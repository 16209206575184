import styled from "styled-components";

const StyledConditionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .conditionTitle {
    margin: auto;
    margin: 0;
    color: #cacaca;
  }
  .mainContainer {
    display: flex;
    padding: 5px;
    background: #44444f;
    margin: 10px;
    border: 1px solid #222222;
    flex-direction: column;
    min-width: 198px;
  }
  .additionalHolder {
    padding: 4px 8px;
  }
  .additionalSelectHolder {
    margin: 10px;
    /* overflow: hidden; */
  }
  .additionalSelectClose {
    cursor: pointer;
    margin: 2px 0 0;
    background: linear-gradient(to top, #556, #334) #556 no-repeat;
    border: 1px solid #332;
    border-bottom: 1px dotted #443;
    padding: 2px 1px;
    font-size: 12px;
    text-shadow: 1px 0 black;
    border-radius: 5px 5px 0 0;
    text-align: center;
    :hover {
      color: #ddb;
    }
  }
  .additionalSelectOpen {
    cursor: pointer;
    margin: 2px 0 0;
    background: linear-gradient(to top, #334, #556) #334 no-repeat;
    border: 1px solid #332;
    border-bottom: 1px dotted #443;
    padding: 2px 1px;
    font-size: 12px;
    text-shadow: 1px 0 black;
    border-radius: 5px 5px 0 0;
    text-align: center;
    :hover {
      color: #aa8 !important;
    }
  }
  .additionalSelectInputs {
    border: 1px solid #332;
    padding: 0.4rem;
    border-top: none;
    color: #ddd;
    background: #555566;
    /* overflow: hidden; */
  }

  .mainDropdownHolder .inputHolder {
    flex-direction: row-reverse !important;
  }
`;

export default StyledConditionSection;
