import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import compressData from "../utils/functions/compressData";
export const useHistoryStore = create(
  persist(
    (set, get) => ({
      histories: [],
      selectedHistoryId: undefined,
      historyLoading: false,
      setHistoryLoading: (loading) => {
        // console.log(loading);
        set(() => ({ historyLoading: loading }));
      },
      selectHistory: (historyId) => {
        set(() => ({ selectedHistoryId: historyId }));
        // return _parsedData;
      },
      addHistory: (history) => {
        let _history = get().histories?.sort((a, b) =>
          new Date(a?.assigned_name.split("*")[1] ?? undefined).getTime() >
          new Date(b?.assigned_name.split("*")[1] ?? undefined).getTime()
            ? 1
            : -1
        );

        const selectedIndex = _history.findIndex(
          (e) => e.assigned_name === get().selectedHistoryId
        );
        if (selectedIndex > -1) {
          _history = _history.slice(0, selectedIndex + 1);
        }

        if (_history.length >= 16) {
          _history.shift();
        }
        _history.forEach((_hist) => {
          delete _hist.id;
          if (JSON.parse(_hist.json_file)?.status === "selected") {
            const _json = JSON.parse(_hist.json_file);
            _json.status = "active";
            _hist.status = "active";
            _hist.json_file = JSON.stringify(_json);
          }
        });
        const _historyProject = history.json_file;
        const _time = new Date().toLocaleString();
        const _historyLength = _history.filter(
          (e) => JSON.parse(e.json_file)?.status !== "inActive"
        ).length;
        const assigned_name = history?.historyTitle
          ? `${_historyLength + 1}-${history?.historyTitle}*${_time}`
          : `${_historyLength + 1}-history*${_time}`;
        if (!!!_history.find((e) => e.assigned_name === assigned_name)) {
          _history.push({
            project_detail: Number(localStorage.getItem("projectID")),
            assigned_name: assigned_name,
            json_file: _historyProject,
            status: "selected",
          });
          _history.forEach((item) => {
            if (!item.json_file?.compressedData) {
              item.json_file = JSON.stringify({
                compressedData: compressData(item.json_file),
              });
            }
          });
          set(() => ({
            histories: _history,
            selectedHistoryId: assigned_name,
          }));
        }
      },
      setHistoryListTest: (historyList) => {
        set(() => ({
          histories: historyList?.sort((a, b) =>
            new Date(a?.assigned_name.split("*")[1] ?? undefined).getTime() >
            new Date(b?.assigned_name.split("*")[1] ?? undefined).getTime()
              ? 1
              : -1
          ),
        }));
      },
    }),
    {
      name: "history-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    }
  )
);
