import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import StyledUserModal from "./StyledUserModal";
import useFetch from "../../utils/hooks/useFetch";
import {
  CAPTCHA,
  LOGIN,
  PROCESS_DATA,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_CAPTCHA,
} from "../../constants/routes/api-routes";
import LoadingButton from "../Common/LoadingButton";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import useMutate from "../../utils/hooks/useMutate";
import { useVariableStore } from "../../store/variableStore";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../constants/routes/app-routes";
import decompressData from "../../utils/functions/decompressData";
import { useHistoryStore } from "../../store/historyStore";
import { useFlowStore } from "../../store/flowStore";
import { v4 as uuidv4 } from "uuid";

const UserModal = () => {
  const variableStore = useVariableStore();
  const historyStore = useHistoryStore();
  const flowStore = useFlowStore();
  const [modalMode, setModalMode] = useState("login");
  const [userData, setUserData] = useState(null);
  const [userCaptcha, setUserCaptcha] = useState("");
  const [resetTimer, setResetTimer] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [captchaImage, setCaptchaImage] = useState("");
  const [captchaHash, setCaptchaHash] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { resetStore } = useVariableStore();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm({});

  const [projectID, setProjectID] = useState(localStorage.getItem("projectID"));

  const {
    data: projectData,
    refetch: projectRefetch,
    isLoading: projectDataLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, localStorage.getItem("projectName")],
    PROCESS_DATA + "/search",
    { selected_name: localStorage.getItem("projectName") },
    null,
    { enabled: false }
  );

  const {
    mutate,
    isSuccess: createProjectSuccess,
    isError: createProjectError,
  } = useMutate("POST", [PROCESS_DATA, "create"], PROCESS_DATA);

  const { data: lastProject } = useFetch(
    "GET",
    [PROCESS_DATA],
    `${PROCESS_DATA}?start=0&end=1`,
    null,
    null,
    {
      cacheTime: 0,
      caches: "no-cache",
      enabled: !projectID && !!Cookies.get("token"),
    }
  );

  const {
    data: projectFetched,
    isSuccess: projectFetchedSuccess,
    isLoading: projectLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, projectID],
    `${PROCESS_DATA}/${projectID}/`,
    null,
    null,
    { enabled: !!projectID && !!Cookies.get("token") }
  );

  const {
    mutate: forgetPasswordMutate,
    isSuccess: forgetPasswordSuccess,
    isError: forgetPasswordError,
  } = useMutate("POST", [RESET_PASSWORD, userData], RESET_PASSWORD, null, {
    enabled: false || !userData,
    cacheTime: 0,
  });

  const {
    mutate: verifyCaptchaMutate,
    isError: captchaError,
    isSuccess: captchaDone,
    data: captchaData,
  } = useMutate("POST", [userCaptcha], VERIFY_CAPTCHA, null, {
    enabled: false,
  });

  const {
    data: captchaImageData,
    refetch: generateCaptcha,
    isLoading: captchaLoading,
  } = useFetch("GET", [CAPTCHA], CAPTCHA, null, null, null, {
    responseType: "arraybuffer",
  });

  const {
    mutate: userActionMutation,
    data,
    isSuccess,
    isError,
  } = useMutate(
    "POST",
    [
      modalMode === "login" ? LOGIN : modalMode === "signup" && REGISTER,
      userData,
    ],
    modalMode === "login" ? LOGIN : modalMode === "signup" && REGISTER
  );

  useEffect(() => {
    if (captchaError) {
      toast.error("please enter captcha again.");
      setIsLoading(false);
      setCaptchaHash(null);
      setResetLoading(false);
      generateCaptcha();
      return;
    }
    if (captchaDone && !captchaData) {
      toast.error("please enter captcha again.");
      setIsLoading(false);
      setResetLoading(false);
      setCaptchaHash(null);
      generateCaptcha();
      return;
    }
    if (captchaDone && captchaData) {
      if (modalMode === "forget") {
        forgetPasswordMutate(userData);
      } else {
        userActionMutation(userData);
      }
    }
    // eslint-disable-next-line
  }, [captchaData, captchaDone, captchaError]);

  // Getting last project and set Data
  useEffect(() => {
    if (projectFetchedSuccess && projectFetched) {
      const _data = projectFetched?.data;
      localStorage.setItem("projectData", JSON.stringify(_data));
      localStorage.setItem("name_by_user", _data?.name_by_user);
      localStorage.setItem("projectName", _data?.selected_name);
      // variableStore.setOldValues(_data?.oldValues ?? []);
      const historyIndex = _data?.project_detail_levels?.findIndex(
        (e) => e.assigned_name === historyStore.selectedHistoryId
      );
      const _json = _data.json_file;
      historyStore.setHistoryListTest(_data.project_detail_levels);
      const _jsonData = JSON.parse(_json);
      let _parsedData = _jsonData?.data ? _jsonData?.data : _jsonData;
      if (_parsedData?.compressedData) {
        _parsedData = {
          ...JSON.parse(decompressData(_parsedData?.compressedData))?.data,
          highestIndex: JSON.parse(decompressData(_parsedData?.compressedData))
            ?.highestIndex,
        };
      }
      if (_parsedData?.events) {
        for (const key in _parsedData?.events) {
          if (Object.hasOwnProperty.call(_parsedData?.events, key)) {
            const element = _parsedData?.events[key];
            element.nodesData.forEach((e) => {
              e.selected = false;
            });
          }
        }
        flowStore.setEvents(_parsedData?.events);
        flowStore.setSelectedEvent("on_tick");

        variableStore.setConstants(_parsedData?.constants ?? []);
        variableStore.setVariables(_parsedData?.variables ?? []);
        variableStore.setOldValues(_parsedData?.oldValues ?? []);
        localStorage.setItem(
          "highestIndex",
          Number(
            historyIndex >= 0 &&
              historyIndex < _data.project_detail_levels.length - 1
              ? _parsedData?.highestIndex
              : _jsonData?.highestIndex
          )
        );
      }
      navigate(HOME_ROUTE);
    }
    // eslint-disable-next-line
  }, [projectFetched, projectFetchedSuccess]);

  useEffect(() => {
    if (lastProject) {
      const _data = lastProject?.data?.results;
      if (_data && _data.length < 1) {
        const _uuid = uuidv4();
        const _sendingData = {
          events: {
            on_init: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_timer: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_tick: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_trade: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_chart: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_deinit: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
          },
          variables: [],
          constants: [],
          oldValues: [],
        };
        mutate({
          data: _sendingData,
          selected_name: _uuid,
          name_by_user: "unnamed",
          highestIndex: 1,
        });
        localStorage.setItem("projectName", _uuid);
      } else {
        const _selectedProject = _data[0];
        variableStore.resetStore();
        localStorage.setItem("projectID", _selectedProject?.id);
        localStorage.setItem("projectData", JSON.stringify(_selectedProject));
        setProjectID(_selectedProject?.id);
      }
    }
    // eslint-disable-next-line
  }, [lastProject]);

  useEffect(() => {
    if (projectData) {
      const _selectedProject = projectData?.data?.find(
        (item) => item?.selected_name === localStorage.getItem("projectName")
      );
      variableStore.resetStore();
      localStorage.setItem("projectID", _selectedProject?.id);
      localStorage.setItem("projectData", JSON.stringify(_selectedProject));
      setProjectID(_selectedProject?.id);
    }
    // eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (createProjectError || createProjectSuccess) {
      setIsLoading(false);
    }
    if (createProjectSuccess) {
      projectRefetch();
    }
    // eslint-disable-next-line
  }, [createProjectSuccess, createProjectError]);

  // Function to convert array buffer to base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    setIsLoading(false);
    if (captchaImageData?.data) {
      setCaptchaHash(captchaImageData?.headers.get("X-Captcha-Hash"));
      const base64Image = arrayBufferToBase64(captchaImageData.data);
      setCaptchaImage(`data:image/png;base64,${base64Image}`);
    }
  }, [captchaImageData]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      return;
    }
    if (modalMode !== "login") {
      setIsLoading(false);
    }

    if (isSuccess && data && modalMode === "login") {
      resetStore();
      const _expireTime =
        Number(Date.now()) + Number(process.env.REACT_APP_TOKEN_EXPIRE_TIME);
      toast.success("Login was successful.");
      Cookies.set("token", data.data.access);
      Cookies.set("role", data?.data?.role);
      Cookies.set("refreshToken", data?.data?.refresh);
      Cookies.set("tokenExpireTime", _expireTime);
      // navigate(HOME_ROUTE);
      // window.location.reload();
    } else if ((isSuccess, modalMode === "signup")) {
      toast.success("Please check your email to activate your account.");
      generateCaptcha();
      setModalMode("login");
      reset();
    } else {
      generateCaptcha();
    }
    // eslint-disable-next-line
  }, [isSuccess, data, isError]);

  useEffect(() => {
    generateCaptcha();
    let interval;
    const _resetTimer = Cookies.get("passwordResetTime");
    if (_resetTimer && _resetTimer - Date.now() >= 0) {
      interval = setInterval(() => {
        const currentTime = _resetTimer - Date.now();
        if (currentTime <= 0) {
          clearInterval(interval);
          Cookies.remove("passwordResetTime");
          setResetTimer(false);
        } else {
          setResetTimer(`Wait ${Math.ceil(currentTime / 1000)} seconds`);
        }
      }, 1000);
    } else {
      setResetTimer(false);
    }
    return () => {
      setUserData(null);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (userData && modalMode !== "forget") {
  //     userActionMutation(userData);
  //   } else {
  //     if (userData) {
  //       forgetPasswordMutate(userData);
  //     }
  //   }
  // }, [userData]);

  useEffect(() => {
    if (forgetPasswordError) {
      setResetLoading(false);
      setIsLoading(false);
      return;
    }
    let interval;
    if (forgetPasswordSuccess) {
      const _forgetPasswordCD = Date.now() + 120 * 1000;
      Cookies.set("passwordResetTime", _forgetPasswordCD);
      interval = setInterval(() => {
        const currentTime = _forgetPasswordCD - Date.now();
        if (currentTime <= 0) {
          clearInterval(interval);
          Cookies.remove("passwordResetTime");
          setResetTimer(false);
        } else {
          setResetTimer(`Wait ${Math.ceil(currentTime / 1000)} seconds`);
        }
      }, 1000);
      setUserData(null);
      setModalMode("login");
      // generateCaptcha();
      reset();
      setResetLoading(false);
      setIsLoading(false);
      toast.success("Password reset link sent to your email.");
    }
    return () => {
      clearInterval(interval);
    }; // eslint-disable-next-line
  }, [forgetPasswordSuccess, forgetPasswordError]);

  const onSubmit = (data) => {
    if (modalMode === "signup" && data.password !== data.confirm_password) {
      toast.error("password does not match!");
      return;
    }
    // if (validateCaptcha()) {
    if (modalMode === "forget") {
      setResetLoading(true);
    }
    setUserData(data);
    validateCaptcha();
    // }
  };

  const validateCaptcha = () => {
    setIsLoading(true);
    verifyCaptchaMutate({
      captcha_input: userCaptcha,
      captcha_hash: captchaHash,
    });
  };

  return (
    <StyledUserModal>
      <div className="userMainModal">
        {modalMode === "login" ? (
          <h1>Login</h1>
        ) : modalMode === "signup" ? (
          <h1>Sign Up</h1>
        ) : (
          <h1>Forgot Password</h1>
        )}
        <form className="userModalForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="userModalInputHolder">
            <span>Email :</span>
            <input placeholder="Email" type="email" {...register("email")} />
          </div>
          {modalMode !== "forget" && (
            <div className="userModalInputHolder">
              <span>Password :</span>
              <input
                placeholder="Password"
                type="password"
                {...register("password")}
              />
            </div>
          )}
          {modalMode === "signup" && (
            <div className="userModalInputHolder">
              <span>Confirm Password :</span>
              <input
                placeholder="Confirm Password"
                type="password"
                {...register("confirm_password")}
              />
            </div>
          )}
          <div className="userModalInputHolder">
            {captchaLoading ? (
              <span>loading...</span>
            ) : (
              <img className="captchaImage" src={captchaImage} alt="captcha" />
            )}
            <input
              className="captchaInput"
              type="text"
              placeholder="Enter the Captcha..."
              value={userCaptcha}
              onChange={(e) => {
                setUserCaptcha(e.target.value);
              }}
            />
          </div>

          {modalMode === "login" ? (
            <div>
              <LoadingButton
                title={"Login"}
                isLoading={isLoading}
                type="submit"
                className="loginSubmitBtn"
                style={{ color: "black !important" }}
              />
            </div>
          ) : modalMode === "signup" ? (
            <LoadingButton
              title={"Sign Up"}
              isLoading={isLoading}
              type="submit"
            />
          ) : (
            <LoadingButton
              type="submit"
              isLoading={resetLoading}
              disabled={resetTimer}
              title={!resetTimer ? "Send Reset Link" : resetTimer}
            />
          )}
        </form>
        <div>
          {modalMode === "login" ? (
            <>
              <div className="infoHolder">
                <p>If you don't have an account yet, please</p>
                <LoadingButton
                  title={"Sign Up"}
                  isLoading={isLoading}
                  onClick={() => {
                    setUserData(null);
                    setModalMode("signup");
                    generateCaptcha();
                    reset();
                  }}
                />
              </div>
              <div className="infoHolder">
                <p>Forgot Password ?</p>
                <LoadingButton
                  title={"Reset Password"}
                  isLoading={isLoading}
                  onClick={() => {
                    setUserData(null);
                    setModalMode("forget");
                    generateCaptcha();
                    reset();
                  }}
                />
              </div>
            </>
          ) : (
            <div className="infoHolder">
              <p> If you already have an account, please</p>
              <LoadingButton
                title={"Login"}
                isLoading={isLoading}
                onClick={() => {
                  setUserData(null);
                  setModalMode("login");
                  generateCaptcha();
                  reset();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </StyledUserModal>
  );
};

export default UserModal;
