import styled from "styled-components";

const StyledLoadProjectModal = styled.div`
  width: 700px;
  min-height: 300px;
  max-height: 600px;
  overflow: hidden;
  background: #666666;
  box-shadow: 0 0 10px #d6d6d6;
  position: absolute;
  left: calc(50% - 262.5px);
  top: 20%;
  z-index: 999999;
  padding: 5px;
  .header {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px;
    cursor: move;
    span {
      font-size: 14px;
      color: #aaaaaa;
    }
    button {
      margin: 0;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }
  }

  .content {
    border: 1px solid black;
    min-height: 364px;
    table {
      width: 100%;
    }
    .projectHolder {
      border: 1px solid black;
      border-bottom: 2px solid red;
      background: #555;
      &:nth-child(odd) {
        background-color: #5f5f5f;
      }
      td {
        color: #dddddd;
        padding: 3px;
        font-size: 14px;
      }
    }
    button {
      cursor: pointer;
      color: #f55;
      font-size: 14px;
      text-shadow: 1px 1px 1px #200;
      border: 1px solid #111;
      border-radius: 3px;
      padding: 4px 8px;
      background: linear-gradient(to bottom, #aaa, #777) #777 no-repeat;
      outline: none;
      box-shadow: none;
    }
    .loadBtn {
      font-size: 14px;
      color: #5f5;
      text-shadow: 1px 1px 1px #020;
    }
  }

  .pagination {
    user-select: none;
    span {
      margin: 0 7px 0 5px;
      text-align: center;
    }
  }

  .deleteHolder{
  display: flex;
  flex-direction: column;
  }
  .deleteConfirmation {
    display: flex;
    button:first-child {
      background-color: red !important;
      color: white;
    }
    button:last-child {
      background-color: gray;
      color: white;
    }
  }
`;

export default StyledLoadProjectModal;
