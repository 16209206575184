import styled from "styled-components";

const StyledSetVariableModal = styled.div`
  background: red;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 260px;
  white-space: nowrap;
  z-index: 9999;
  .header {
    display: flex;
    flex-direction: column;
    align-items: start;
    background: #999999;
    button {
      padding: 0;
      border: none;
      box-shadow: none;
      font-size: 12px;
      margin: 2px 0;
      background: none;
    }
    :first-child {
      color: #222222;
    }
    :last-child {
      color: #dddddd;
      background: #333333;
      width: 100%;
    }
  }
  .content {
    display: flex;
    align-items: normal;
    justify-content: center;
    background: #aaa;
    padding: 2px;

    > div {
      border: 1px solid #111;
      height: auto;
      color: #000;
    }
    table {
      width: 100%;
      thead {
        border-bottom: 1px solid red;
        font-size: 10px;
        td:nth-of-type(2) {
          text-align: center;
        }
      }
      tbody {
        font-size: 12px;

        td:nth-of-type(2) {
          text-align: center;
          color: #000;
        }
      }
    }
  }
`;

export default StyledSetVariableModal;
