import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Draggable from "react-draggable";
import SettingAccordion from "./SettingAccordion";
import { StyledProjectSettings } from "./StyledProjectSettings";
import { projectSettingsInputs } from "../../constants/projectSettingsInputs";
import { ReactComponent as Cross } from "../../assests/icons/cross.svg";
import useUpdateProject from "../../utils/hooks/useUpdateProject";
import { projectSettingsDefaults } from "../../constants/projectSettingsDefaults";

const ProjectSettings = ({ setModal }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [settings, setSettings] = useState({});
  const { updateProject } = useUpdateProject();
  useEffect(() => {
    const storedSettings =
      localStorage.getItem("projectSettings") &&
      localStorage.getItem("projectSettings") != "undefined"
        ? JSON.parse(localStorage.getItem("projectSettings"))
        : projectSettingsDefaults;
    setSettings(storedSettings);

    const setNestedValues = (obj, prefix = "") => {
      Object.entries(obj).forEach(([key, value]) => {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object" && value !== null) {
          setNestedValues(value, fullKey);
        } else {
          setValue(fullKey, value);
        }
      });
    };

    setNestedValues(storedSettings);
  }, [setValue]);

  const onSubmit = (data) => {
    const updateNestedObject = (obj, path, value) => {
      const keys = path.split(".");
      let current = obj;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!(keys[i] in current)) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
    };

    const updatedSettings = JSON.parse(JSON.stringify(settings));
    Object.keys(data).forEach((key) => {
      updateNestedObject(updatedSettings, key, data[key]);
    });

    localStorage.setItem("projectSettings", JSON.stringify(updatedSettings));
    setSettings(updatedSettings);
    updateProject();
    setModal(false);
  };

  //   const getSettingValue = (key) => {
  //     return getNestedValue(settings, key);
  //   };

  return (
    <Draggable handle=".handle">
      <StyledProjectSettings>
        <div className="project-settings-modal">
          <div className="handle header">
            <h2>Project Settings</h2>
            <button onClick={() => setModal(false)}>
              <Cross />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="settingsForm">
              {projectSettingsInputs.map((setting, index) => (
                <SettingAccordion
                  key={index}
                  title={setting.title}
                  index={index}
                  inputs={setting.inputs}
                  register={register}
                />
              ))}
            </div>
            <button type="submit">Save Settings</button>
          </form>
        </div>
      </StyledProjectSettings>
    </Draggable>
  );
};

export default ProjectSettings;
