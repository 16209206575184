import styled from "styled-components";
export const StyledSettingAccordion = styled.div`
  min-width: 100%;
  margin: 2px;
  background: #444444;
  .inputsContainer {
    margin: 0 2px;
    border: 1px solid #332;
    border-top: none;
    width: calc(100% - 4px);
    /* overflow: hidden; */
  }
  
  .separator {
    border: 1px solid #332;
    border-bottom: 1px dotted #443;
    padding: 2px 10px;
    font-size: 9px;
    text-align: center;
    font-weight: 700;
    color: #bb9;
    cursor: pointer;
    text-shadow: 0 1px 0 #000;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(180deg, #555550, #332) #555550 no-repeat;
    margin: 0 2px;

    &.expanded {
      color: #888880;
      background: linear-gradient(180deg, #44443f, #555550);
    }
  }

  .inputAccordionInputs {
    margin: 0 !important;
    padding: 0;
    .labelHolder {
      background: #555550;
      width: 50%;
    }
    span {
      margin: 0;
      padding: 2px;
      height: 100%;
      display: block;
      height: 100%;
    }
  }

  .inputHolderSettings {
    color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    select,
    textarea,
    input[type="text"],
    input[type="number"] {
      font-size: 12px;
      margin: 3px 0;
      padding: 2px;
      background: #333;
      color: #ddd;
      width: fit-content;
      min-width: 50%;
      cursor: pointer;
      border-radius: 0 3px 0 3px;
      option {
        width: 100%;
      }
    }

    .inputHolder {
      display: flex;
      margin: 3px 0;
      align-items: start;
      justify-content: space-between;
      width: 100%;

      input[type="text"] {
        width: fit-content;
        margin: 0;
        min-width: 50%;
      }
      select {
        margin: 0;
        min-width: 50%;
      }

      .labelHolder {
        background: #555550;
        width: 50%;
      }

      span {
        color: #ddd;
        font-size: 12px;
        white-space: nowrap;
        margin-right: 5px;
      }
    }
  }
`;
