import styled from "styled-components";

const StyledInputAccordion = styled.div`
  min-width: 100%;
  margin: 2px;
  .inputsContainer {
    margin: 0 2px;
    border: 1px solid #332;
    border-top: none;
    width: calc(100% - 4px);
    /* overflow: hidden; */
  }
  .separator {
    border: 1px solid #332;
    border-bottom: 1px dotted #443;
    padding: 2px 10px;
    font-size: 9px;
    text-align: center;
    font-weight: 700;
    color: #bb9;
    cursor: pointer;
    text-shadow: 0 1px 0 #000;
    border-radius: 5px 5px 0 0;
    background: linear-gradient(180deg, #555550, #332) #555550 no-repeat;
    margin: 0 2px;

    &.expanded {
      color: #888880;
      background: linear-gradient(180deg, #44443f, #555550);
    }
  }

  .inputAccordionInputs {
    margin: 0 !important;
    padding: 0;
    .labelHolder {
      background: #555550;
      width: 50%;
    }
    span {
      margin: 0;
      padding: 2px;
      height: 100%;
      display: block;
      height: 100%;
    }
  }
`;

export default StyledInputAccordion;
