import styled from "styled-components";

const StyledFlowCanvas = styled.section`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;
  .loadingScreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }

  aside .description {
    margin-bottom: 10px;
  }

  .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }

  .dndnode.input {
    border-color: #0041d0;
  }

  .dndnode.output {
    border-color: #ff0072;
  }

  .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }

  .selectall {
    margin-top: 10px;
  }
  .context-menu {
    background: white;
    border-style: solid;
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
  }

  .context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
  }

  .context-menu button:hover {
    background: white;
  }

  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  .disablingSection {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    opacity: 0.2;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      zoom: 3;
    }
  }

  .ContextMenuHolder {
    position: absolute;
    /* top: 20%;
    left: calc(100% + 5px); */
    /* padding: 5px; */
    background-color: #666;
    z-index: 9999;
    border-radius: 5px;
    box-shadow: 0 0 3px black;
    display: flex;
    flex-direction: column;
    align-items: start;
    button {
      color: #ddd;
      margin-bottom: 5px;
      box-flex-group: #ccc;
      background: transparent;
      box-shadow: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 3px;
      width: 100%;
      &:hover {
        color: #fff !important;
        background-color: #ab9356 !important;
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .paneContextMenu {
    position: absolute;
    min-width: 120px;
    width: auto;
    z-index: 99999;
    border: solid 1px #330;
    background: #666;
    padding: 0;
    margin: 0;
    -webkit-box-shadow: 0 0 0.8px #000;
    -moz-box-shadow: 0 0 0.8px #000;
    box-shadow: 0 0 0.8px #000;
    border-radius: 5px;
    button {
      list-style: none;
      margin: 0;
      color: #ddd;
      border: none;
      display: flex;
      align-items: center;
      text-decoration: none;
      background: transparent;
      /* display: block; */
      line-height: 20px;
      /* height: 20px; */
      outline: none;
      padding: 1px 5px 1px 2.8px;
      white-space: nowrap;
      cursor: pointer;
      pointer-events: all;
      img {
        margin-right: 10px;
      }
    }
  }
`;

export default StyledFlowCanvas;
