import React, { useState } from "react";
import StyledConditionModal from "./StyledConditionModal";
import ConditionSection from "../ConditionSection";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useFlowStore } from "../../../store/flowStore";

const ConditionModal = ({ nodeId }) => {
  const flowStore = useFlowStore();
  const { register, getValues, setValue } = useFormContext(); // retrieve all hook methods
  const [operator, setOperator] = useState(
    getValues("params.operator.label") ?? ">"
  );

  const settingValues = () => {
    const _property =
      Object.keys(
        flowStore.nodes?.find((property) => property.id === nodeId)?.params
      ).length > 0
        ? flowStore.nodes.find((item) => item.id === nodeId)
        : null;
    if (!_property) {
      setValue("params.operator.cross_width", 1);
    } else {
      if (_property?.params) {
        if (_property?.params?.operator?.cross_width) {
          setValue(
            "params.operator.cross_width",
            _property?.params?.operator?.cross_width
          );
        } else {
          setValue("params.operator.cross_width", 1);
        }
      }
    }
  };

  useEffect(() => {
    settingValues();
    //eslint-disable-next-line
  }, []);
  //eslint-disable-next-line
  const [conditionOperators] = useState([
    {
      value: 1,
      label: ">",
    },
    {
      value: 2,
      label: "<",
    },
    {
      value: 4,
      label: "<=",
    },
    {
      value: 5,
      label: ">=",
    },
    {
      value: 6,
      label: "==",
    },
    {
      value: 7,
      label: "×>",
    },
    {
      value: 8,
      label: "×<",
    },
    {
      value: 9,
      label: "!=",
    },
  ]);
  return (
    <StyledConditionModal>
      <ConditionSection nodeId={nodeId} id="left" />
      <div className="conditionOperator">
        <span>Operator</span>
        <select
          {...register("params.operator.label", {
            onChange: (e) => setOperator(e.target.value),
          })}
        >
          {conditionOperators.length > 0 &&
            conditionOperators.map((operator) => (
              <option key={operator?.label} value={operator?.label}>
                {operator?.label}
              </option>
            ))}{" "}
        </select>
        {(operator === "×<" || operator === "×>") && (
          <div className="crossWidth">
            <p>Cross Width</p>
            <input
              className="crossWidth"
              type="text"
              {...register("params.operator.cross_width")}
            />
          </div>
        )}
      </div>
      <ConditionSection nodeId={nodeId} id="right" />
    </StyledConditionModal>
  );
};

export default ConditionModal;
