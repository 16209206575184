import React from "react";
import StyledAccordionItem from "./StyledAccordionItem";

const AccordionItem = ({
  title,
  color,
  bgcolor,
  list,
  isOpen,
  category,
  ...props
}) => {
  const onDragStart = (nodeData, event) => {
    let _nodeData = { ...nodeData };
    _nodeData.title = nodeData.child_name;
    _nodeData.bgcolor = nodeData?.color_bg;
    _nodeData.color = nodeData?.color_font;
    _nodeData.category = category;
    delete _nodeData?.menu_child_feature;
    event.dataTransfer.setData("application/reactflow", "customNode");
    event.dataTransfer.setData(
      "application/reactflow/data",
      JSON.stringify(_nodeData)
    );
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <StyledAccordionItem {...props} color={color} bgcolor={bgcolor}>
      <div className={isOpen ? "open" : ""}>
        <span>{title}</span>
        <div style={{ display: isOpen ? "block" : "none" }}>
          {list &&
            list
              ?.filter((item) => item?.is_shown)
              ?.sort((a, b) => (Number(a.id_sent) > Number(b.id_sent) ? 1 : -1))
              .map((item, index) => (
                <div
                  key={item.child_name + index}
                  className="itemListContainer"
                >
                  <div
                    className="listBrick"
                    style={{ background: item?.color_bg }}
                  ></div>
                  <div
                    onDragStart={onDragStart.bind(this, { ...item })}
                    draggable
                    className="accordionContentItem"
                    key={index}
                  >
                    {item.child_name}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </StyledAccordionItem>
  );
};

export default AccordionItem;
