import SideBar from "../../../Components/Sidebar";
import FlowCanvas from "../../../Components/FlowCanvas";
import EventsBar from "../../../Components/EventsBar";
import { useState } from "react";
import LoadProjectModal from "../../../Components/ProjectModals/LoadProjectModal";
import NewProjectModal from "../../../Components/ProjectModals/NewProjectModal";
import HistorySection from "../../../Components/HistorySection";
import StyledHome from "./StyledHome";
import Cookies from "js-cookie";
import { useVariableStore } from "../../../store/variableStore";
import { useFlowStore } from "../../../store/flowStore";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants/routes/app-routes";
const Home = () => {
  const [reRender, setRerender] = useState(false);
  const [loadProjectModal, setLoadProjectModal] = useState(false);
  const [newProjectModal, setNewProjectModal] = useState(false);
  const { resetStore } = useVariableStore();
  const { resetStore: flowReset } = useFlowStore();
  const navigate = useNavigate();

  return (
    <StyledHome>
      <div className="App">
        <div className="topSection">
          {loadProjectModal && (
            <LoadProjectModal setModal={setLoadProjectModal} />
          )}
          {newProjectModal && <NewProjectModal setModal={setNewProjectModal} />}
          <div className="menuContainer">
            <div className="menuParent">
              <span className="menuTitle">Projects</span>
              <div className="menuModal">
                <button onClick={() => setNewProjectModal(true)}>
                  New Project
                </button>
                <button onClick={() => setLoadProjectModal(true)}>
                  Load Project
                </button>
              </div>
            </div>
            <div className="menuParent">
              <div className="menuTitle">
                <span className="menuTitle">Profile</span>
                <div className="menuModal">
                  {/* <button>
                    <a href={rolePage?.[Cookies.get("role")]}> Profile Page</a>
                  </button> */}
                  <button
                    onClick={() => {
                      localStorage.clear();
                      Cookies.remove("token", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("role", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("refreshToken", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("tokenExpireTime", {
                        domain: process.env.REACT_APP_MAIN_DOMAIN,
                      });
                      Cookies.remove("token");
                      Cookies.remove("role");
                      Cookies.remove("refreshToken");
                      Cookies.remove("tokenExpireTime");
                      resetStore();
                      flowReset();
                      navigate(LOGIN_ROUTE);
                      // window.location.reload();
                    }}
                  >
                    Logout
                  </button>
                </div>
                {/* <a href={rolePage?.[Cookies.get("role")]}> Profile</a> */}
              </div>
            </div>
          </div>
          <HistorySection reRender={reRender} setRerender={setRerender} />
          <EventsBar setRerender={setRerender} />
        </div>
        <div>
          <div className="mainWrapper">
            <div className="leftSection">
              <SideBar reRender={reRender} setRerender={setRerender} />
            </div>
            <div className="rightSection">
              <FlowCanvas reRender={reRender} setRerender={setRerender} />
            </div>
          </div>
        </div>
      </div>
    </StyledHome>
  );
};

export default Home;
