import React, { useEffect, useState } from "react";
import StyledCommonProperty from "./StyledCommonProperty";
import { useFormContext } from "react-hook-form";
import InputAccordion from "../InputsAccordion";
import useFetch from "../../../utils/hooks/useFetch";
import { MODAL_DETAIL } from "../../../constants/routes/api-routes";
import { useFlowStore } from "../../../store/flowStore";

const CommonProperty = ({ nodeId, blockName }) => {
  const flowStore = useFlowStore();
  const { unregister, setValue } = useFormContext(); // retrieve all hook methods
  const _selectedNode = flowStore.nodesData?.find((node) => node.id === nodeId);
  const [dataItems, setDataItems] = useState([
    ...(_selectedNode?.data?.menu_child_feature
      ? _selectedNode?.data?.menu_child_feature?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      : []),
  ]);

  const { data, isLoading, isSuccess } = useFetch(
    "GET",
    [`${MODAL_DETAIL + _selectedNode?.data?.slug}/`],
    `${MODAL_DETAIL + _selectedNode?.data?.slug}/`,
    null,
    null,
    { enabled: !!_selectedNode }
  );

  useEffect(() => {
    if ((isSuccess, data)) {
      setDataItems(
        data?.data?.menu_child_feature?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      );
    }
  }, [data, isSuccess]);

  const selectChangeHandler = (input, event) => {
    input?.options.forEach((_option) => {
      if (_option?.id !== event.target.value) {
        if (_option?.subInputs) {
          _option?.subInputs.forEach((subInput) => {
            unregister(subInput.registerId);
          });
        }
      } else {
        const _property =
          flowStore.nodes &&
          Object.keys(
            flowStore.nodes?.find((property) => property.id === nodeId)?.params
          ).length > 0
            ? flowStore.nodes.find((item) => item.id === nodeId)
            : null;
        if (!_property || !_property?.params?._option?.id) {
          if (_option?.subInputs) {
            _option?.subInputs.forEach((subInput) => {
              setValue(subInput.registerId, subInput.value);
            });
          }
        }
      }
    });
  };

  return (
    <StyledCommonProperty>
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          {dataItems.length > 0 &&
            dataItems.map((item, index) => (
              <InputAccordion
                key={item?.name_key + index}
                selectOnChange={selectChangeHandler}
                title={item?.name_key}
                defaultOpenState={item?.is_default_open}
                inputs={item?.inputs}
                nodeId={nodeId}
                accordionItem={item}
              />
            ))}
        </>
      )}
    </StyledCommonProperty>
  );
};

export default CommonProperty;
