import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { useVariableStore } from "./variableStore";
export const useModalStore = create(
  persist(
    (set) => ({
      modals: [],
      openModal: (data) => {
        set((state) => {
          const _data = state.modals.find((e) => e?.id === data?.id)
            ? [...state.modals]
            : [...state.modals, { ...data }];
          return { modals: _data };
        });
      },
      closeModal: (id) => {
        useVariableStore.getState().cancelRemovingOldValue(id);

        set((state) => ({ modals: state.modals.filter((e) => e.id !== id) }));
      },
    }),
    {
      name: "propertiesModal-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    }
  )
);
