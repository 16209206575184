import { defineAbility } from "@casl/ability";
import { roles } from "../constants/roles";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import Cookies from "js-cookie";
const defineUserAbility = () => {
  // const { can, rules } = new AbilityBuilder();
  const role = Cookies.get("role");
  return defineAbility((can) => {
    can(["read"], "user");
    if (role === roles.ADMIN) {
      can(["manage"], "all");
    } else if (role === roles.DEBUG_USER) {
      can(["read"], "debugging");
    } else if (role === roles.TEST_USER) {
      can(["read"], "testing");
    }
  });
};

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export default defineUserAbility;
