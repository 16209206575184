import styled from "styled-components";

const StyledConditionModal = styled.div`
  display: flex;
  align-items: start;
  .crossWidth {
    display: flex; 
    align-items: center;
    flex-direction: column;
    p {
      white-space: nowrap;
    }
    input {
      background: #333;
      padding: 4px;
      width: 34px;
      color: #ddd;
      border: 1px solid #000;
    }
  }
  .conditionOperator {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #cacaca;
    }
    select {
      margin-top: 10px;
      border: 1px solid black;
      font-size: 20px;
      padding: 0 6px;
      height: 30px;
      max-width: 60px;
      background: #333;
      color: #ddd;
    }
  }
`;

export default StyledConditionModal;
