import styled from "styled-components";

const StyledEventsBar = styled.div`
  background: red;
  height: 30px;
  border: 1px solid #111;
  border-bottom: 0 solid transparent;
  border-radius: 15px 15px 0 0;
  padding: 4px 12px;
  padding-bottom: 0;
  background-color: #444;
  min-width: 435px;
  margin: auto;
  position: absolute;
  top: 60%;
  left: 40%;
  .eventBtn {
    cursor: pointer;
    font-family: Georgia;
    vertical-align: middle;
    padding: 0.1rem 0.9rem;
    color: #111;
    display: table-cell;
    font-size: 15px;
    margin: 0;
    border: 0.1rem solid #555;
    border-left: none;
    white-space: nowrap;
    background-color: #aaa;
    background: -webkit-linear-gradient(top, #aaa, #777) #aaa no-repeat;
    span {
      position: relative;
      font-size: 13px;
      padding-left: 3px;
      top: 2px;
      color: #c97;
      text-shadow: 1px 1px 1px #000;
    }
  }
  .activeEvent {
    background: linear-gradient(180deg, #fa7, #d24007);
  }
`;

export default StyledEventsBar;
