import styled from "styled-components";

const StyledVariablesAndConstantsModal = styled.div`
  width: 600px;
  min-height: 300px;
  max-height: 500px;
  overflow: hidden;
  background: #666666;
  box-shadow: 0 0 10px #d6d6d6;
  position: absolute;
  left: calc(50% - 262.5px);
  top: 20%;
  z-index: 999999;
  .header {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px;
    cursor: move;
    span {
      font-size: 14px;
      color: #aaaaaa;
    }
    button {
      margin: 0;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }
  }
  .contentSection {
    overflow-y: auto;
    min-height: 275px;
    max-height: 475px;
    width: 100%;
    padding: 3px;
    .VariablesBtnHolder {
      display: block;
      button {
        color: black;
        box-shadow: none;
        border: 1px solid;
        border-radius: 5px 5px 0 0;
        border: 1px solid #333;
        border-bottom: none;
        margin-top: 0;
        margin-bottom: -1px;
        padding: 3px;
      }
      :first-child {
        background: #997755;
      }
      :last-child {
        background: #555566;
      }
    }
    .variableArea {
      border: 1px solid #333;

      .variablesContainer {
        thead {
          td {
            text-align: center;
          }
        }
        button {
          margin: 0;
        }
        tbody {
          td {
            padding: 2px;
            button {
              display: flex;
              border: none;
              box-shadow: none;
              align-items: center;
              /* height: 17px; */
              /* width: 30px; */
              background: #ff4444;
              margin: 0;
              margin-left: 10px;
              border: 1px solid #111;
              padding: 0;
              cursor: pointer;
              svg {
                fill: #d6d6d6;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
      .staticDataContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        color: #dddddd;
        button {
          cursor: pointer;
          background: linear-gradient(180deg, #aaa, #777) #777;
          padding: 4px 8px;
          border-radius: 0;
          color: #dddddd;
          box-shadow: none;
          border: 1px solid black;
          margin: 15px 0;
        }
        p {
          margin-bottom: 4px;
        }
      }
    }
  }

  .addBtn {
    margin: 0;
    margin-top: 15px;
    background: none;
    border: none;
    box-shadow: none;
    color: #ddd;
  }
`;

export default StyledVariablesAndConstantsModal;
