import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { useFlowStore } from "./flowStore";
import setNestedValue from "../utils/functions/setNestedValue";
import update from "immutability-helper";

const defaultValues = {
  Variables: [],
  Constants: [],
  oldValues: [],
};

export const useVariableStore = create(
  persist(
    (set, get) => ({
      Variables: [],
      Constants: [],
      oldValues: [],
      removedOldValues: [],

      addVariables: (variableList, type) => {
        set((state) => {
          const _variables = [...state[type]];
          variableList.forEach((_var) => {
            if (_var.moved) {
              const _targetIndex = _var?.hoverIndex < 1 ? 0 : _var?.hoverIndex;
              _variables.splice(_targetIndex, 0, _var);
            } else {
              _variables.push(_var);
            }
          });
          return {
            oldValues: [
              ...state.oldValues,
              ...variableList.map((_var) => ({ variable: _var, nodes: [] })),
            ],
            [type]: [..._variables],
          };
        });
      },

      getVariable: (variableId, type) => {
        return get()[type].find((e) => e?.id === variableId);
      },

      deleteVariables: (variableList, type) => {
        set((state) => ({
          [type]: state[type].filter((e) => {
            if (variableList.find((v) => v.id === e.id)) {
              return false;
            } else {
              return true;
            }
          }),
          oldValues: state.oldValues.filter((e) => {
            if (variableList.find((v) => v.id === e?.variable?.id)) {
              return false;
            } else {
              return true;
            }
          }),
        }));
      },

      moveVariable: (dragIndex, hoverIndex, type) => {
        set((state) => {
          return {
            [type]: update(state[type], {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, state[type][dragIndex]],
              ],
            }),
          };
        });
      },

      changeVariables: (changedList, type) => {
        set((state) => {
          const _oldValue = [...state.oldValues];
          const _variables = [...state[type]];
          changedList.forEach((element) => {
            const _selectedOldValue = _oldValue.find(
              (e) => e?.variable?.id === element.id
            );
            if (_selectedOldValue) {
              const variableIndex = _variables.findIndex(
                (e) => e.id === element.id
              );
              _selectedOldValue.variable = element;

              if (variableIndex > -1) {
                const _oldVariable = { ..._variables[variableIndex] };
                _variables[variableIndex] = element;
                // if (_oldVariable.name !== element.name) {
                // we have to change the variable name in the nodes
                _selectedOldValue.nodes.forEach((node) => {
                  const _selectedNode = useFlowStore
                    .getState()
                    .getNode(node.id);
                  const _registerIds = Object.keys(node);
                  _registerIds.forEach((registerId) => {
                    if (registerId !== "id") {
                      setNestedValue(_selectedNode, registerId, element?.name);
                    }
                  });
                  useFlowStore.getState().updateNode(node.id, _selectedNode);
                });
              }
              // }
            }
          });
          return {
            oldValues: _oldValue,
            [type]: _variables,
          };
        });
      },
      clearRemovedOldValues: (nodeId) => {
        set((state) => {
          const _oldValues = [...state.oldValues];
          const _removedOnes = [...state.removedOldValues];
          _removedOnes.forEach((_removed) => {
            const _nodeOldValues = _removed.nodes.find(
              (e) => e.id === nodeId
            )?.oldValues;
            _oldValues.forEach((oldValue) => {
              const _node = oldValue.nodes.find((e) => e.id === nodeId);
              if (_node && _removed.variableName === oldValue.variable.name) {
                _nodeOldValues?.forEach((removed) => {
                  if (_node[removed]) {
                    delete _node[removed];
                  }
                });

                if (Object.keys(_node).length <= 2) {
                  oldValue.nodes.splice(
                    oldValue.nodes.findIndex((e) => e.id === nodeId),
                    1
                  );
                }
              }
            });
          });
          get().cancelRemovingOldValue(nodeId);
          return { oldValues: _oldValues };
        });
      },

      cancelRemovingOldValue: (nodeId) => {
        set((state) => {
          const _removedOnes = [...state.removedOldValues];
          _removedOnes.forEach((_removed) => {
            _removed.nodes = _removed.nodes.filter((e) => e.id !== nodeId);
          });
          return {
            removedOldValues: _removedOnes,
          };
        });
      },

      removeOldValue: (variableName, registerId, nodeId) => {
        set((state) => {
          const _removedOnes = [...state.removedOldValues];
          const removedOldValueIndex = _removedOnes.findIndex(
            (e) => e.variableName === variableName
          );
          if (removedOldValueIndex > -1) {
            const _nodes = state.removedOldValues[removedOldValueIndex]?.nodes;
            const _nodeIndex = _nodes.findIndex((e) => e.id === nodeId);
            if (_nodeIndex > -1) {
              _nodes[_nodeIndex]?.oldValues.push(registerId);
              _removedOnes[removedOldValueIndex] = {
                ..._removedOnes[removedOldValueIndex],
                nodes: [..._nodes],
              };
            } else {
              _removedOnes[removedOldValueIndex] = {
                ..._removedOnes[removedOldValueIndex],
                nodes: [..._nodes, { id: nodeId, oldValues: [registerId] }],
              };
            }
            return { removedOldValues: _removedOnes };
          } else {
            const _removedOldValue = {
              variableName,
              nodes: [
                {
                  id: nodeId,
                  oldValues: [registerId],
                },
              ],
            };
            return {
              removedOldValues: [...state.removedOldValues, _removedOldValue],
            };
          }
        });
      },

      setVariables: (variables) => set({ Variables: variables }),

      setConstants: (constants) => set({ Constants: constants }),

      setOldValues: (oldValues) => {
        return set({ oldValues: oldValues });
      },

      removeNode: (nodeId) => {
        set((state) => {
          const _oldValues = [...state.oldValues];
          _oldValues.forEach((e) => {
            const nodeIndex = e.nodes.findIndex((n) => n.id === nodeId);
            if (nodeIndex > -1) {
              e.nodes.splice(nodeIndex, 1);
            }
          });

          return { oldValues: _oldValues };
        });
      },

      duplicateNode: (nodeId, newNodeId) => {
        set((state) => {
          const _oldValues = [...state.oldValues];
          _oldValues.forEach((e) => {
            const nodeIndex = e.nodes.findIndex((n) => n.id === nodeId);
            e.nodes.push({
              ...e.nodes[nodeIndex],
              id: newNodeId,
            });
          });

          return { oldValues: _oldValues };
        });
      },

      assignVariable: (variable, nodeId, registerId, oldValue) => {
        const _variables = get().Variables;
        const _constnats = get().Constants;
        let _variableType;
        let indexFounded = -1;
        if (_variables.findIndex((e) => e.name === oldValue) > -1) {
          _variableType = "Variables";
          indexFounded = _variables.findIndex((e) => e.name === oldValue);
        } else if (_constnats.findIndex((e) => e.name === oldValue) > -1) {
          _variableType = "Constants";
          indexFounded = _constnats.findIndex((e) => e.name === oldValue);
        }
        if (indexFounded < 0) {
          set((state) => {
            const _oldValues = [...state.oldValues];
            const _variableIndex = _oldValues.findIndex(
              (e) => e?.variable?.id === variable.id
            );
            if (_variableIndex > -1) {
              const _variableOldValue = _oldValues[_variableIndex];
              const _selectedNodeIndex = _variableOldValue.nodes.findIndex(
                (e) => e.id === nodeId
              );
              if (_selectedNodeIndex >= 0) {
                _variableOldValue.nodes[_selectedNodeIndex] = {
                  ..._variableOldValue.nodes[_selectedNodeIndex],
                  id: nodeId,
                  index: useFlowStore.getState().getNode(nodeId)?.id_by_user,
                  [registerId]: oldValue,
                };
              } else {
                _variableOldValue.nodes.push({
                  id: nodeId,
                  index: useFlowStore.getState().getNode(nodeId)?.id_by_user,
                  [registerId]: oldValue,
                });
              }
            } else {
              _oldValues.push({
                variable: variable,
                nodes: [
                  {
                    id: nodeId,
                    index: useFlowStore.getState().getNode(nodeId)?.id_by_user,
                    [registerId]: oldValue,
                  },
                ],
              });
            }
            return { oldValues: _oldValues };
          });
        } else {
          const _oldValues = [...get().oldValues];
          const _oldVariable = _oldValues.find(
            (e) => e?.variable?.name === oldValue
          );
          if (_oldVariable) {
            const _oldVariableNode = _oldVariable.nodes.find(
              (e) => e.id === nodeId
            );
            if (_oldVariableNode) {
              const _originalValue = _oldVariableNode[registerId];
              const _variableIndex = _oldValues.findIndex(
                (e) => e?.variable?.id === variable.id
              );
              if (_variableIndex > -1) {
                const _variableOldValue = _oldValues[_variableIndex];
                const _selectedNodeIndex = _variableOldValue.nodes.findIndex(
                  (e) => e.id === nodeId
                );
                if (_selectedNodeIndex >= 0) {
                  _variableOldValue.nodes[_selectedNodeIndex] = {
                    ..._variableOldValue.nodes[_selectedNodeIndex],
                    id: nodeId,
                    index: useFlowStore.getState().getNode(nodeId)?.id_by_user,
                    [registerId]: _originalValue,
                  };
                } else {
                  _variableOldValue.nodes.push({
                    id: nodeId,
                    index: useFlowStore.getState().getNode(nodeId)?.id_by_user,
                    [registerId]: _originalValue,
                  });
                }
              } else {
                _oldValues.push({
                  variable: variable,
                  nodes: [
                    {
                      id: nodeId,
                      index: useFlowStore.getState().getNode(nodeId)
                        ?.id_by_user,
                      [registerId]: _originalValue,
                    },
                  ],
                });
              }
              if (_oldVariableNode[registerId]) {
                delete _oldVariableNode[registerId];
              }

              if (Object.keys(_oldVariableNode).length <= 2) {
                _oldVariable.nodes.splice(
                  _oldVariable.nodes.findIndex((e) => e.id === nodeId),
                  1
                );
              }
              set({ oldValues: _oldValues });
            }
          }
        }
      },

      resetStore: () => {
        set(defaultValues);
      },
    }),
    {
      name: "variable-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    }
  )
);
