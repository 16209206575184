const getNestedValue = (params, id) => {
  const idParts = id.split("."); // Split the id by dots
  let result = params;
  for (const part of idParts) {
    if (result && result.hasOwnProperty(part)) {
      result = result[part];
    } else {
      // Handle invalid property path (optional)
      return null;
    }
  }

  return result;
};

export default getNestedValue;
