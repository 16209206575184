import { useFlowStore } from "../../store/flowStore";
import { useHistoryStore } from "../../store/historyStore";
import { useVariableStore } from "../../store/variableStore";

const clearProjectLocalStorage = () => {
  localStorage.removeItem("projectID");
  localStorage.removeItem("oldValues");
  localStorage.removeItem("projectName");
  localStorage.removeItem("name_by_user");
  localStorage.removeItem("projectData");
  localStorage.removeItem("highestIndex");
  localStorage.removeItem("Constants");
  localStorage.removeItem("Variables");
  localStorage.removeItem("events");
  useVariableStore.getState().resetStore();
  useFlowStore.getState().resetStore();
  useHistoryStore.getState().setHistoryListTest([]);
};

export default clearProjectLocalStorage;
