import Admin from "../../pages/authenticated/Admin/Admin";
import Debugging from "../../pages/authenticated/Debugging/Debugging";
import Home from "../../pages/authenticated/Home/Home";
import Testing from "../../pages/authenticated/Testing/Testing";
import {
  ADMIN_ROUTE,
  DEBUGGING_ROUTE,
  HOME_ROUTE,
  TESTING_ROUTE,
} from "./app-routes";

export const authRoutes = [
  { route: HOME_ROUTE, Component: Home, type: "user", exact: false },
  { route: DEBUGGING_ROUTE, Component: Debugging, type: "debugging" },
  { route: TESTING_ROUTE, Component: Testing, type: "testing" },
  { route: ADMIN_ROUTE, Component: Admin, type: "admin" },
];
