import React, { useState } from "react";
import LoadingButton from "../../../Common/LoadingButton";
const ProjectRow = ({
  project,
  loadProjectHandler,
  deleteProjectHandler,
  deletingProject,
  deleteLoading,
}) => {
  const [deleting, setDeleting] = useState(false);
  const formatDate = (date) => {
    const _date = new Date(date);
    return _date.toLocaleString();
  };
  const deleteConfirmHandler = () => {
    deleteProjectHandler(project.id);
  };
  return (
    <tr
      style={{
        background:
          // eslint-disable-next-line
          localStorage.getItem("projectID") == project.id ? "#654" : "",
      }}
      className="projectHolder"
      key={project.id}
    >
      <td>
        <button
          onClick={loadProjectHandler.bind(this, project.id)}
          className="loadBtn"
        >
          Load
        </button>
      </td>
      <td>{project.name_by_user}</td>
      <td>Expert</td>
      <td>MQL4</td>
      <td>{formatDate(project?.created_at)}</td>
      <td>{formatDate(project?.updated_at)}</td>
      <td>
        <div className="deleteHolder">
          <LoadingButton
            onClick={() => setDeleting((e) => !e)}
            className="deleteBtn"
            title={"Delete"}
            isLoading={deletingProject === project.id && deleteLoading}
          >
            {deleting && "?"}
          </LoadingButton>
          {deleting && (
            <div className="deleteConfirmation">
              <LoadingButton
                style={{ background: "red" }}
                onClick={deleteConfirmHandler}
                isLoading={deletingProject === project.id && deleteLoading}
              >
                Yes
              </LoadingButton>
              <LoadingButton
                isLoading={deletingProject === project.id && deleteLoading}
                onClick={() => setDeleting(false)}
              >
                No
              </LoadingButton>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ProjectRow;
