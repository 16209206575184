export const INDICATORS = "/indicators";

export const ALL_INDICATORS = INDICATORS + "/all-indicators";

export const CANDLES_INFO = INDICATORS + "/candles-info";

export const MARKET_PROPERTIES = INDICATORS + "/market-props";

export const FORMULA_SIGNS = INDICATORS + "/get-mathematical-signs-formula/";

export const FORMULA_DETAILS = INDICATORS + "/get-formula-details/";

export const MENU = "/menu";

export const MENU_ITEMS =
  "ca31ddc1-4477-4c0b-98e8-9abf106d267f/menu-parent-categories/";

export const MODAL_DETAIL =
  "ca31ddc1-4477-4c0b-98e8-9abf106d267f/menu-child-category/";

export const CONDITION_SECTION =
  "/6c571c84-13cd-4710-b50c-f74c31591e0e/condition-menu-child-categories/";
export const CONDITION_ROW2 =
  "6c571c84-13cd-4710-b50c-f74c31591e0e/dropdown-condition-menu-child-categories/";

export const CONDITION_INPUTS = (row1, row2) =>
  `/6c571c84-13cd-4710-b50c-f74c31591e0e/nested-access/${row1}/${row2}/`;

export const ALL_CONDITIONS = MENU + "/all-conditions-dropdown";
export const VALUE_INFO = ALL_CONDITIONS + "/2";

export const PROCESS_DATA = "/sell-buy/process-data";

export const SELL_BUY = "/sell-buy";

export const SELL_BUY_STRUCTURE = SELL_BUY + "/sell-buy-structure";

export const TIME_FILTERS = "/time-filters";

export const ONCE_PER_BAR = TIME_FILTERS + "/once-per-bar-block/";

export const PENDING_ORDER = SELL_BUY + "/pending-order-sell-buy-structure";

export const CHECK_TRADES_AND_ORDERS_COUNT = "/check-trades-and-orders-count";

export const NO_TRADES_NEARBY =
  CHECK_TRADES_AND_ORDERS_COUNT + "/no-trades-near-by/";
export const IF_TRADE_DETAILS =
  CHECK_TRADES_AND_ORDERS_COUNT + "/if-trade-details-details/";

export const CHECK_TRADING_CONDITION = "/check-trading-condition";

export const ALL_PROFIT_STRUCTURE =
  CHECK_TRADING_CONDITION + "/all-profit-structure";

export const USERS = "/users";

export const LOGIN = USERS + "/login/";

export const REGISTER = USERS + "/register/";

export const LOGOUT = USERS + "/logout/";

export const REFRESH_TOKEN = USERS + "/api/token/refresh/";

export const RESET_PASSWORD = USERS + "/password-reset-request/";

export const EVENTS = "/ca31ddc1-4477-4c0b-98e8-9abf106d267f/events-item/";

export const CAPTCHA = USERS + "/captcha/";

export const VERIFY_CAPTCHA = USERS + "/verify-captcha/";

export const CSRF_TOKEN = USERS + "/api/get-csrf-token/";
