import CommonProperty from "../Components/ProppertiesModal/CommonProperty";
import ConditionModal from "../Components/ProppertiesModal/ConditionModal";
import FormulaModal from "../Components/ProppertiesModal/FormulaModal";
import blockName from "./blocks/blockNames";

const PropertiesModalComponents = ({ nodeId, type }) => {
  if (type === blockName.condition) {
    return <ConditionModal nodeId={nodeId} />;
  } else if (type === blockName.formula) {
    return <FormulaModal nodeId={nodeId} />;
  } else {
    return <CommonProperty blockName={type} nodeId={nodeId} />;
  }
};

export default PropertiesModalComponents;
