const setNestedValue = (params, id, value) => {
  const idParts = id.split("."); // Split the id by dots
  let result = params;
  for (let i = 0; i < idParts.length - 1; i++) {
    const part = idParts[i];
    if (result[part] === undefined) {
      result[part] = {}; // Create an empty object if it doesn't exist
    }
    result = result[part];
  }

  const lastPart = idParts[idParts.length - 1];
  result[lastPart] = value;
};

export default setNestedValue;