import React, { useState } from "react";
import StyledAccordion from "./StyledAccordion";
import AccordionItem from "./AccordionItem";

const Accordion = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const itemSelectHandler = (index) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  return (
    <StyledAccordion>
      {items &&
        items
          ?.filter((item) => item?.is_shown)
          ?.sort((a, b) => (Number(a.id_sent) > Number(b.id_sent) ? 1 : -1))
          .map((item, index) => (
            <AccordionItem
              color={item?.color_font}
              bgcolor={item?.color_bg}
              category={item?.slug?.replace(/-/g, "_")}
              isOpen={selectedIndex === index ? true : false}
              list={item?.parent_name}
              key={index}
              title={item?.name}
              onClick={itemSelectHandler.bind(this, index)}
            />
          ))}
    </StyledAccordion>
  );
};

export default Accordion;
