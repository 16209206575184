import React, { useCallback } from "react";
import StyledPropertiesModal from "./StyledPropertiesModal";
import { ReactComponent as Cross } from "../../assests/icons/cross.svg";
import { FormProvider, useForm } from "react-hook-form";
import PropertiesModalComponents from "../../constants/propertiesModalComponents";
import { useReactFlow, ViewportPortal } from "@xyflow/react";
import deleteNodeHandler from "../../utils/functions/deleteNodeHandler";

import Draggable from "react-draggable";
import useUpdateProject from "../../utils/hooks/useUpdateProject";
import { useFlowStore } from "../../store/flowStore";
import { useVariableStore } from "../../store/variableStore";
import { useModalStore } from "../../store/modalStore";

const PropertiesModal = ({ data }) => {
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const { closeModal } = useModalStore();
  // const { setNodes, setEdges } = useReactFlow();
  const { updateProject } = useUpdateProject();
  const methods = useForm({
    shouldUnregister: true,
    defaultValues:
      flowStore.nodes?.find((property) => property.id === data.id)?.params &&
      Object.keys(
        flowStore.nodes?.find((property) => property.id === data.id)?.params
      ).length > 0
        ? flowStore.nodes?.find((property) => property.id === data.id)
        : {},
  });
  const deletingNodeHandler = useCallback(() => {
    const id = data?.id;
    const _events = deleteNodeHandler(
      () => {},
      () => {},
      closeModal.bind(this, data?.id),
      id
    );
    updateProject(_events, false, true, `Delete block (${data?.index})`);

    // eslint-disable-next-line
  }, [data?.id, closeModal, updateProject]);

  const submitHandler = (formData) => {
    const _nodes = flowStore.nodesData;
    const _node = _nodes.find((item) => item.id === data.id).data;
    const _oldProps = flowStore.nodes;
    const updateIndex = _oldProps.findIndex((i) => i.id === data.id);
    if (updateIndex > -1) {
      _oldProps[updateIndex] = {
        params: {},
        ...formData,
        id: _node?.id,
        id_by_user: _node?.index,
        blockName: _node.title,
        category: _node?.category,
        block_name_mql: _node.slug.replace(/-/g, "_"),
      };
      flowStore.setNodes(_oldProps);
      updateProject(
        flowStore.events,
        false,
        true,
        `Update parameters (${_node?.index})`
      );
    } else {
      const _properties = [
        ..._oldProps,
        {
          id: _node.id,
          params: {},
          id_by_user: _node?.index,
          category: _node?.category,
          block_name_mql: _node.slug.replace(/-/g, "_"),
          blockName: _node.title,
          ...formData,
        },
      ];
      flowStore.setNodes(_properties);
      updateProject(
        flowStore.events,
        false,
        true,
        `Update parameters (${_node?.index})`
      );
    }
    variableStore.clearRemovedOldValues(_node?.id);
    closeModal(data?.id);
  };

  return (
    <ViewportPortal className="nodrag">
      <Draggable
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        handle=".header"
        defaultPosition={{ x: data?.x, y: data?.y }}
      >
        <StyledPropertiesModal
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="header">
            <button onClick={closeModal.bind(this, data?.id)}>
              <Cross />
            </button>
            <span>{data?.title}</span>
          </div>
          <div className="content">
            <FormProvider {...methods}>
              <form autoComplete="off">
                <PropertiesModalComponents type={data?.slug} nodeId={data.id} />
                <div className="bottomSection">
                  <div className="changeStatus">
                    <span>Change Status to</span>
                    <input className="statusCheckbox" type="checkbox" />
                    <input className="statusText" type="text" />
                  </div>
                  <div className="btnHolder">
                    <button onClick={methods.handleSubmit(submitHandler)}>
                      Update
                    </button>
                    <button onClick={deletingNodeHandler}>Delete</button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </StyledPropertiesModal>
      </Draggable>
    </ViewportPortal>
  );
};

export default PropertiesModal;
