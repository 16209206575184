import styled from "styled-components";

const StyledSideBar = styled.aside`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  h1 {
    color: #ddd;
  }
  button {
    margin: 5px auto;
    background-color: lightblue;
    padding: 10px;
    border-radius: 10px;
    color: darkblue;
    font-weight: 600;
    box-shadow: 0 0 6px blue;
  }
  .variableBtnHolder {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3px;
    button {
      margin: 0;
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
      color: #ddd;
    }
    :first-child {
      background: #997755;
    }
    :last-child {
      background: #555566;
    }
  }
  .logOutHolder {
    color: #ddd;
  }

  .nameHolder {
    position: relative;
    input {
      min-width: 180px;
      margin-right: 5px;
      font-size: 20px;
      margin: 0;
      font-size: 12px;
      background-color: #333;
      color: #ddd;
      border: 1px solid #717171;
      font-size: 18px;
      padding: 8px;
      text-align: center;
    }
    .changeNameBtn {
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      color: #d6d6d6;
      border: 1px solid #111;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      padding: 0;
      text-align: center;
      position: absolute;
      top: -3px;
      right: 0;
      fill: green;
      background: transparent;
      box-shadow: none;
      border: none;
    }
  }
  .exportBtn {
    position: absolute;
    top: 40px;
    width: 50px;
    height: 30px;
    /* background: linear-gradient(to bottom, #617ba7, #446189); */
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-position: top center;
    background-size: cover;
    padding: 0;
    cursor: pointer;
  }
  .badge {
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #c97;
    text-shadow: 1px 1px 1px #000;
    padding-right: 6px;
    background: transparent !important;
  }
`;

export default StyledSideBar;
