const exceptions = {
  "Candle ID": "shift",
  Time: "time_str",
  custom: "custom",
  openAtCustomPrice: "OPEN_AT_CUSTOM_PRICE",
  indicator: "indicator",
  adjust: "Adjust",
  moreSettings: "more_settings",
  conditionParams: {
    operator: {
      label: ">",
      cross_width: 1,
    },
    left: {
      row1: "indicator",
      row2: "ma",
      params: {
        value: "1",
        ma_period: "5",
        ma_shift: "0",
        ma_method: "MODE_SMA",
        applied_price: "PRICE_CLOSE",
        adjust: "",
        symbol: "",
        timeframe: "PERIOD_CURRENT",
        shift: "0",
      },
    },
    right: {
      row1: "indicator",
      row2: "ma",
      params: {
        value: "1",
        ma_period: "20",
        ma_shift: "0",
        ma_method: "MODE_SMA",
        applied_price: "PRICE_CLOSE",
        adjust: "",
        symbol: "",
        timeframe: "PERIOD_CURRENT",
        shift: "0",
      },
    },
  },
  formulaParams: {
    operator: {
      label: "+",
    },
    left: {
      row1: "value",
      row2: "Numeric",
      params: {
        value: "1",
        adjust: "",
      },
    },
    right: {
      row1: "value",
      row2: "Numeric",
      params: {
        value: "1",
        adjust: "",
      },
    },
    adjust: "",
    variable: "",
  },
};

export default exceptions;
