import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import StyledFlowCanvas from "./StyledFlowCanvas";
import CustomNode from "../CustomNode";
import CustomEdge from "../CustomEdge";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../utils/hooks/useFetch";
import { PROCESS_DATA } from "../../constants/routes/api-routes";
import Loader from "../Common/Loader";
import useUpdateProject from "../../utils/hooks/useUpdateProject";
import { useHistoryStore } from "../../store/historyStore";
import decompressData from "../../utils/functions/decompressData";
import { useFlowStore } from "../../store/flowStore";
import Cookies from "js-cookie";
import { useVariableStore } from "../../store/variableStore";
import PropertiesModal from "../ProppertiesModal";
import { ViewportPortal } from "@xyflow/react";
import deleteImage from "../../assests/img/delete.png";
import pasteImage from "../../assests/img/page_white_paste.png";
import useMutate from "../../utils/hooks/useMutate";
import { useModalStore } from "../../store/modalStore";
import copyImage from "../../assests/img/page_white_copy.png";
import settingsImage from "../../assests/img/project_settings.png";
import Draggable from "react-draggable";
import ProjectSettings from "../ProjectSettings";
import { projectSettingsDefaults } from "../../constants/projectSettingsDefaults";
const edgeTypes = {
  customEdge: CustomEdge,
};
const nodeTypes = {
  customNode: CustomNode,
};
const FlowCanvas = ({ reRender, setRerender }) => {
  const reactFlowWrapper = useRef(null);
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const [movingNode, setMovingNode] = useState();
  const { modals } = useModalStore();
  const { updateProject } = useUpdateProject();
  const [nodes, setNodes, onNodesChange] = useNodesState(flowStore.nodesData);
  const [edges, setEdges, onEdgesChange] = useEdgesState(flowStore.edges);
  const [selectionContextMenu, setSelectionContextMenu] = useState(null);
  const historyStore = useHistoryStore();
  const [projectID, setProjectID] = useState(localStorage.getItem("projectID"));
  const [mutateLoading, setMutateLoading] = useState(false);
  const [deleteEdge, setDeleteEdge] = useState(null);
  const [paneContextMenu, setPaneContextMenu] = useState(null);
  const [isMultipleNodes, setIsMultipleNodes] = useState(false);
  const [projectSettingsModal, setProjectSettingsModal] = useState(false);
  const {
    data,
    isSuccess,
    isLoading: projectLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, projectID],
    `${PROCESS_DATA}/${projectID}/`,
    null,
    null,
    { enabled: !!projectID && !!Cookies.get("token") }
  );

  const { data: lastProject, isLoading } = useFetch(
    "GET",
    [PROCESS_DATA],
    `${PROCESS_DATA}?start=0&end=1`,
    null,
    null,
    {
      cacheTime: 0,
      caches: "no-cache",
      enabled: !localStorage.getItem("projectID") && !!Cookies.get("token"),
    }
  );

  const {
    mutate,
    isSuccess: createProjectSuccess,
    isError: createProjectError,
  } = useMutate("POST", [PROCESS_DATA, "create"], PROCESS_DATA);

  const {
    data: projectData,
    refetch: projectRefetch,
    isLoading: projectDataLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, localStorage.getItem("projectName")],
    PROCESS_DATA + "/search",
    { selected_name: localStorage.getItem("projectName") },
    null,
    { enabled: false }
  );
  useEffect(() => {
    if (lastProject) {
      const _data = lastProject?.data?.results;
      if (_data && _data.length < 1) {
        setMutateLoading(true);
        const _uuid = uuidv4();
        const _sendingData = {
          events: {
            on_init: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_timer: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_tick: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_trade: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_chart: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
            on_deinit: {
              nodes: [],
              nodesData: [],
              edges: [],
            },
          },
          variables: [],
          constants: [],
          oldValues: [],
          project_options: projectSettingsDefaults,
        };
        mutate({
          data: _sendingData,
          selected_name: _uuid,
          name_by_user: "unnamed",
          highestIndex: 1,
        });
        localStorage.setItem("projectName", _uuid);
      } else {
        const _selectedProject = _data[0];
        variableStore.resetStore();
        localStorage.setItem("projectID", _selectedProject?.id);
        localStorage.setItem("projectData", JSON.stringify(_selectedProject));
        window.location.reload();
      }
    }
    // eslint-disable-next-line
  }, [lastProject]);

  useEffect(() => {
    if (createProjectError || createProjectSuccess) {
      setMutateLoading(false);
    }
    if (createProjectSuccess) {
      projectRefetch();
    }
    // eslint-disable-next-line
  }, [createProjectSuccess, createProjectError]);

  useEffect(() => {
    if (projectData) {
      const _selectedProject = projectData?.data?.find(
        (item) => item?.selected_name === localStorage.getItem("projectName")
      );
      variableStore.resetStore();
      localStorage.setItem("projectID", _selectedProject?.id);
      localStorage.setItem("projectData", JSON.stringify(_selectedProject));
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (isSuccess && data) {
      setEdges([]);
      const _data = data?.data;
      localStorage.setItem("projectData", JSON.stringify(_data));
      localStorage.setItem("name_by_user", _data?.name_by_user);
      localStorage.setItem("projectName", _data?.selected_name);
      // variableStore.setOldValues(_data?.oldValues ?? []);
      const historyIndex = _data.project_detail_levels.findIndex(
        (e) => e.assigned_name === historyStore.selectedHistoryId
      );
      const _json =
        historyIndex >= 0 &&
        historyIndex < _data.project_detail_levels.length - 1
          ? _data.project_detail_levels[historyIndex].json_file
          : _data.json_file;
      historyStore.setHistoryListTest(_data.project_detail_levels);
      const _projectOptions = JSON.parse(_data.json_file)?.data?.project_options;
      localStorage.setItem("projectSettings", JSON.stringify(_projectOptions));
      const _jsonData = JSON.parse(_json);
      let _parsedData = _jsonData?.data ? _jsonData?.data : _jsonData;
      if (_parsedData?.compressedData) {
        _parsedData = {
          ...JSON.parse(decompressData(_parsedData?.compressedData))?.data,
          highestIndex: JSON.parse(decompressData(_parsedData?.compressedData))
            ?.highestIndex,
        };
      }
      if (_parsedData?.events) {
        for (const key in _parsedData?.events) {
          if (Object.hasOwnProperty.call(_parsedData?.events, key)) {
            const element = _parsedData?.events[key];
            element.nodesData.forEach((e) => {
              e.selected = false;
              e.dragging = false;
            });
          }
        }
        flowStore.setEvents(_parsedData?.events);
        flowStore.setSelectedEvent("on_tick");

        variableStore.setConstants(_parsedData?.constants ?? []);
        variableStore.setVariables(_parsedData?.variables ?? []);
        variableStore.setOldValues(_parsedData?.oldValues ?? []);

        // localStorage.setItem(
        //   "Constants",
        //   JSON.stringify(_parsedData?.constants ?? [])
        // );
        // localStorage.setItem(
        //   "Variables",
        //   JSON.stringify(_parsedData?.variables ?? [])
        // );
        localStorage.setItem(
          "highestIndex",
          Number(
            historyIndex >= 0 &&
              historyIndex < _data.project_detail_levels.length - 1
              ? _parsedData?.highestIndex
              : _jsonData?.highestIndex
          )
        );
      }
      setRerender((e) => !e);
    }
    // eslint-disable-next-line
  }, [data, isSuccess]);

  useEffect(() => {
    try {
      setNodes(flowStore.nodesData);
      setEdges(flowStore.edges);
      historyStore.setHistoryLoading(false);
    } catch (err) {
      console.log({ err });
    }
    if (localStorage.getItem("projectID") !== projectID) {
      setProjectID(localStorage.getItem("projectID"));
    }
    // eslint-disable-next-line
  }, [flowStore.nodesData, flowStore.edges]);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const ref = useRef(null);
  const onConnect = useCallback(
    async (params) => {
      const _edges = flowStore.edges;
      if (
        !_edges.find(
          (edge) =>
            edge.source === params.source && edge.target === params.target
        )
      ) {
        const _id = uuidv4();
        await flowStore.addEdge({ ...params, type: "customEdge", id: _id });
        updateProject(flowStore?.events);
        setEdges((eds) => {
          return addEdge({ ...params, type: "customEdge", id: _id }, eds);
        });
      }
    },
    [flowStore, setEdges, updateProject]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance?.setCenter(0, 0, { zoom: 1, duration: 500 });
    }
  }, [reactFlowInstance]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("application/reactflow");
      const _nodeData = JSON.parse(
        event.dataTransfer.getData("application/reactflow/data")
      );
      // check if the dropped element is valid
      if (
        typeof type === "undefined" ||
        !type ||
        !_nodeData ||
        typeof _nodeData === "undefined"
      ) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      let _id = uuidv4();
      const _index =
        Number(localStorage.getItem("highestIndex")) >= 1
          ? Number(localStorage.getItem("highestIndex"))
          : 1;
      const newNode = {
        id: _id,
        type,
        position,
        data: {
          ..._nodeData,
          index: _index,
          id: _id,
          loading: true,
        },
      };
      localStorage.setItem("highestIndex", Number(_index) + 1);
      // flowStore.addNode(newNode);
      flowStore.addNodesData(newNode);
      setNodes((nds) => nds.concat(newNode));
    }, // eslint-disable-next-line
    [reactFlowInstance, setNodes]
  );

  const nodeDragHandler = (event, node) => {
    // const _nodes = getNestedLocalStorage("nodesData");
    flowStore.deselectNode(node.id);
    if (!isMultipleNodes) {
      const _nodes = flowStore.nodesData;
      const _node = _nodes.find((item) => item.id === node.id);
      if (
        JSON.stringify(movingNode.position) !== JSON.stringify(node.position) ||
        JSON.stringify(movingNode.positionAbsolute) !==
          JSON.stringify(node.positionAbsolute)
      ) {
        _node.position = node.position;
        _node.positionAbsolute = node.positionAbsolute;
        flowStore.moveNode(node.id, node.position, node.positionAbsolute);
        updateProject(flowStore.events);
        setMovingNode(null);
        // setRerender((e) => !e);
      }
    }
  };

  const dragStartHandler = (e, node) => {
    setMovingNode(node);
  };

  const movingMultipleNodesHandler = (e, nodes) => {
    flowStore.movingMultipleNodes(nodes);
    updateProject(flowStore.events);
    setIsMultipleNodes(false);
  };

  const slectionContextMenuHandler = (e, nodes) => {
    e.preventDefault();
    setSelectionContextMenu({
      x: e.clientX,
      y: e.clientY,
      nodes: [...nodes],
    });
  };

  const multipleNodesDeleteHandler = (nodes) => {
    flowStore.deleteMultipleNodes(nodes);
    updateProject(flowStore.events);
    setSelectionContextMenu(null);
  };

  const paneContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const _xy = { x: e.clientX, y: e.clientY };
    const _position = reactFlowInstance.screenToFlowPosition(_xy);
    setPaneContextMenu(_position);
  };
  const pasteHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (localStorage.getItem("copiedItem")) {
      const _copiedItems = JSON.parse(localStorage.getItem("copiedItem"));

      if (_copiedItems.nodes.length === 1) {
        //single block paste
        const _nodesData = _copiedItems.nodes[0].nodesData;
        const node = _copiedItems.nodes[0].node;
        const id = _nodesData?.id;
        const _id = uuidv4();
        const position = { ...paneContextMenu };
        const _index = Number(localStorage.getItem("highestIndex"));
        flowStore.addNodesData({
          ..._nodesData,
          data: {
            ..._nodesData.data,
            index: _index,
            id: _id,
          },
          id: _id,
          position,
          positionAbsolute: position,
          selected: false,
          dragging: false,
        });
        flowStore.addNode({
          ...node,
          id: _id,
          id_by_user: _index,
        });
        variableStore.duplicateNode(id, _id);
        updateProject(flowStore.events, false, true, `copy single block`);
        localStorage.setItem("highestIndex", Number(_index) + 1);
      } else {
        //multiple block paste

        const _copiedItems = JSON.parse(localStorage.getItem("copiedItem"));
        const _edges = _copiedItems?.edges;
        const _nodes = _copiedItems.nodes;
        const _index = Number(localStorage.getItem("highestIndex"));
        let newIndex = _index;
        const nodesIds = [];
        _nodes.forEach((item) => {
          const _nodesData = item;
          const node = flowStore.getNode(item.id);
          const _id = uuidv4();
          // Calculate the offset between the original position and the paste position
          const offsetX = paneContextMenu.x - _nodes[0].position.x;
          const offsetY = paneContextMenu.y - _nodes[0].position.y;

          // Apply the offset to each node's position
          const position = {
            x: _nodesData.position.x + offsetX,
            y: _nodesData.position.y + offsetY,
          };
          nodesIds.push({
            id: _id,
            oldId: _nodesData.id,
          });
          flowStore.addNodesData({
            ..._nodesData,
            data: {
              ..._nodesData.data,
              index: newIndex,
              id: _id,
            },
            id: _id,
            position,
            positionAbsolute: position,
            selected: false,
            dragging: false,
          });

          flowStore.addNode({
            ...node,
            id: _id,
            id_by_user: newIndex,
          });
          variableStore.duplicateNode(_nodesData.id, _id);
          newIndex++;
        });

        _edges.forEach((edge) => {
          const _source = nodesIds.find(
            (node) => node.oldId === edge.source
          )?.id;
          const _target = nodesIds.find(
            (node) => node.oldId === edge.target
          )?.id;
          flowStore.addEdge({
            ...edge,
            id: uuidv4(),
            source: _source,
            target: _target,
          });
        });
        localStorage.setItem("highestIndex", newIndex);
        updateProject(flowStore.events, false, true, `copy multiple blocks`);
      }
    }
    setPaneContextMenu(null);
  };
  const edgeDeleteHandler = (e, edge) => {
    e.preventDefault();
    const _xy = { x: e.clientX, y: e.clientY };
    const _position = reactFlowInstance.screenToFlowPosition(_xy);
    setDeleteEdge({ ..._position, edgeId: edge?.id });
  };

  const closeModalHandler = (e) => {
    e.stopPropagation();
    setDeleteEdge(null);
  };

  const deleteEdgeHandler = async (e) => {
    e.stopPropagation();
    await flowStore.deleteEdge(deleteEdge?.edgeId);
    updateProject(flowStore?.events);
    setDeleteEdge(null);
  };

  const onSelectionStart = useCallback(() => {
    setNodes((nds) =>
      nds.map((_node) => {
        return { ..._node, selected: false, dragging: false };
      })
    );
    setIsMultipleNodes(true);
  }, [setNodes]);

  const onSelectionChange = useCallback((elements) => {
    setNodes((nds) =>
      nds.map((node) => {
        return {
          ...node,
          selected: elements.nodes.some((n) => n.id === node.id),
        };
      })
    );
  }, [setNodes]);

  const selectionCopyHandler = () => {
    const _edges = flowStore.edges.filter((edge) =>
      selectionContextMenu?.nodes.find(
        (node) => node.id === edge.source || node.id === edge.target
      )
    );
    localStorage.setItem(
      "copiedItem",
      JSON.stringify({ nodes: selectionContextMenu?.nodes, edges: _edges })
    );
    setSelectionContextMenu(null);
  };

  return (
    <>
      {selectionContextMenu && (
        <div
          className="ContextMenuHolder"
          style={{
            position: "absolute",
            top: selectionContextMenu?.y,
            left: selectionContextMenu?.x,
            zIndex: 99,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            onClick={multipleNodesDeleteHandler.bind(
              this,
              selectionContextMenu?.nodes
            )}
          >
            <img src={deleteImage} alt="deleteImage" />
            Delete nodes
          </button>
          <button onClick={selectionCopyHandler}>
            <img src={copyImage} alt="copyImage" />
            Copy nodes
          </button>
        </div>
      )}
      {projectSettingsModal && (
        <ProjectSettings setModal={setProjectSettingsModal} />
      )}
      <StyledFlowCanvas className="dndflow">
        {((!localStorage.getItem("projectID") && Cookies.get("token")) ||
          projectLoading ||
          projectDataLoading ||
          mutateLoading ||
          isLoading) && (
          <div className="loadingScreen">
            Loading <Loader />
          </div>
        )}

        <ReactFlowProvider>
          <div
            onClick={() => setPaneContextMenu(null)}
            className={`reactflow-wrapper relative`}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              ref={ref}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodesConnectable={true}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onPaneClick={() => {
                setSelectionContextMenu(null);
                setDeleteEdge(null);
              }}
              onNodeDragStop={nodeDragHandler}
              onNodeDragStart={dragStartHandler}
              onSelectionEnd={(e, nodes) => console.log({ e })}
              onSelectionDragStop={movingMultipleNodesHandler}
              onSelectionDragStart={onSelectionStart}
              onSelectionContextMenu={slectionContextMenuHandler}
              onPaneContextMenu={paneContextMenuHandler}
              onEdgeContextMenu={edgeDeleteHandler}
              fitView
              onSelectionChange={onSelectionChange}
              selectNodesOnDrag={false}
            >
              {modals &&
                modals.map((modal) => (
                  <PropertiesModal key={modal?.id} data={modal} />
                ))}
              <button
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                  zIndex: 999999,
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => setProjectSettingsModal(true)}
              >
                <img src={settingsImage} alt="settingsImage" />
              </button>

              {paneContextMenu && (
                <ViewportPortal>
                  <div
                    style={{
                      top: paneContextMenu.y,
                      left: paneContextMenu.x,
                      pointerEvents: "all",
                    }}
                    className="paneContextMenu"
                  >
                    <button onClick={pasteHandler}>
                      <img alt="paste" src={pasteImage} />
                      Paste
                    </button>
                  </div>
                </ViewportPortal>
              )}

              {deleteEdge && (
                <ViewportPortal>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      top: deleteEdge?.y,
                      left: deleteEdge?.x,
                      pointerEvents: "all",
                    }}
                    className="deleteModal"
                  >
                    <span>Delete this connection?</span>
                    <div className="btnHolder">
                      <button onClick={deleteEdgeHandler}>Yes</button>
                      <button onClick={closeModalHandler}>No</button>
                    </div>
                  </div>
                </ViewportPortal>
              )}
              <Controls />
              <MiniMap
                style={{ height: 120, background: "#666" }}
                zoomable
                pannable
              />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </StyledFlowCanvas>
    </>
  );
};

export default FlowCanvas;
