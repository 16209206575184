import styled from "styled-components";

const StyledFormulaModal = styled.div`
  .detailsContainer {
    margin: auto;
    width: 50%;
  }
  .valueFetchHolder {
    display: flex;
    align-items: center;
  }
  .operator {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      color: #cacaca;
    }
    select {
      margin-top: 10px;
      border: 1px solid black;
      font-size: 20px;
      padding: 0 6px;
      height: 30px;
      max-width: 60px;
      background: #333;
      color: #ddd;
    }
  }
`;

export default StyledFormulaModal;
