import styled from "styled-components";

const StyledHistorySection = styled.div`
  z-index: 99;
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0;
  > button {
    border: none;
    background: none;
    color: #ddd;
    cursor: pointer;
    font-size: 18px;
  }
  .dateHolder {
    pointer-events: none;
    font-size: 12px;
    white-space: nowrap;
    color: #888 !important;
  }
  .historyMenu {
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 3px 3px 1px #000;
    background-color: #aaa;
    border: 1px solid #333;
    height: 250px;
    overflow-y: auto;
    white-space: nowrap;

    .historyItem {
      font-size: 14px;
      user-select: none;
      cursor: pointer;
      color: #333;
      padding: 8px;
      border-bottom: 1px solid #666;
    }
    .selected {
      background-color: #fc3;
      color: #555;
    }
    .inActive {
      background-color: #888;
      color: #777 !important;
    }
  }
`;

export default StyledHistorySection;
