import pako from "pako";

const compressData = (largeString) => {
  if (!JSON.parse(largeString)?.compressedData) {
    // Compress the data
    const compressedData = pako.gzip(largeString);

    // Convert the compressed data to a base64 string
    const base64CompressedData = btoa(
      String.fromCharCode(...new Uint8Array(compressedData))
    );
    return base64CompressedData;
  } else {
    return JSON.parse(largeString)?.compressedData;
  }
};

export default compressData;
