import React, { useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "@xyflow/react";
import { ReactComponent as Bin } from "../../assests/icons/bin.svg";
import "./StyledCustomEdge.css";
import { useFlowStore } from "../../store/flowStore";
import useUpdateProject from "../../utils/hooks/useUpdateProject";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEndId,
}) => {
  const { updateProject } = useUpdateProject();
  const flowStore = useFlowStore();
  const [modal, setModal] = useState(false);
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = (e) => {
    e.preventDefault();
    setModal(true);
  };
  const deleteEdgeHandler = async () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    await flowStore.deleteEdge(id);
    updateProject(flowStore?.events);
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEndId} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan crossHolder"
        >
          {modal && (
            <div className="deleteModal">
              <span>Delete this connection?</span>
              <div className="btnHolder">
                <button onClick={deleteEdgeHandler}>Yes</button>
                <button onClick={closeModalHandler}>No</button>
              </div>
            </div>
          )}
          <button className="edgebutton" onClick={onEdgeClick}>
            <Bin />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
