import styled from "styled-components";

const StyledCommonProperty = styled.div`
  display: flex;
  /* width: 200px; */
  flex-direction: column;
  align-items: center;

  white-space: nowrap;
  select,
  input[type="text"],
  input[type="number"] {
    font-size: 12px;
    margin: 3px 0;
    padding: 2px;
    background: #333;
    color: #ddd;
    width: fit-content;
    cursor: pointer;
    border-radius: 0 3px 0 3px;
    option {
      width: 100%;
    }
  }
  .inputHolder {
    display: flex;
    margin: 3px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input[type="text"] {
      width: 36px;
      margin: 0;
    }
    select {
      margin: 0;
    }
    span {
      color: #ddd;
      font-size: 12px;
      margin: 0 5px;
      background: transparent;
    }
  }
`;

export default StyledCommonProperty;
