import React, { useEffect, useState } from "react";
import StyledNewProjectModal from "./StyledNewProjectModal";
import { ReactComponent as Cross } from "../../../assests/icons/cross.svg";
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../../utils/hooks/useFetch";
import { PROCESS_DATA } from "../../../constants/routes/api-routes";
import LoadingButton from "../../Common/LoadingButton";
import clearProjectLocalStorage from "../../../utils/functions/clearProjectLocalStorage";
import { useFlowStore } from "../../../store/flowStore";
import { useVariableStore } from "../../../store/variableStore";
import { projectSettingsDefaults } from "../../../constants/projectSettingsDefaults";
const NewProjectModal = ({ setModal }) => {
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const [projectName, setProjectName] = useState("");
  const [apiData, setApiData] = useState();
  const { isSuccess, isLoading } = useFetch(
    "POST",
    [PROCESS_DATA, apiData],
    PROCESS_DATA,
    null,
    apiData,
    { enabled: !!apiData },
    null,
    { responseType: "blob" }
  );

  const {
    data: projectData,
    refetch: projectRefetch,
    isLoading: projectLoading,
  } = useFetch(
    "GET",
    [PROCESS_DATA, localStorage.getItem("projectName")],
    PROCESS_DATA + "/search",
    { selected_name: localStorage.getItem("projectName") },
    null,
    { enabled: false }
  );

  useEffect(() => {
    if (projectData) {
      const _selectedProject = projectData?.data?.find(
        (item) => item?.selected_name === localStorage.getItem("projectName")
      );
      variableStore.resetStore();
      localStorage.setItem("projectID", _selectedProject?.id);
      localStorage.setItem("projectData", JSON.stringify(_selectedProject));
      setModal(false);
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (isSuccess) {
      projectRefetch();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const nameChangeHandler = (e) => {
    setProjectName(e.target.value);
  };

  const createHandler = () => {
    variableStore.resetStore();
    clearProjectLocalStorage();

    const _events = flowStore.events;
    for (const key in _events) {
      if (Object.hasOwnProperty.call(_events, key)) {
        const element = _events[key];
        element.nodes = [];
        element.nodesData = [];
        element.edges = [];
      }
    }

    const _sendingData = {
      events: _events,
      variables: [],
      constants: [],
      oldValues: [],
      project_options: projectSettingsDefaults,
    };
    const _uuid = uuidv4();
    const _apiData = {
      data: _sendingData,
      selected_name: _uuid,
      name_by_user:
        projectName && projectName.length > 0 ? projectName : "unnamed",
      highestIndex: 1,
    };
    localStorage.setItem("projectName", _uuid);
    setApiData(_apiData);
  };

  return (
    <Draggable handle=".header">
      <StyledNewProjectModal>
        <div className="header">
          <span>New Project</span>
          <button onClick={() => setModal(false)}>
            <Cross />
          </button>
        </div>
        <div className="contentNewProject">
          <table>
            <tbody>
              <tr>
                <td>
                  <div>
                    <fieldset>
                      <legend>type</legend>
                      <input
                        id="expert"
                        type="radio"
                        name="project_type"
                        value="expert"
                      />
                      <label htmlFor="expert">Expert Advisor</label>
                      <br />
                      <input
                        id="script"
                        type="radio"
                        name="project_type"
                        value="script"
                      />
                      <label htmlFor="script">Script</label>
                    </fieldset>
                  </div>

                  <div>
                    <fieldset>
                      <legend>language</legend>
                      <input
                        id="MQL4"
                        type="radio"
                        name="code_language"
                        value="MQL4"
                      />
                      <label htmlFor="MQL4">MetaTrader 4 (MQL4)</label>
                      <br />
                      <input
                        id="MQL5"
                        type="radio"
                        name="code_language"
                        value="MQL5"
                      />
                      <label htmlFor="MQL5">MetaTrader 5 (MQL5)</label>
                    </fieldset>
                  </div>
                </td>
              </tr>

              <tr>
                <div className="flex">
                  <input
                    onChange={nameChangeHandler}
                    type="text"
                    value={projectName}
                    placeholder="Name..."
                  />
                  <LoadingButton
                    onClick={createHandler}
                    title={"Create"}
                    isLoading={isLoading || projectLoading}
                  />
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </StyledNewProjectModal>
    </Draggable>
  );
};

export default NewProjectModal;
