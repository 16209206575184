import React, { useEffect, useState } from "react";
import StyledConditionSection from "./StyledConditionSection";
import useFetch from "../../../utils/hooks/useFetch";
import {
  CONDITION_INPUTS,
  CONDITION_ROW2,
  CONDITION_SECTION,
} from "../../../constants/routes/api-routes";
import Input from "../../Input";
import { useFormContext } from "react-hook-form";
import getNestedValue from "../../../utils/functions/getNestedValue";
import exceptions from "../../../constants/exeptions";
import ConditionAccordion from "../ConditionAccordion";
import { useFlowStore } from "../../../store/flowStore";

const ConditionSection = ({
  id,
  nodeId,
  defaultRow1 = null,
  defaultRow2 = null,
  defaultParams,
}) => {
  const flowStore = useFlowStore();
  const { setValue, register } = useFormContext(); // retrieve all hook methods
  const [, setForceRefreshState] = useState(true);
  const [subInputs, setSubInputs] = useState([]);
  //states
  const [firstRowList, setFirstRowList] = useState([]);
  const [row1, setRow1] = useState(null);

  const [row2, setRow2] = useState(
    Object.keys(
      flowStore.nodes?.find((property) => property.id === nodeId)?.params
    ).length > 0 &&
      flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
      ? getNestedValue(
          flowStore.nodes?.find((property) => property.id === nodeId),
          `params.${id}.row2`
        ) ?? defaultRow2
      : defaultRow2
  );
  const [secondRowList, setSecondRowList] = useState([]);
  const [additionalProps, setAdditionalProps] = useState({
    inputs: [],
    additionalSelects: [],
  });

  //API calls

  useEffect(() => {
    if (defaultRow1) {
      const _row1Value =
        Object.keys(
          flowStore.nodes?.find((property) => property.id === nodeId)?.params
        ).length > 0 &&
        flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
          ? getNestedValue(
              flowStore.nodes?.find((property) => property.id === nodeId),
              `params.${id}.row1`
            ) ?? defaultRow1
          : defaultRow1;

      setValue(`params.${id}.row1`, _row1Value);

      setRow1(_row1Value);
    }
    // eslint-disable-next-line
  }, [defaultRow1]);

  useEffect(() => {
    const _row2Value =
      Object.keys(
        flowStore.nodes?.find((property) => property.id === nodeId)?.params
      ).length > 0 &&
      flowStore.nodes?.findIndex((property) => property.id === nodeId) > -1
        ? getNestedValue(
            flowStore.nodes?.find((property) => property.id === nodeId),
            `params.${id}.row2`
          ) ?? defaultRow2
        : defaultRow2;

    setRow2(_row2Value);
    setValue(`params.${id}.row2`, _row2Value);
    // eslint-disable-next-line
  }, [defaultRow2]);

  //getting the condition list
  const { data: firstRowData, isSuccess: firstRowIsSuccess } = useFetch(
    "GET",
    CONDITION_SECTION,
    CONDITION_SECTION
  );
  //getting the condition Details
  const { data, isSuccess, isLoading } = useFetch(
    "GET",
    [id, CONDITION_ROW2 + row1 + "/"],
    CONDITION_ROW2 + row1 + "/",
    null,
    null,
    { enabled: !!firstRowList.length > 0 && row1?.length > 0 }
  );

  const { data: fetchedInputs, isLoading: inputsLoading } = useFetch(
    "GET",
    [id, CONDITION_INPUTS(row1, row2), row2, row1],
    CONDITION_INPUTS(row1, row2),
    null,
    null,
    {
      enabled:
        !!row1 &&
        !!row2 &&
        row1?.length > 0 &&
        row2?.length > 0 &&
        !!secondRowList.find((item) => item?.id === row2),
    }
  );

  useEffect(() => {
    const _data = fetchedInputs?.data;
    const _inputs = [];
    const _additionalSelects = [];
    _data?.conditions_input_field?.forEach((_input) => {
      _inputs.push({
        id: _input?.name_key,
        value: defaultParams?.params?.[_input?.name_key]
          ? defaultParams?.params?.[_input?.name_key]
          : _input?.name_value,
        type: "text",
        id_sent: _input?.id_sent,
        key: _input?.name_represent,
      });
    });
    _data?.conditions_dropdown_menu?.forEach((input) => {
      if (input?.condition_child_extra_feature_name.length > 0) {
        if (
          input?.name_key?.includes(exceptions.adjust) ||
          input?.name_key?.includes(exceptions.moreSettings)
        ) {
          const _additionalSelectInputs = [];
          input?.condition_child_extra_feature_name?.forEach(
            (additionalInput) => {
              if (additionalInput?.type === "text") {
                _additionalSelectInputs.push({
                  id: additionalInput?.name_key,
                  key: additionalInput?.name_represent,
                  value: defaultParams?.params?.[additionalInput?.name_key]
                    ? defaultParams?.params?.[additionalInput?.name_key]
                    : additionalInput?.name_value,
                  id_sent: additionalInput?.id_sent,
                  type: "text",
                });
              } else {
                const _options = [];
                additionalInput?.condition_grand_child_feature_name?.forEach(
                  (additionalInputOption) => {
                    _options.push({
                      id: additionalInputOption?.name_value,
                      name: additionalInputOption?.name_represent,
                      id_sent: additionalInputOption?.id_sent,
                    });
                  }
                );
                _additionalSelectInputs.push({
                  id: additionalInput?.name_key,
                  key: additionalInput?.name_represent,
                  value: defaultParams?.params?.[additionalInput?.name_key]
                    ? defaultParams?.params?.[additionalInput?.name_key]
                    : additionalInput?.name_value,
                  id_sent: additionalInput?.id_sent,
                  type: "select",
                  options: _options,
                });
              }
            }
          );
          _additionalSelects.push({
            id: input?.name_key,
            key: input?.name_value,
            id_sent: input?.id_sent,
            inputs: _additionalSelectInputs?.sort((a, b) =>
              Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
            ),
          });
        } else {
          const _options = [];
          let inputId = input?.condition_child_extra_feature_name[0]?.name_key;
          input?.condition_child_extra_feature_name?.forEach((option) => {
            const _subInputs = [];
            option?.condition_grand_child_feature_name?.forEach((_subInput) => {
              _subInputs.push({
                id: _subInput?.name_key,
                key: _subInput?.name_represent,
                id_sent: _subInput?.id_sent,
                type: "text",
                value: defaultParams?.params?.[_subInput?.name_key]
                  ? defaultParams?.params?.[_subInput?.name_key]
                  : _subInput?.name_value,
              });
            });

            option?.condition_grand_child_extra_feature_name?.forEach(
              (_subInput) => {
                const _subInputOptions = [];
                if (
                  _subInput?.grand_child_ofchild_extra_feature_name?.length > 0
                ) {
                  let _subInputId =
                    _subInput?.grand_child_ofchild_extra_feature_name[0]
                      ?.name_key;
                  _subInput?.grand_child_ofchild_extra_feature_name?.forEach(
                    (subInputOption) => {
                      _subInputOptions.push({
                        id: subInputOption?.name_value,
                        name: subInputOption?.name_represent,
                        id_sent: subInputOption?.id_sent,
                      });
                    }
                  );
                  _subInputs.push({
                    id: _subInputId,
                    key: _subInput?.name_represent,
                    type: "select",
                    id_sent: _subInput?.id_sent,
                    value: defaultParams?.params?.[_subInputId]
                      ? defaultParams?.params?.[_subInputId]
                      : _subInput?.name_value === "NULL"
                      ? _subInputOptions[0]?.id
                      : _subInput?.name_value,
                    options: _subInputOptions?.sort((a, b) =>
                      Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                    ),
                  });
                }
              }
            );

            _options.push({
              id: option?.name_value,
              name: option?.name_represent,
              id_sent: option?.id_sent,
              subInputs: _subInputs?.sort((a, b) =>
                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
              ),
            });
          });

          _inputs.push({
            id: inputId,
            key: input?.name_key,
            type: "select",
            id_sent: input?.id_sent,
            value: defaultParams?.params?.[inputId]
              ? defaultParams?.params?.[inputId]
              : input?.name_value === "NULL" && !input?.name_value
              ? _options[0].id
              : input?.name_value,
            options: _options?.sort((a, b) =>
              Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
            ),
          });
        }
      }
    });
    setAdditionalProps((e) => ({
      additionalSelects: _additionalSelects?.sort((a, b) =>
        Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
      ),
      inputs: _inputs?.sort((a, b) =>
        Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
      ),
    }));
    // eslint-disable-next-line
  }, [fetchedInputs]);

  //set condition list state
  useEffect(() => {
    if (firstRowIsSuccess && firstRowData) {
      const _data = firstRowData.data[0];
      const _conditions = [];
      _data?.children_name?.forEach((condition) => {
        _conditions.push({
          id: condition?.slug,
          name: condition?.child_name,
          id_sent: condition?.id_sent,
        });
      });
      if ((row1 === undefined || row1 === null) && !defaultRow1) {
        setRow1(_conditions[0]?.id);
        setValue(`params.${id}.row1`, _conditions[0]?.id);
      } else if (row1) {
        setValue(`params.${id}.row1`, row1);
      } else {
        setRow1(defaultRow1);
        setValue(`params.${id}.row1`, defaultRow1);
      }
      setRow2("");
      setFirstRowList(
        _conditions?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      );
    }
    //eslint-disable-next-line
  }, [firstRowIsSuccess, firstRowData]);

  // useEffect(() => {
  //   if (row1) {
  //     setValue(`params.${id}.row1`, row1);
  //   }
  //   // eslint-disable-next-line
  // }, [row1]);

  useEffect(() => {
    register(`params.${id}.params`, { value: {} });
    const _properties = flowStore.nodes;
    const propertyIndex = _properties?.findIndex(
      (property) => property.id === nodeId
    );

    const _row2 =
      propertyIndex >= 0
        ? getNestedValue(_properties[propertyIndex], `params.${id}.row2`)
        : null;
    const _row1 =
      propertyIndex >= 0
        ? getNestedValue(_properties[propertyIndex], `params.${id}.row1`)
        : null;
    if (propertyIndex >= 0 && _row2) {
      setRow2(_row2);
      setValue(`params.${id}.row2`, _row2);
    }
    if (propertyIndex >= 0 && _row1) {
      setRow1(_row1);
      setValue(`params.${id}.row1`, _row1);
    }
    // eslint-disable-next-line
  }, []);

  //parse data to get the condition Details
  useEffect(() => {
    if (row1) {
      setValue(`params.${id}.row1`, row1);
    }
    if (isSuccess && data) {
      const _secondRowList = [];
      data?.data?.condition_menu_child_feature?.forEach((item) => {
        _secondRowList.push({
          id: item?.slug,
          name: item?.name_key,
          id_sent: item?.id_sent,
        });
      });
      setSecondRowList(
        _secondRowList?.sort((a, b) =>
          Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
        )
      );
    }
  }, [isSuccess, data, row1]);

  //after changing condition this happens to set the first item as default
  useEffect(() => {
    if (secondRowList.length > 0) {
      const _properties = flowStore.nodes;
      const propertyIndex = _properties?.findIndex(
        (property) => property.id === nodeId
      );
      if (
        typeof propertyIndex === "undefined" ||
        propertyIndex < 0 ||
        (propertyIndex >= 0 &&
          getNestedValue(_properties[propertyIndex], `params.${id}.row1`) !==
            row1)
      ) {
        const _row2 =
          defaultRow2?.length > 0 &&
          secondRowList.find((item) => item?.id === defaultRow2)
            ? defaultRow2
            : secondRowList[0]?.id;
        setRow2(_row2);
        setValue(`params.${id}.row2`, _row2);
      } else {
        if (propertyIndex >= 0) {
          const _row2 =
            propertyIndex >= 0
              ? getNestedValue(_properties[propertyIndex], `params.${id}.row2`)
              : null;
          if (_row2) {
            setRow2(_row2);
            setValue(`params.${id}.row2`, _row2);
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [secondRowList]);

  //handlers

  // handle changes in the inputs after that we will change the additional prop list if a option selected had any changes into the additionalProps
  const inputChangeHandler = (key, value) => {
    setForceRefreshState((e) => !e);
    //we find the selected Option
    let _selectedOption = additionalProps?.inputs
      .find((prop) => {
        return prop.id === key;
      })
      ?.options.find((option) => {
        return option.id === value;
      });
    //the changeFrom field return a list of id's to remove from the additional prop list
    //the changeTo field will return a list of inputs to insert in the additional prop list
    // in this way we will have a dynamic way to change the input list after changing a select box option

    //check if the selected Option does have any change from or change to
    const _props = [...additionalProps?.inputs];
    if (_selectedOption?.changeFrom) {
      _selectedOption?.changeFrom?.forEach((changes) => {
        const propIndex = _props.findIndex((prop) => prop.id === changes.id);
        if (propIndex > 0) {
          _props.splice(propIndex, 1);
        }
      });
    }

    //check for the ChangeTo field and push the new inputs into the additionalProps
    if (_selectedOption?.changeTo) {
      _selectedOption?.changeTo?.forEach((changes) => {
        _props.push(changes);
        setValue(`params.${id}.params.${changes.id}`, changes.value);
      });
    }
    setAdditionalProps((e) => ({
      inputs: _props,
      additionalSelects: e.additionalSelects,
    }));
  };

  //handle change of condition details
  const indicatorChangeHandler = (selectedItem) => {
    setRow2(selectedItem.target.value);
    setValue(`params.${id}.row2`, selectedItem.target.value);
  };

  //handle change of condition
  const conditionChangeHandler = (_selectedCondition) => {
    setRow2("");
    setRow1(_selectedCondition.target.value);
  };

  return (
    <StyledConditionSection>
      <div className="conditionTitle">
        {id === "left" ? "Left Operand" : id === "right" && "Right Operand"}
      </div>
      <div className="mainContainer">
        {firstRowList.length > 0 && (
          <div className="mainDropdownHolder">
            <Input
              id={id}
              registerId={`params.${id}.row1`}
              input={{
                key: "",
                type: "select",
                id: "condition",
                options: firstRowList,
              }}
              type={"select"}
              value={row1}
              nodeId={nodeId}
              onChange={conditionChangeHandler}
              disableVariables
            />
          </div>
        )}
        {isLoading ? (
          <span>loading...</span>
        ) : (
          <>
            {secondRowList && secondRowList.length > 0 && (
              <div className="mainDropdownHolder">
                <Input
                  id={id}
                  input={{
                    key: "",
                    id: "conditionDetail",
                    options: secondRowList,
                  }}
                  registerId={`params.${id}.row2`}
                  nodeId={nodeId}
                  type={"select"}
                  onChange={indicatorChangeHandler}
                  disableVariables
                />
              </div>
            )}
            <div className="additionalHolder">
              {inputsLoading ? (
                <span>loading...</span>
              ) : (
                <>
                  {additionalProps?.inputs?.length > 0 &&
                    additionalProps.inputs
                      ?.sort((a, b) =>
                        Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                      )
                      .map((input, index) => (
                        <div key={`${input?.id}-${input?.id}-${index}`}>
                          <Input
                            key={input?.id + input?.key}
                            id={id}
                            input={input}
                            nodeId={nodeId}
                            value={input?.value}
                            registerId={`params.${id}.params.${input?.id}`}
                            inputChangeHandler={
                              input?.type === "select" && inputChangeHandler
                            }
                            setSubInputs={
                              input?.type === "select" && setSubInputs
                            }
                            type={input?.type}
                          />
                          {input?.type === "select" &&
                            subInputs.find(
                              (e) => e.id === `params.${id}.params.${input?.id}`
                            ) &&
                            subInputs
                              .find(
                                (e) =>
                                  e.id === `params.${id}.params.${input?.id}`
                              )
                              ?.subInputs?.sort((a, b) =>
                                Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
                              )
                              ?.map((subInput, index) => (
                                <Input
                                  key={subInput.id + index}
                                  value={subInput?.value}
                                  id={id}
                                  input={subInput}
                                  registerId={`params.${id}.params.${subInput?.id}`}
                                  type={subInput?.type}
                                  nodeId={nodeId}
                                />
                              ))}
                        </div>
                      ))}
                </>
              )}
            </div>
            <div className="additionalSelectBoxContainer">
              {inputsLoading ? (
                <span>loading...</span>
              ) : (
                <>
                  {additionalProps?.additionalSelects?.length > 0 &&
                    additionalProps?.additionalSelects?.map(
                      (additionalSelect) => (
                        <ConditionAccordion
                          additionalSelect={additionalSelect}
                          id={id}
                          inputChangeHandler={inputChangeHandler}
                          nodeId={nodeId}
                          key={additionalSelect?.id}
                        />
                      )
                    )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </StyledConditionSection>
  );
};

export default ConditionSection;
