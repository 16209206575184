import React, { useState, useEffect } from "react";
import StyledLoadProjectModal from "./StyledLoadProjectModal";
import Draggable from "react-draggable";
import { ReactComponent as Cross } from "../../../assests/icons/cross.svg";
import useFetch from "../../../utils/hooks/useFetch";
import { PROCESS_DATA } from "../../../constants/routes/api-routes";
import LoadingButton from "../../Common/LoadingButton";
import { useQueryClient } from "@tanstack/react-query";
import clearProjectLocalStorage from "../../../utils/functions/clearProjectLocalStorage";
import { useFlowStore } from "../../../store/flowStore";
import { useVariableStore } from "../../../store/variableStore";
import ProjectRow from "./ProjectRow";

const LoadProjectModal = ({ setModal }) => {
  const [projects, setProjects] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletingProject, setDeletingProject] = useState(null);
  const flowStore = useFlowStore();
  const { resetStore } = useVariableStore();

  const { data, isLoading, isRefetching, refetch } = useFetch(
    "GET",
    [PROCESS_DATA, currentPage],
    `${PROCESS_DATA}?start=${currentPage * 10 - 10}&end=${currentPage * 10}`,
    null,
    null,
    { cacheTime: 0, caches: "no-cache" }
  );

  useEffect(() => {
    if (data) {
      refetch();
    }
    return () => {
      setTotalPages(0);
      setCurrentPage(1);
      setProjects([]);
    };
    // eslint-disable-next-line
  }, []);

  const queryClient = useQueryClient();
  useEffect(() => {
    if (totalPages > 0) {
      for (let index = 1; index <= totalPages; index++) {
        if (index !== currentPage) {
          queryClient.cancelQueries({ queryKey: [PROCESS_DATA, index] });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentPage, totalPages]);

  const { isLoading: deleteLoading, isSuccess } = useFetch(
    "DELETE",
    [PROCESS_DATA, deletingProject],
    `${PROCESS_DATA}/${deletingProject}/`,
    null,
    null,
    { enabled: !!deletingProject }
  );
  useEffect(() => {
    if (data) {
      setProjects(data?.data?.results);
      setTotalPages(Math.ceil(data?.data?.count / 10));
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      // eslint-disable-next-line
      if (deletingProject == localStorage.getItem("projectID")) {
        clearProjectLocalStorage();
        flowStore.setSelectedEvent("on_tick");
        window.location.reload();
      }
      setProjects((prev) =>
        prev.filter((project) => project.id !== deletingProject)
      );
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const nextPageHandler = () => {
    currentPage < totalPages && setCurrentPage((e) => e + 1);
  };

  const prevPageHandler = () => {
    currentPage > 1 && setCurrentPage((e) => e - 1);
  };

  const loadProjectHandler = (projectId) => {
    // console.log(e.target);
    resetStore();
    flowStore.resetStore();
    localStorage.setItem("projectID", projectId);
    setModal(false);
    window.location.reload();
  };

  const deleteProjectHandler = (projectId) => {
    // console.log(e.target);
    setDeletingProject(projectId);
  };

  const formatDate = (date) => {
    const _date = new Date(date);
    return _date.toLocaleString();
  };

  return (
    <Draggable handle=".header">
      <StyledLoadProjectModal>
        <div className="header">
          <span>Load Project</span>
          <button onClick={() => setModal(false)}>
            <Cross />
          </button>
        </div>
        <div className="content">
          <table>
            <thead>
              <tr>
                <td></td>
                <td>Project Name</td>
                <td>Type</td>
                <td>Language</td>
                <td>created</td>
                <td>modified</td>
              </tr>
            </thead>
            <tbody>
              {isLoading || isRefetching ? (
                <h3>Loading...</h3>
              ) : (
                <>
                  {projects.map((project) => (
                    <ProjectRow
                      project={project}
                      deleteLoading={deleteLoading}
                      deleteProjectHandler={deleteProjectHandler}
                      loadProjectHandler={loadProjectHandler}
                      deletingProject={deletingProject}
                      key={project?.id}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button onClick={prevPageHandler}>Prev</button>
          <span>
            {currentPage} of {totalPages}
          </span>
          <button onClick={nextPageHandler}>Next</button>
        </div>
      </StyledLoadProjectModal>
    </Draggable>
  );
};

export default LoadProjectModal;
