import React, { useEffect, useState } from "react";
import StyledInputAccordion from "./StyledInputAccordion";
import Input from "../../Input";
import { useFormContext, useWatch } from "react-hook-form";
import { useFlowStore } from "../../../store/flowStore";

const InputAccordion = ({
  title,
  selectOnChange,
  nodeId,
  defaultOpenState,
  accordionItem,
}) => {
  const flowStore = useFlowStore();
  const { getValues, control } = useFormContext();
  const [open, setOpen] = useState(defaultOpenState);
  const [, setForceRerender] = useState(false);
  const [inputs, setInputs] = useState([]);
  const watchOutput = useWatch(control);

  useEffect(() => {
    setOpen(defaultOpenState);
  }, [defaultOpenState]);

  useEffect(() => {
    setForceRerender((e) => !e);
  }, [watchOutput]);

  useEffect(() => {
    const _json = JSON?.parse(accordionItem?.json_added);
    const _inputs = [];
    accordionItem?.input_fields?.forEach((input) => {
      if (input?.type === "valueFetch") {
        const _values = input?.name_value.split(",");
        _inputs.push({
          id: input?.name_key,
          type: "valueFetch",
          key: input?.name_represent,
          defaultRow1: _values[0],
          defaultRow2: _values[1],
          defaultParams: _json?.[input?.name_key] ?? null,
          id_sent: input?.id_sent,
          registerId:
            input?.register_id !== "NULL" ||
            !input?.register_id ||
            input?.register_id?.length < 1
              ? input?.register_id
              : input?.name_key,
        });
      } else {
        _inputs.push({
          id: input?.name_key,
          type: "text",
          id_sent: input?.id_sent,
          key: input?.name_represent,
          value: _json?.[input?.name_key]
            ? _json?.[input?.name_key]
            : input?.name_value ?? "",
          registerId:
            input?.register_id !== "NULL" ||
            !input?.register_id ||
            input?.register_id?.length < 1
              ? input?.register_id
              : `params.${input?.name_key}`,
        });
      }
    });
    accordionItem?.dropdown_menu?.forEach((input) => {
      if (input?.child_extra_feature_name.length > 0) {
        const _options = [];
        let inputId = input?.child_extra_feature_name[0]?.name_key;
        input?.child_extra_feature_name?.forEach((option) => {
          const _subInputs = [];
          option?.grand_child_input_field?.forEach((_subInput) => {
            if (_subInput?.type === "valueFetch") {
              const _values = _subInput?.name_value.split(",");
              _subInputs.push({
                id: _subInput?.name_key,
                type: "valueFetch",
                key: _subInput?.name_represent,
                defaultRow1: _values[0],
                defaultRow2: _values[1],
                defaultParams: _json?.[_subInput?.name_key] ?? null,
                id_sent: _subInput?.id_sent,
                registerId:
                  _subInput?.register_id !== "NULL" ||
                  !_subInput?.register_id ||
                  _subInput?.register_id?.length < 1
                    ? _subInput?.register_id
                    : _subInput?.name_key,
              });
            } else {
              _subInputs.push({
                id: _subInput?.name_key,
                key: _subInput?.name_represent,
                type: "text",
                id_sent: _subInput?.id_sent,
                value: _json?.[_subInput?.name_key]
                  ? _json?.[_subInput?.name_key]
                  : _subInput?.name_value,
                registerId:
                  _subInput?.register_id !== "NULL" ||
                  !_subInput?.register_id ||
                  _subInput?.register_id?.length < 1
                    ? _subInput?.register_id
                    : `params.${_subInput?.name_key}`,
              });
            }
          });

          option?.grand_child_dropdown_menu?.forEach((_subInput) => {
            const _subInputOptions = [];
            if (_subInput?.the_child_details.length > 0) {
              let _subInputId = _subInput?.the_child_details[0]?.name_key;
              _subInput?.the_child_details?.forEach((subInputOption) => {
                _subInputOptions.push({
                  id: subInputOption?.name_value,
                  name: subInputOption?.name_represent,
                  id_sent: subInputOption?.id_sent,
                });
              });
              _subInputs.push({
                id: _subInputId,
                key: _subInput?.name_represent,
                id_sent: _subInput?.id_sent,
                type: "select",
                value: _json?.[_subInputId]
                  ? _json?.[_subInputId]
                  : _subInput?.name_value === "NULL"
                  ? _subInputOptions[0]?.id
                  : _subInput?.name_value,
                options: _subInputOptions,
                registerId:
                  _subInput?.register_id !== "NULL" ||
                  !_subInput?.register_id ||
                  _subInput?.register_id?.length < 1
                    ? _subInput?.register_id
                    : `params.${_subInputId}`,
              });
            }
          });
          _options.push({
            id: option?.name_value,
            name: option?.name_represent,
            id_sent: option?.id_sent,
            subInputs: _subInputs?.sort((a, b) =>
              Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
            ),
          });
        });
        _inputs.push({
          id: inputId,
          key: input?.name_key,
          type: "select",
          value: _json?.[inputId]
            ? _json?.[inputId]
            : input?.name_value === "NULL"
            ? _options[0].id
            : input?.name_value,
          options: _options?.sort((a, b) =>
            Number(a.id_sent) > Number(b.id_sent) ? 1 : -1
          ),
          id_sent: input?.id_sent,
          registerId:
            input?.register_id !== "NULL" ||
            !input?.register_id ||
            input?.register_id?.length < 1
              ? input?.register_id
              : `params.${inputId}`,
        });
      }
    });
    setInputs(
      _inputs?.sort((a, b) => (Number(a.id_sent) > Number(b.id_sent) ? 1 : -1))
    );
  }, [accordionItem]);

  const openCloseHandler = () => {
    const _nodesData = flowStore.getNodesData(nodeId);
    setOpen((e) => {
      if (e) {
        const _newHeader = _nodesData?.data?.closeHeaders ?? [];

        const _newNodesData = {
          ..._nodesData,
          data: {
            ..._nodesData?.data,
            openHeaders:
              _nodesData?.data?.openHeaders?.filter((o) => o !== title) ?? [],
            closeHeaders: [..._newHeader, title],
          },
        };
        flowStore.updateNodesData(nodeId, _newNodesData);
        return false;
      } else {
        const _newHeader = _nodesData?.data?.openHeaders ?? [];
        const _newNodesData = {
          ..._nodesData,
          data: {
            ..._nodesData?.data,
            closeHeaders:
              _nodesData?.data?.closeHeaders?.filter((o) => o !== title) ?? [],
            openHeaders: [..._newHeader, title],
          },
        };
        flowStore.updateNodesData(nodeId, _newNodesData);
        return true;
      }
    });
  };

  useEffect(() => {
    // settingValues();
    setForceRerender((e) => !e);
    // eslint-disable-next-line
  }, [inputs]);

  useEffect(() => {
    const _nodesData = flowStore.getNodesData(nodeId);

    if (_nodesData?.data?.openHeaders?.find((e) => e === title)) {
      setOpen(true);
    } else if (_nodesData?.data?.closeHeaders?.find((e) => e === title)) {
      setOpen(false);
    }
    setForceRerender((e) => !e);
    // settingValues();
    // eslint-disable-next-line
  }, []);

  const selectChangeHandler = (input, event) => {
    selectOnChange(input, event);
    setForceRerender((e) => !e);
  };

  useEffect(() => {
    // settingValues();
    setForceRerender((e) => !e);
    // eslint-disable-next-line
  }, [open]);

  return (
    <StyledInputAccordion>
      {title && (
        <div
          onClick={openCloseHandler}
          className={`separator ${open ? "expanded" : ""}`}
        >
          {title}
        </div>
      )}
      <div
        className="inputsContainer"
        style={{ display: open || !title ? "block" : "none" }}
      >
        {inputs &&
          inputs.length > 0 &&
          inputs.map((input) => (
            <div key={`${input?.registerId}-${input?.id}-${input?.key}`}>
              <Input
                key={input?.id + input?.key}
                input={input}
                registerId={input?.registerId ?? `params.${input?.id}`}
                type={input?.type}
                defaultParams={input?.defaultParams}
                nodeId={nodeId}
                onChange={
                  input?.type === "select" &&
                  selectChangeHandler.bind(this, input)
                }
                className="inputAccordionInputs"
              />
              {input.type === "select" &&
                input?.options.find(
                  (option) =>
                    option.id ===
                    getValues(input?.registerId ?? `params.${input?.id}`)
                )?.subInputs &&
                input?.options
                  .find(
                    (option) =>
                      option.id ===
                      getValues(input?.registerId ?? `params.${input?.id}`)
                  )
                  ?.subInputs?.map((subInput) => (
                    <Input
                      key={subInput?.id}
                      input={subInput}
                      nodeId={nodeId}
                      defaultParams={subInput?.defaultParams}
                      registerId={
                        subInput?.registerId ?? `params.${subInput?.id}`
                      }
                      type={subInput?.type}
                      className="inputAccordionInputs"
                    />
                  ))}
            </div>
          ))}
      </div>
    </StyledInputAccordion>
  );
};

export default InputAccordion;
