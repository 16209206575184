import styled from "styled-components";

const StyledHome = styled.section`
  .topSection {
    background: linear-gradient(180deg, #666, #3a3a3a);
    height: 95px;
    position: relative;
  }

  .menuContainer {
    display: flex;
    align-items: center;
  }
  .menuParent {
    a {
      text-decoration: none;
      color: inherit;
    }
    display: block;
    font-size: 12px;
    color: #111;
    text-shadow: 0 1px 0 #555;
    text-decoration: none;
    padding: 1px 5px;
    text-align: center;
    width: 90px;
    border: 1px solid black;
    background: linear-gradient(to bottom, #aaa, #444);
    cursor: pointer;
    position: relative;
    .menuModal {
      position: absolute;
      top: 100%;
      display: flex;
      z-index: 10;
      /* hide element for now */
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      /* make submenu not hoverable when opacity transition finished,
   * do this instantaneously */
      transition: max-height 0s 0.5s,
        /* hide the submenu after 1 second, in 400ms */ opacity 0.1s 0.1s;
      align-items: center;
      flex-direction: column;
      &:hover.menuModal {
        max-height: 500px;
        opacity: 1;
        transition-delay: 0s;
      }
      button {
        width: 100%;
        border-top: 0.1rem solid #6b727c;
        border-bottom: 0.1rem solid #575f6a;
        border-left: none;
        border-right: none;
        position: relative;
        white-space: nowrap;
        color: white;
        padding: 0;
        cursor: pointer;
        background-color: #5f6975;
        padding: 10px;
        &:hover .menuModal {
          max-height: 500px;
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }

    &:hover .menuModal {
      max-height: 500px;
      opacity: 1;
      transition-delay: 0s;
    }
  }
  .mainWrapper {
    display: flex;
    justify-content: space-between;

    .leftSection {
      width: 240px;
      height: calc(100vh - 95px);
      min-width: 239.5px;
      overflow-y: auto;
      background-color: #333333;
    }

    .rightSection {
      width: 100%;
    }
  }
`;

export default StyledHome;
