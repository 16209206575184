import styled from "styled-components";

const StyledPropertiesModal = styled.div`
  position: absolute;
  /* top: 20%;
  left: calc(100% + 5px); */
  pointer-events: all;
  background-color: #444;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 0 8px black;
  .header {
    cursor: move;
    background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 1) 0%,
      rgba(102, 102, 102, 1) 49%,
      rgba(34, 34, 34, 1) 100%
    );
    width: 100%;
    padding: 5px 3px;
    display: flex;
    align-items: center;
    /* height: 25px; */
    color: #cacaca;
    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 30px;
      white-space: nowrap;
    }
    svg {
      margin-right: 20px;
      fill: white;
      stroke: #ddd;
      width: 20px;
      height: 20px;
    }
  }
  .content {
    color: #cacaca;
    padding: 2px;

    .changeStatus {
      background-color: #445555;
      color: #99aaaa;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 1px 5px;
      span {
        white-space: nowrap;
      }
      .statusText {
        background: #666;
        width: 50px;
        height: 18px;
      }
      .statusCheckbox {
        margin: 3px;
      }
    }
    .btnHolder {
      button {
        background: linear-gradient(180deg, #555, #333) #555;
        padding: 5px 20px;
        border-radius: 8px;
        margin: auto;
        width: 40%;
        margin: 5px 0;
        border: 1px solid black;
      }
      :first-child {
        color: #3a3;
        text-shadow: 0 0 1px #3a3;
      }
      :last-child {
        color: red;
        text-shadow: 0 0 1px red;
      }
    }
  }
  svg {
    fill: red;
    width: 10px;
    height: 10px;
  }
`;

export default StyledPropertiesModal;
