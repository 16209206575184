import React, { useCallback, useEffect, useState } from "react";
import StyledVariablesAndConstantsModal from "./StyledVariablesAndConstantsModal";
import { ReactComponent as Cross } from "../../assests/icons/cross.svg";
import Draggable from "react-draggable";
import setNestedValue from "../../utils/functions/setNestedValue";
import useUpdateProject from "../../utils/hooks/useUpdateProject";
import LoadingButton from "../Common/LoadingButton";
import { useFlowStore } from "../../store/flowStore";
import { useVariableStore } from "../../store/variableStore";
import { v4 as uuidv4 } from "uuid";
import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import VariableItem from "./VariableItem";
import { HTML5Backend } from "react-dnd-html5-backend";

const VariablesAndConstantsModal = ({ closeHandler, modal }) => {
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const [modalMode, setModalMode] = useState(modal);
  const [addedVariables, setAddedVariables] = useState([]);
  const [changedVariables, setChangedVariables] = useState([]);
  const [deletedVariables, setDeletedVariables] = useState([]);
  const [variablesList, setVariablesList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const { updateProject, isSuccess, isError } = useUpdateProject();

  useEffect(() => {
    if (isSuccess || isError) {
      setIsLoading(false);
      setVariablesList([...variableStore[modalMode]]);
    }
    if (isSuccess) {
      setAddedVariables([]);
      setChangedVariables([]);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setModalMode(modal);
    setVariablesList([...variableStore[modal]]);
  }, [modal]);

  //Add new Button Handler
  const addVariableHandler = (e) => {
    const _id = uuidv4();
    setAddedVariables((e) => [
      ...e,
      { id: _id, type: "double", name: "", value: "", description: "" },
    ]);
    setVariablesList((e) => [
      ...e,
      { id: _id, type: "double", name: "", value: "", description: "" },
    ]);
  };

  //handle changing Tab
  const changeTabHandler = (tab) => {
    setVariablesList([...variableStore[tab]]);
    setModalMode(tab);
  };

  const sanitizeString = (str) => {
    // Add an underscore before the first character if it's a number
    str = str.replace(/^\d/, "_$&");

    // Replace any character that is not a letter, number, or underscore with an underscore
    return str.replace(/[^a-zA-Z0-9_]/g, "_");
  };
  //handle variable inputs change
  const inputChangeHandler = (variableId, index, key, event) => {
    const addedIndex = addedVariables.findIndex((e) => e.id === variableId);
    if (addedIndex > -1) {
      setAddedVariables((e) => {
        const _variables = [...e];
        _variables[addedIndex][key] =
          key === "name"
            ? sanitizeString(event.target.value)
            : event.target.value;
        return _variables;
      });
    } else {
      const newListVariable = [...variablesList];
      const _variable = newListVariable.find((e) => e.id === variableId);
      const newChangedList = [...changedVariables];
      const editIndex = newChangedList.findIndex((e) => e.id === variableId);
      if (editIndex > -1) {
        // edit the changed variables

        setChangedVariables((e) => {
          const newList = [...e]; // shallow copy
          const newItem = { ...newList[editIndex] }; // deep copy of item
          newItem[key] =
            key === "name"
              ? sanitizeString(event.target.value)
              : event.target.value;
          newList[editIndex] = newItem;
          return newList;
        });
      } else {
        //add this to changed variables
        if (_variable) {
          _variable[key] =
            key === "name"
              ? sanitizeString(event.target.value)
              : event.target.value;
          setChangedVariables((e) => [...e, _variable]);
        }
      }
    }

    setVariablesList((e) => {
      const newList = [...e]; // shallow copy
      const newItem = { ...newList[index] }; // deep copy of item
      newItem[key] =
        key === "name"
          ? sanitizeString(event.target.value)
          : event.target.value;
      newList[index] = newItem;
      return newList;
    });
  };

  //handle the submit button (it Will save the variableList in the localStorage or in the future in the database)
  const submitHandler = () => {
    setIsLoading(true);

    //new structure using the zustand store for the variables handling \
    variableStore.addVariables(
      addedVariables.filter((e) => {
        if (!e.name || e.name.length < 1) {
          return false;
        }
        if (
          variableStore[modalMode].find((v) => v?.name === e.name) ||
          variableStore[
            modalMode === "Variables" ? "Constants" : "Variables"
          ].find((v) => v?.name === e.name)
        ) {
          return false;
        } else {
          return true;
        }
      }),
      modalMode
    );
    variableStore.changeVariables(changedVariables, modalMode);
    variableStore.deleteVariables(deletedVariables, modalMode);

    //first lets delete variables
    // const _oldValues = localStorage.getItem("oldValues")
    //   ? JSON.parse(localStorage.getItem("oldValues"))
    //   : [];
    // deletedVariables.forEach((deletedVariable) => {
    //   const variableIndex = _oldValues.findIndex(
    //     (item) => item.variable === deletedVariable.name
    //   );
    //   if (variableIndex >= 0) {
    //     const _properties = flowStore.nodes;
    //     _oldValues[variableIndex].nodes.forEach((node) => {
    //       const _originalNodeIndex = _properties.findIndex(
    //         (e) => e.id === node.id
    //       );
    //       if (_originalNodeIndex >= 0) {
    //         for (const key in node) {
    //           if (Object.hasOwnProperty.call(node, key)) {
    //             const element = node[key];
    //             setNestedValue(_properties[_originalNodeIndex], key, element);
    //           }
    //         }
    //       }
    //     });
    //     flowStore.setNodes(_properties);
    //     updateProject(flowStore.events);
    //   }
    // });

    // localStorage.setItem(modalMode, JSON.stringify(variablesList));

    const _sendingData = {
      events: flowStore.events,
      variables:
        modalMode === "Variables" ? variablesList : variableStore.Variables,
      constants:
        modalMode === "Constants" ? variablesList : variableStore.Constants,
      oldValues: variableStore.oldValues,
    };
    updateProject(_sendingData, true);

    // variablesList.forEach((_variable) => {
    //   if (
    //     _oldValues.find((item) => item.variable === _variable.name) ===
    //     undefined
    //   ) {
    //     _oldValues.push({ variable: _variable.name, nodes: [] });
    //   }
    //   // localStorage.setItem("oldValues", JSON.stringify(_oldValues));
    // });
  };

  //handle deleting an item
  const variableDeleteHandler = (index) => {
    const _oldValues = [...variableStore.oldValues];
    const _deletedVariable = { ...variablesList[index] };
    const usedNodes = _oldValues.find(
      (e) => e?.variable?.id === _deletedVariable.id
    )?.nodes;
    if (usedNodes?.length > 0) {
      alert(
        `the ${_deletedVariable?.name} already used in ${
          usedNodes?.length <= 1 ? "Block" : ""
        } ${usedNodes.map((e) => e.index)} ${
          usedNodes?.length > 1 ? "Blocks" : ""
        }`
      );
    } else {
      setDeletedVariables((e) => [...e, variablesList[index]]);
      setVariablesList((e) => {
        const _e = [...e];
        _e.splice(index, 1);
        return _e;
      });
    }
  };

  const moveVariable = useCallback(
    (dragIndex, hoverIndex, id) => {
      setVariablesList((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
      if (variableStore.getVariable(id, modalMode)) {
        variableStore.moveVariable(dragIndex, hoverIndex, modalMode);
      }
      const addedIndex = addedVariables.findIndex((e) => e.id === id);

      if (addedIndex > -1) {
        setAddedVariables((e) => {
          const _variables = [...e];
          _variables[addedIndex].moved = {
            dragIndex,
            hoverIndex,
          };
          return _variables;
        });
      }
    },
    [modalMode, variableStore, addedVariables]
  );
  const renderVariableItem = (variable, index) => {
    return (
      <VariableItem
        index={index}
        key={variable?.id}
        inputChangeHandler={inputChangeHandler}
        variable={variable}
        variableDeleteHandler={variableDeleteHandler}
        moveVariable={moveVariable}
      />
    );
  };

  return (
    <Draggable handle=".header">
      <StyledVariablesAndConstantsModal>
        <div className="header">
          <span>Constants (Inputs) / Variables</span>
          <button onClick={closeHandler}>
            <Cross />
          </button>
        </div>
        <div className="contentSection">
          <div className="VariablesBtnHolder">
            <button
              onClick={changeTabHandler.bind(this, "Constants")}
              style={{ opacity: modalMode === "Constants" ? 1 : 0.6 }}
            >
              Constants
            </button>
            <button
              onClick={changeTabHandler.bind(this, "Variables")}
              style={{ opacity: modalMode === "Variables" ? 1 : 0.6 }}
            >
              Variables
            </button>
          </div>
          <div
            className="variableArea"
            style={{
              background: modalMode === "Constants" ? "#997755" : "#555566",
            }}
          >
            <div>
              <div className="variablesContainer">
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Type</td>
                      <td>Name</td>
                      <td></td>
                      <td>Value</td>
                      <td>Description</td>
                      <td>Delete</td>
                    </tr>
                  </thead>
                  <tbody>
                    <DndProvider backend={HTML5Backend}>
                      {variablesList.length > 0 &&
                        variablesList.map((variable, index) =>
                          renderVariableItem(variable, index)
                        )}
                    </DndProvider>
                  </tbody>
                </table>
              </div>
              <button className="addBtn" onClick={addVariableHandler}>
                + Add new
              </button>
            </div>

            <div className="staticDataContainer">
              <LoadingButton
                onClick={submitHandler}
                title={"Update"}
                isLoading={isLoading}
              ></LoadingButton>
              {modalMode === "Constants" ? (
                <p>
                  Use Project Constants to define boolean, numeric or text
                  values for using in one or more blocks in the same time.
                  Changing constants here affects all the blocks that they are
                  used in, at the same time.
                </p>
              ) : (
                <p>
                  Use Project Variables to define boolean, numeric or text
                  values for using in project. These values can be changed at
                  some parts of the strategy.
                </p>
              )}
              {modalMode === "Constants" ? (
                <p>
                  Set a Constant here. Open parameters window of some block and
                  right click over one of it's input fields. Choose the
                  Constant. Project Constants are also automatically defined as
                  input parameters (externs) in MetaTrader 4 and 5.
                </p>
              ) : (
                <p>
                  To use: Set a Variable here. Open parameters window of some
                  block and right click over one of it's input fields. Choose
                  the Variable. To change: Set a Variable here. Open parameters
                  window of some block and click on Variables on the right top.
                  Locate the Variable's input field and enter a formula using
                  available abbreviations. Variables are changed at the
                  beginning and/or at the end of the block execution.
                </p>
              )}
            </div>
          </div>
        </div>
      </StyledVariablesAndConstantsModal>
    </Draggable>
  );
};

export default VariablesAndConstantsModal;
