import "./App.css";
import defineUserAbility, { AbilityContext } from "./config/acl";
import { useEffect, useState } from "react";
import {
  redirect,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import AuthenticatedPages from "./pages/authenticated/AuthenticatedPages/AuthenticatedPages";
import Cookies from "js-cookie";
import useFetch from "./utils/hooks/useFetch";
import { CSRF_TOKEN } from "./constants/routes/api-routes";
import axios from "axios";
import UnAuthenticatedPages from "./pages/unAuthenticated/UnAuthenticatedPages/UnAuthenticatedPages";
import { LOGIN_ROUTE } from "./constants/routes/app-routes";
function App() {
  const [ability, setAbility] = useState(defineUserAbility());
  const { data } = useFetch("GET", [CSRF_TOKEN], CSRF_TOKEN);

  //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   console.log(res.data);
  //   setIP(res.data.IPv4)
  // }

  // Updated Code

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    Cookies.set("Userip", res?.data?.ip);
    sessionStorage.setItem("Userip", res?.data?.ip);
  };

  // Listen f or changes in localStorage
  useEffect(() => {
    getData();

    function handleStorageChange() {
      // When the role changes, update the ability
      setAbility(defineUserAbility());
    }

    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (data) {
      Cookies.set("csrfToken", data?.data?.csrfToken);
    }
  }, [data]);

  return (
    <AbilityContext.Provider value={ability}>
      <Router>
        <AuthenticatedPages />
        <UnAuthenticatedPages />
      </Router>
    </AbilityContext.Provider>
  );
}

export default App;
