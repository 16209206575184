import styled from "styled-components";

const StyledCustomNode = styled.div`
  position: relative;
  justify-content: space-between;

  &.loading {
    opacity: 0.4;
    pointer-events: none;
    cursor: none;
  }
  .nodeContent {
    box-shadow: 0 0 8px black;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #555555;
    border-radius: 500px;

    .index,
    .title {
      font-size: 10px;
      padding: 0 3px 0 5px;
      color: #aaa;
      svg {
        fill: green;
      }
    }
    .title {
      background-color: ${(props) => props.bgcolor || "white"};
      color: ${(props) => props.color || "black"};
      font-size: 13px;
      padding: 3px 6px;
      text-align: center;
      border-radius: 500px;
      vertical-align: middle;
      span {
        display: flex;
        align-items: center;
        margin-right: 2px;
        padding-top: 0px;
      }
    }
  }

  .editInputHolder {
    input {
      background: transparent;
    }
  }

  .checkHolder {
    fill: green;
      
  }
  .menu {
    position: absolute;
    top: 20%;
    left: calc(100% + 5px);
    /* padding: 5px; */
    background-color: #666;
    z-index: 9999;
    border-radius: 5px;
    box-shadow: 0 0 3px black;
    display: flex;
    flex-direction: column;
    align-items: start;
    button {
      color: #ddd;
      margin-bottom: 5px;
      box-flex-group: #ccc;
      background: transparent;
      box-shadow: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 3px;
      width: 100%;
      &:hover {
        color: #fff !important;
        background-color: #ab9356 !important;
      }
      img {
        margin-right: 4px;
      }
    }
  }

  .closeButton {
    color: #ddd;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: right;
    direction: rtl;
    align-self: flex-end;
    margin-bottom: 8px !important;
    padding: 5px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledCustomNode;
