import { useFlowStore } from "../../store/flowStore";
import { useVariableStore } from "../../store/variableStore";

const deleteNodeHandler = (setNodes, setEdges, setMenu, id) => {
  const _properties = useFlowStore.getState().nodes;
  const _deletedNodeIndex = _properties?.find(
    (item) => item?.id === id
  )?.id_by_user;

  const _highestIndex = Number(localStorage.getItem("highestIndex")) - 1;

  if (_deletedNodeIndex >= _highestIndex && _highestIndex > 1) {
    localStorage.setItem("highestIndex", Number(_highestIndex));
  }

  setNodes((nodes) => nodes.filter((node) => node.id !== id));
  setEdges((edges) => {
    const _newEdges = [...edges];
    const _filteredEdges = _newEdges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );
    useFlowStore.getState().setEdges(_filteredEdges);
    return _filteredEdges;
  });

  const _oldValues = localStorage.getItem("oldValues")
    ? JSON.parse(localStorage.getItem("oldValues"))
    : [];
  _oldValues.forEach((oldValue) => {
    const _selectedNodeIndex = oldValue.nodes.findIndex(
      (item) => item.id === id
    );
    if (_selectedNodeIndex >= 0) {
      oldValue.nodes.splice(_selectedNodeIndex, 1);
    }
  });
  localStorage.setItem("oldValues", JSON.stringify(_oldValues));
  if (_properties) {
    useFlowStore
      .getState()
      .setNodes(_properties.filter((property) => property.id !== id));
  }
  const _nodesData = useFlowStore.getState().nodesData;
  useFlowStore
    .getState()
    .setNodesData(_nodesData.filter((node) => node.id !== id));

  useVariableStore.getState().removeNode(id);
  setMenu(false);
  return useFlowStore.getState().events;
};

export default deleteNodeHandler;
