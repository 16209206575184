export const projectSettingsInputs = [
  {
    title: "Magic Number and Other Options",
    inputs: [
      {
        id: "magic_number",
        type: "text",
        registerId: "magic_and_other.magic_number",
        key: "Magic number",
      },
      {
        id: "expiration_date",
        type: "text",
        registerId: "magic_and_other.expiration_date",
        key: "Expiration date",
      },
      {
        id: "on_timer_period",
        type: "text",
        registerId: "magic_and_other.on_timer_period",
        key: 'Period for the "on Timer" event',
      },
    ],
  },
  {
    title: "Pip Size",
    inputs: [
      {
        id: "pip_size_rules",
        type: "textarea",
        registerId: "pip_size.rules",
        key: "Rules",
      },
    ],
  },
  {
    title: "Description and Version Number",
    inputs: [
      {
        id: "copy_right",
        type: "text",
        registerId: "description_and_version_number.copy_right",
        key: "Copyright",
      },
      {
        id: "description",
        type: "textarea",
        registerId: "description_and_version_number.description",
        key: "Description",
      },
      {
        id: "website_address",
        type: "text",
        registerId: "description_and_version_number.website_address",
        key: "Website address",
      },
      {
        id: "version_number",
        type: "text",
        registerId: "description_and_version_number.version_number",
        key: "Version number",
      },
    ],
  },
  {
    title: "Virtual Stops",
    inputs: [
      {
        id: "virtual_stops",
        type: "select",
        registerId: "virtual_stops.virtual_stops",
        key: "Virtual stops",
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
      },
      {
        id: "virtual_stops_time_out",
        type: "text",
        registerId: "virtual_stops.virtual_stops_time_out",
        key: "Virtual stops time out",
      },
      {
        id: "emergency_stops",
        type: "select",
        registerId: "virtual_stops.emergency_stops",
        key: "Emergency stops",
        options: [
          { id: "yes", label: "Yes" },
          { id: "no", label: "No" },
          {
            id: "always",
            label: "Always (even in case of trades without virtual stops)",
          },
        ],
      },
      {
        id: "relative_size",
        type: "text",
        registerId: "virtual_stops.relative_size",
        key: "Relative size",
      },
      {
        id: "add_pips",
        type: "text",
        registerId: "virtual_stops.add_pips",
        key: "Add pips",
      },
    ],
  },
  {
    title: "Visual",
    inputs: [
      {
        id: "display_spread_meter",
        type: "select",
        registerId: "visual.display_spread_meter",
        key: "Display spread meter",
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
      },
      {
        id: "display_status_messages",
        type: "select",
        registerId: "visual.display_status_messages",
        key: "Display status messages",
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
      },
      {
        id: "display_indicators_after_test",
        type: "select",
        registerId: "visual.display_indicators_after_test",
        key: "Display indicators after test",
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
      },
    ],
  },
];
