
import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retryDelay: 3000,
      retry: 2,
      staleTime: 0,
      cacheTime:5,
    },
  },
});
export default queryClient;
