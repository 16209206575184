import React, { useEffect, useState } from "react";
import StyledSideBar from "./StyledSideBar";
import Accordion from "../Accrodion";
import useFetch from "../../utils/hooks/useFetch";
import { MENU_ITEMS, PROCESS_DATA } from "../../constants/routes/api-routes";
import VariablesAndConstantsModal from "../VariablesAndConstantsModal";
import UserModal from "../UserModal";
import { toast } from "react-toastify";
import LoadingButton from "../Common/LoadingButton";
import { Can } from "../../config/acl";
import { v4 as uuidv4 } from "uuid";
import ProjectNameModal from "../ProjectModals/ProjectNameModal";
import useMutate from "../../utils/hooks/useMutate";
import Cookies from "js-cookie";
import { useFlowStore } from "../../store/flowStore";
import mq4Image from "../../assests/img/mq4.png";
import { ReactComponent as Check } from "../../assests/icons/check.svg";
import { useHistoryStore } from "../../store/historyStore";
import { useVariableStore } from "../../store/variableStore";
import { projectSettingsDefaults } from "../../constants/projectSettingsDefaults";

const SideBar = ({ reRender, setRerender }) => {
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const [VariablesModal, setVariablesModal] = useState(false);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState(
    localStorage.getItem("name_by_user")
  );
  const [tempProjectName, setTempProjectName] = useState(
    localStorage.getItem("name_by_user")
  );
  const [projectModal, setProjectModal] = useState(false);
  const toastId = React.useRef(null);

  const { data: menuData, isLoading: menuLoading } = useFetch(
    "GET",
    MENU_ITEMS,
    MENU_ITEMS,
    null,
    null,
    {
      enabled: Boolean(Cookies.get("token")),
    }
  );

  const { mutate: updateProjectMutation, isSuccess: putSuccess } = useMutate(
    "PUT",
    [PROCESS_DATA, localStorage.getItem("projectID")],
    `${PROCESS_DATA}/${localStorage.getItem("projectID")}/`
  );

  const {
    mutate: downloadMutate,
    data,
    isSuccess,
    isError,
  } = useMutate("POST", [PROCESS_DATA], PROCESS_DATA, null, null, null, {
    responseType: "blob",
  });

  const { data: projectData, refetch: projectRefetch } = useFetch(
    "GET",
    [PROCESS_DATA, localStorage.getItem("projectName")],
    PROCESS_DATA + "/search",
    { selected_name: localStorage.getItem("projectName") },
    null,
    { enabled: false }
  );

  const token = Cookies.get("token");
  useEffect(() => {
    setTempProjectName(localStorage.getItem("name_by_user"));
    setProjectName(localStorage.getItem("name_by_user"));
  }, [reRender]);

  useEffect(() => {
    if (putSuccess) {
      if (!isUpdatingName) {
        const _eventsStore = flowStore.events;
        for (const key in _eventsStore) {
          if (Object.prototype.hasOwnProperty.call(_eventsStore, key)) {
            const element = _eventsStore[key];
            const _nodes = [...element.nodes];
            const _nodesData = [...element.nodesData];
            const _edges = [...element.edges];
            _nodesData.forEach((_node) => {
              if (
                _node?.data?.loading &&
                _nodes.find((e) => e.id === _node.id)
              ) {
                _node.data.loading = false;
              }
            });
            const _finalEdges = _edges.filter((edge) => {
              if (_nodes.findIndex((n) => n.id === edge.target) === -1) {
                return false;
              }
              if (_nodes.findIndex((n) => n.id === edge.source) === -1) {
                return false;
              }

              return true;
            });

            element.edges = _finalEdges;
            element.nodesData = _nodesData;
          }
        }
        const _sendingData = {
          events: _eventsStore,
          variables: variableStore.Variables ?? [],
          constants: variableStore.Constants ?? [],
          project_options:
            localStorage.getItem("projectSettings") &&
            localStorage.getItem("projectSettings") != "undefined"
              ? JSON.parse(localStorage.getItem("projectSettings"))
              : projectSettingsDefaults,
        };
        const _apiData = {
          data: _sendingData,
          selected_name: localStorage.getItem("projectName"),
        };
        setIsLoading(true);
        downloadMutate(_apiData);
      } else {
        setIsLoading(false);
        setIsUpdatingName(false);
      }
    }
    // eslint-disable-next-line
  }, [putSuccess]);

  useEffect(() => {
    return () => {
      setProjectName(null);
    };
  }, []);

  //handle close modal
  const modalCloseHandler = () => {
    setVariablesModal(false);
  };

  //handle opening modal
  const modalOpenHandler = (key) => {
    setVariablesModal(key);
  };

  useEffect(() => {
    if (projectData) {
      const _selectedProject = projectData?.data?.find(
        (item) => item?.selected_name === localStorage.getItem("projectName")
      );
      localStorage.setItem("projectID", _selectedProject?.id);
      localStorage.setItem("projectData", JSON.stringify(_selectedProject));
      window.location.reload();
    }
  }, [projectData]);

  const setDataHandler = (isReport = false) => {
    const _eventsStore = flowStore.events;
    for (const key in _eventsStore) {
      if (Object.prototype.hasOwnProperty.call(_eventsStore, key)) {
        const element = _eventsStore[key];
        const _nodes = [...element.nodes];
        const _nodesData = [...element.nodesData];
        const _edges = [...element.edges];
        _nodesData.forEach((_node) => {
          if (_node?.data?.loading && _nodes.find((e) => e.id === _node.id)) {
            _node.data.loading = false;
          }
        });
        const _finalEdges = _edges.filter(
          (edge) =>
            _nodes.findIndex((n) => n.id === edge.source) > -1 &&
            _nodes.findIndex((n) => n.id === edge.target) > -1
        );
        console.log({ _edges });

        element.edges = _finalEdges;
        element.nodesData = _nodesData;
      }
    }
    const _sendingData = {
      events: _eventsStore,
      variables: variableStore.Variables ?? [],
      constants: variableStore.Constants ?? [],
      project_options:
        localStorage.getItem("projectSettings") &&
        localStorage.getItem("projectSettings") != "undefined"
          ? JSON.parse(localStorage.getItem("projectSettings"))
          : projectSettingsDefaults,
    };
    if (!localStorage.getItem("projectID")) {
      const _uuid = uuidv4();
      const _apiData = {
        data: _sendingData,
        selected_name: _uuid,
        name_by_user: projectName,
        highestIndex: localStorage.getItem("highestIndex"),
      };
      if (isReport === true) {
        _apiData.selected_name = localStorage.getItem("projectName")
          ? localStorage.getItem("projectName")
          : _uuid;

        let element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," +
            encodeURIComponent(JSON.stringify(_apiData))
        );
        element.setAttribute(
          "download",
          `${localStorage.getItem("name_by_user")}-DeveloperReport.json`
        );

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        localStorage.setItem("projectName", _uuid);
        setIsLoading(true);
        if (!isUpdatingName) {
          toastId.current = toast.loading("Downloading MQL File...");
        }
        downloadMutate(_apiData);
      }
    } else {
      const _project = JSON.parse(localStorage.getItem("projectData"));
      _project.json_file = JSON.stringify({
        data: _sendingData,
        selected_name: localStorage.getItem("projectName"),
        name_by_user: projectName
          ? projectName
          : localStorage.getItem("name_by_user"),
        highestIndex: localStorage.getItem("highestIndex"),
      });
      _project.name_by_user = projectName
        ? projectName
        : localStorage.getItem("name_by_user");
      delete _project?.user_related;
      let _history = [
        ...useHistoryStore
          .getState()
          .histories?.sort((a, b) =>
            new Date(a?.assigned_name.split("*")[1] ?? undefined).getTime() >
            new Date(b?.assigned_name.split("*")[1] ?? undefined).getTime()
              ? 1
              : -1
          ),
      ];
      _project.project_detail_levels = _history;
      if (isReport === true) {
        const _sendingData = {
          events: flowStore.events,
          variables: variableStore.Variables ?? [],
          constants: variableStore.Constants ?? [],
          project_options:
            localStorage.getItem("projectSettings") &&
            localStorage.getItem("projectSettings") != "undefined"
              ? JSON.parse(localStorage.getItem("projectSettings"))
              : projectSettingsDefaults,
        };
        const _apiData = {
          data: _sendingData,
          selected_name: localStorage.getItem("projectName"),
          name_by_user: projectName
            ? projectName
            : localStorage.getItem("name_by_user"),
          highestIndex: localStorage.getItem("highestIndex"),
        };
        let element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," +
            encodeURIComponent(JSON.stringify(_apiData))
        );
        element.setAttribute(
          "download",
          `${localStorage.getItem("name_by_user")}-DeveloperReport.json`
        );

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        if (!isUpdatingName) {
          toastId.current = toast.loading("Downloading MQL File...");
        }
        _project.oldValues = variableStore.oldValues;
        updateProjectMutation(_project);
        // setUpdateData(_project);
      }
    }
  };

  //Data Handler (here we convert the data for the server )
  const sendDataHandler = async (isReport = false) => {
    if (!localStorage.getItem("projectID")) {
      setProjectModal(true);
    } else {
      setIsLoading(true);
      setDataHandler(isReport);
    }
  };

  useEffect(() => {
    if (data && isSuccess) {
      setIsLoading(false);
      if (!localStorage.getItem("projectID")) {
        projectRefetch();
      }
      const href = URL.createObjectURL(data?.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `${localStorage.getItem("name_by_user")}.mq4`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      toast.done(toastId.current);
      toast.success("Download Completed");
    }
    if (isError) {
      toast.done(toastId.current);
      toast.error("Download Failed! Please try again.");
    }
    // eslint-disable-next-line
  }, [data, isSuccess, isError]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      projectName != null &&
      // eslint-disable-next-line
      projectName != undefined &&
      localStorage.getItem("name_by_user") !== projectName
    ) {
      localStorage.setItem("name_by_user", projectName);
      setDataHandler(false);
      setTempProjectName(projectName);
    }
    setProjectModal(false);
    //eslint-disable-next-line
  }, [projectName]);

  const changeNameHandler = (e) => {
    e.preventDefault();
    setProjectName(tempProjectName);
    setIsLoading(true);
    setIsUpdatingName(true);
  };

  return (
    <StyledSideBar>
      {localStorage.getItem("name_by_user") && (
        <div className="nameHolder">
          <input
            type="text"
            value={tempProjectName}
            onChange={(e) => setTempProjectName(e.target.value)}
          />
          <LoadingButton
            isLoading={isLoading}
            onClick={changeNameHandler}
            className="changeNameBtn"
          >
            <Check />
          </LoadingButton>
        </div>
      )}

      {projectModal && (
        <ProjectNameModal
          setModal={setProjectModal}
          setProjectName={setProjectName}
        />
      )}
      <div className="variableBtnHolder">
        <button onClick={modalOpenHandler.bind(this, "Constants")}>
          <span className="badge">{variableStore.Constants.length}</span>
          Constants
        </button>
        <button onClick={modalOpenHandler.bind(this, "Variables")}>
          <span className="badge">{variableStore.Variables.length}</span>
          Variables
        </button>
      </div>
      {menuLoading ? (
        <span>loading...</span>
      ) : (
        <Accordion
          items={menuData?.data
            ?.map((parent) => {
              parent.parent_name.forEach((element) => {
                delete element.menu_child_feature;
              });
              return parent;
            })
            ?.filter(
              (item) =>
                (item?.events_child?.length < 1 ||
                  item?.events_child?.find(
                    (_event) =>
                      _event?.slug?.replace(/-/g, "_") ===
                      flowStore.selectedEvent
                  )) &&
                item?.is_shown
            )}
        />
      )}
      {VariablesModal && (
        <VariablesAndConstantsModal
          closeHandler={modalCloseHandler}
          modal={VariablesModal}
        />
      )}
      <LoadingButton
        className="exportBtn"
        onClick={sendDataHandler}
        isLoading={isLoading}
        style={{ background: `url(${mq4Image})` }}
      />
      <Can I="read" a="debugging">
        <LoadingButton
          onClick={sendDataHandler.bind(this, true)}
          title={"Developer Report"}
          isLoading={isLoading}
        />
      </Can>
    </StyledSideBar>
  );
};

export default SideBar;
