import React, { useState } from "react";
import { StyledSettingAccordion } from "./StyledSettingAccordion";
const SettingAccordion = ({ title, inputs, register }) => {
  const [open, setOpen] = useState(true);

  return (
    <StyledSettingAccordion>
      {title && (
        <div
          onClick={() => setOpen(!open)}
          className={`separator ${open ? "expanded" : ""}`}
        >
          {title}
        </div>
      )}

      {title === "Pip Size" && open && (
        <div>
          <p>
            Here you can customize the size of 1 pip that your project will use
            on different charts.
          </p>
          <p>
            Normally you have something called "point" that is like 0.00001 or
            0.01, but different brokers use different values.
          </p>
          <p>
            In the input field below write 1 rule per line. Here are few
            examples:
          </p>
          <ul
            style={{
              listStyle: "none",
              margin: 5,
              padding: 0,
            }}
          >
            <li>
              <span style={{ color: "salmon" }}>0.00001</span> ={" "}
              <span style={{ color: "lightgreen" }}>0.0001</span>
              <span style={{ fontStyle: "italic" }}>
                (Means that if the point size is{" "}
                <span style={{ color: "salmon" }}>0.00001</span>, then 1 pip
                will be <span style={{ color: "lightgreen" }}>0.0001</span>)
              </span>
            </li>
            <li>
              <span style={{ color: "salmon" }}>EURUSD</span> ={" "}
              <span style={{ color: "lightgreen" }}>0.0001</span>
              <span style={{ fontStyle: "italic" }}>
                (Means that if the Symbol name starts with{" "}
                <span style={{ color: "salmon" }}>EURUSD</span>, then 1 pip will
                be <span style={{ color: "lightgreen" }}>0.0001</span>)
              </span>
            </li>
            <li>
              <span style={{ color: "salmon" }}>EUR?</span> ={" "}
              <span style={{ color: "lightgreen" }}>0.0001</span>
              <span style={{ fontStyle: "italic" }}>
                (Means that if the first 3 letters of the Symbol name are{" "}
                <span style={{ color: "salmon" }}>EUR</span>, then 1 pip will be{" "}
                <span style={{ color: "lightgreen" }}>0.0001</span>)
              </span>
            </li>
            <li>
              <span style={{ color: "salmon" }}>?USD</span> ={" "}
              <span style={{ color: "lightgreen" }}>0.0001</span>
              <span style={{ fontStyle: "italic" }}>
                (Means that if letters 4-6 of the Symbol name are{" "}
                <span style={{ color: "salmon" }}>USD</span>, then 1 pip will be{" "}
                <span style={{ color: "lightgreen" }}>0.0001</span>
              </span>
              )
            </li>
          </ul>
        </div>
      )}
      <div
        className="inputsContainer"
        style={{ display: open || !title ? "block" : "none" }}
      >
        {inputs &&
          inputs.length > 0 &&
          inputs.map((input) => (
            <div
              className="inputHolderSettings"
              key={`${input?.registerId}-${input?.id}-${input?.key}`}
            >
              <div className="labelHolder">
                <span>{input?.key}</span>
              </div>
              {input.type === "text" ? (
                <input
                  key={input?.id + input?.key}
                  autoComplete="off"
                  type="text"
                  {...register(input?.registerId ?? `params.${input?.id}`, {})}
                />
              ) : input.type === "select" ? (
                <select
                  key={input?.id + input?.key}
                  autoComplete="off"
                  {...register(input?.registerId ?? `params.${input?.id}`, {})}
                >
                  {input?.options &&
                    input?.options.map((_option, index) => (
                      <option
                        key={index + _option?.id + _option?.name}
                        value={_option?.id}
                      >
                        {_option?.label}
                      </option>
                    ))}
                </select>
              ) : input.type === "textarea" ? (
                <textarea
                  key={input?.id + input?.key}
                  autoComplete="off"
                  rows={7}
                  {...register(input?.registerId ?? `params.${input?.id}`, {})}
                />
              ) : null}
            </div>
          ))}
      </div>
    </StyledSettingAccordion>
  );
};

export default SettingAccordion;
