import React, { useState } from "react";
import Input from "../../Input";

const ConditionAccordion = ({
  additionalSelect,
  id,
  nodeId,
  inputChangeHandler,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="additionalSelectHolder" key={additionalSelect.id}>
      <div
        onClick={() => setOpen((e) => !e)}
        className={`additionalSelectTitle ${
          open ? "additionalSelectOpen" : "additionalSelectClose"
        }`}
      >
        {additionalSelect?.key}
      </div>
      <div
        style={{ display: open ? "block" :"none", padding: open ? "0.4rem" : 0 }}
        className="additionalSelectInputs"
      >
        {additionalSelect?.inputs.length > 0 &&
          additionalSelect?.inputs.map((input) => (
            <Input
              key={input?.id + input?.key}
              id={id}
              nodeId={nodeId}
              registerId={`params.${id}.params.${input?.id}`}
              input={input}
              inputChangeHandler={
                input?.type === "select" && inputChangeHandler
              }
              type={input?.type}
            />
          ))}
      </div>
    </div>
  );
};

export default ConditionAccordion;
