import React, { useState } from "react";
import StyledHistorySection from "./StyledHistorySection";
import { useHistoryStore } from "../../store/historyStore";
import { useFlowStore } from "../../store/flowStore";

const HistorySection = ({ reRender, setRerender }) => {
  // const [historyList, setHistoryList] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const historyStore = useHistoryStore();
  const flowStore = useFlowStore();
  const historyHandler = (_history) => {
    historyStore.selectHistory(_history.assigned_name);
    flowStore.restoreHistory(_history);
  };

  const menuHandler = () => {
    setShowMenu((e) => !e);
  };

  const historyClass = (index) => {
    const _selectedIndex = historyStore.histories.findIndex(
      (e) => e.assigned_name === historyStore.selectedHistoryId
    );
    if (_selectedIndex < 0) {
      return index < historyStore.histories.length - 1 ? "active" : "selected";
    } else {
      if (index === _selectedIndex) {
        return "selected";
      } else if (index < _selectedIndex) {
        return "active";
      } else {
        return "inActive";
      }
    }
  };

  return (
    <StyledHistorySection>
      <button onClick={menuHandler}>History</button>
      {showMenu && (
        <div className="historyMenu ">
          {historyStore.histories
            ?.sort((a, b) =>
              new Date(a?.assigned_name.split("*")[1] ?? undefined).getTime() >
              new Date(b?.assigned_name.split("*")[1] ?? undefined).getTime()
                ? 1
                : -1
            )
            .map((_history, index) => (
              <div
                onClick={historyHandler.bind(this, _history)}
                className={`historyItem ${historyClass(index)}`}
                key={index}
              >
                {_history?.assigned_name?.split("-")[1]?.split("*")[0] ?? ""}
                <br />
                <span className="dateHolder">
                  {_history?.assigned_name?.split("*")[1] ?? ""}
                </span>
              </div>
            ))}
        </div>
      )}
    </StyledHistorySection>
  );
};

export default HistorySection;
