const blockName = {
  buyNow: "Buy now",
  sellNow: "Sell now",
  buyPending: "Buy pending order",
  sellPending: "Sell pending order",
  oncePerBar: "Once per bar",
  condition: "condition",
  formula: "formula",
  ifTrade: "If trade",
  modifyVariables: "Modify Variables",
  checkProfitUnrealized: "Check profit (unrealized)",
  and: "AND",
  or: "OR",
  loopPassNTimes: 'Loop(pass "n" times)',
  delay: "Delay",
  closeTrades: "Close trades",
  noTradeNearby: "No trade nearby",
  comment: "Comment",
  conditions: "conditions",
};

export default blockName;
