import React, { useState } from "react";
import StyledSetVariableModal from "./StyledSetVariableModal";
import { useFormContext } from "react-hook-form";
import { useVariableStore } from "../../store/variableStore";

const SetVariableModal = ({
  setVariable,
  closeVariableModalHandler,
  setValue,
  inputId,
  nodeId,
  registerId,
}) => {
  const { getValues } = useFormContext();
  const variableStore = useVariableStore();
  const [constants] = useState(variableStore.Constants);
  const [variables] = useState(variableStore.Variables);

  const useDefaultValueHandler = (e) => {
    setVariable({ name: "", type: "", id: "" });
    let _oldValues = variableStore.oldValues;

    const _currentVariable = getValues(registerId);
    variableStore.removeOldValue(_currentVariable, registerId, nodeId);
    const _variableIndex = _oldValues.findIndex(
      (item) => item?.variable?.name === _currentVariable
    );

    if (_variableIndex > -1) {
      const _selectedNodeIndex = _oldValues[_variableIndex].nodes.findIndex(
        (item) => item.id === nodeId
      );
      if (_selectedNodeIndex >= 0) {
        setValue(
          registerId,
          _oldValues[_variableIndex].nodes[_selectedNodeIndex][registerId]
        );
      }
    }
    closeVariableModalHandler(e);
  };

  const variableSetHandler = (variable, type, e) => {
    const _oldValue = getValues(registerId);
    variableStore.assignVariable(variable, nodeId, registerId, _oldValue);
    setValue(registerId, variable?.name);
    setVariable({ ...variable, name: variable?.name, type: type });
    closeVariableModalHandler(e);
  };
  return (
    <StyledSetVariableModal>
      <div className="header">
        <button onClick={closeVariableModalHandler}>Close this window</button>
        <button onClick={useDefaultValueHandler}>use the original value</button>
      </div>
      <div className="content">
        <div>
          <table>
            <caption>Constants</caption>
            <thead>
              <tr>
                <td>name</td>
                <td>default value</td>
              </tr>
            </thead>
            <tbody>
              {constants.length > 0 &&
                constants.map((constant) => (
                  <tr key={constant?.name}>
                    <td>
                      <div
                        onClick={variableSetHandler.bind(
                          this,
                          constant,
                          "constant"
                        )}
                        className="constant"
                      >
                        {constant?.name}
                      </div>
                    </td>
                    <td>{constant?.value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <caption>Variables</caption>
            <thead>
              <tr>
                <td>name</td>
                <td>default value</td>
              </tr>
            </thead>
            <tbody>
              {variables.length > 0 &&
                variables.map((variable) => (
                  <tr key={variable}>
                    <td>
                      <div
                        onClick={variableSetHandler.bind(
                          this,
                          variable,
                          "variable"
                        )}
                        className="variable"
                      >
                        {variable?.name}
                      </div>
                    </td>
                    <td>{variable?.value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </StyledSetVariableModal>
  );
};

export default SetVariableModal;
