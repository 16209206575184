import React, { useEffect, useState } from "react";
import StyledFormulaModal from "./StyledFormulaModal";
import ConditionSection from "../ConditionSection";
import useFetch from "../../../utils/hooks/useFetch";
import { MODAL_DETAIL } from "../../../constants/routes/api-routes";
import { useFormContext } from "react-hook-form";
import Input from "../../Input";
import { useFlowStore } from "../../../store/flowStore";

const FormulaModal = ({ nodeId }) => {
  const flowStore = useFlowStore();
  const _selectedNode = flowStore.nodesData?.find((node) => node.id === nodeId);

  const { data, isSuccess } = useFetch(
    "GET",
    [`${MODAL_DETAIL + _selectedNode?.data?.slug}/`],
    `${MODAL_DETAIL + _selectedNode?.data?.slug}/`,
    null,
    null,
    { enabled: !!_selectedNode }
  );
  const { register } = useFormContext();
  const [operators] = useState([
    { label: "+", value: "+" },
    { label: "-", value: "-" },
    { label: "x", value: "*" },
    { label: "÷", value: "/" },
  ]);
  const [details, setDetails] = useState([]);
  useEffect(() => {
    if (data && isSuccess && data?.data?.menu_child_feature?.length > 0) {
      const _inputs = [];
      data?.data?.menu_child_feature[0]?.input_fields?.forEach((input) => {
        _inputs.push({
          id: input?.name_key,
          key: input?.name_represent,
          type: "text",
          registerId: `params.${input?.name_key}`,
          value: input?.name_value,
        });
      });
      console.log(data?.data?.menu_child_feature);
      setDetails(_inputs);
    }
  }, [data, isSuccess]);
  // const { data, isLoading, isSuccess } = useFetch(
  //   "GET",
  //   FORMULA_SIGNS,
  //   FORMULA_SIGNS
  // );

  // const { data: detailsData, isSuccess: detailsSuccess } = useFetch(
  //   "GET",
  //   FORMULA_DETAILS,
  //   FORMULA_DETAILS
  // );

  // useEffect(() => {
  //   if (detailsSuccess && detailsData) {
  //     const _data = detailsData?.data?.formula_details;
  //     if (_data.length > 0) {
  //       const _inputs = _data[0]?.feature_formula;
  //       const _details = [];
  //       _inputs.forEach((input) => {
  //         _details.push({
  //           id: input?.feature_key,
  //           key: input?.feature_represent_key,
  //           value: input?.feature_value,
  //           type: "text",
  //           registerId: `params.${input?.feature_key}`,
  //         });
  //       });

  //       setDetails(_details);
  //     }
  //   }
  // }, [detailsSuccess, detailsData]);

  // useEffect(() => {
  //   if ((data, isSuccess)) {
  //     const _data = data?.data?.signs;
  //     const _operators = [];
  //     _data.forEach((op) => {
  //       _operators.push({
  //         label: op.label,
  //         value: op.label === "×" ? "*" : op.label === "÷" ? "/" : op.label,
  //       });
  //     });
  //     setOperators(_operators);
  //     setValue("params.operator.label", _operators[0].value);
  //   }
  //   // eslint-disable-next-line
  // }, [data, isSuccess]);

  return (
    <StyledFormulaModal>
      <div className="valueFetchHolder">
        <ConditionSection nodeId={nodeId} id="left" />

        <div className="operator">
          <span>Operator</span>
          {operators.length > 0 && (
            <select {...register("params.operator.label")}>
              {operators.map((operator) => (
                <option value={operator?.value} key={operator?.value}>
                  {operator?.label}
                </option>
              ))}
            </select>
          )}
        </div>

        <ConditionSection nodeId={nodeId} id="right" />
      </div>

      {details.length > 0 && (
        <div className="detailsContainer">
          {details.map((input, index) => (
            <Input
              key={input?.id + index}
              input={input}
              registerId={input?.registerId}
              nodeId={nodeId}
              type={input?.type}
            />
          ))}
        </div>
      )}
    </StyledFormulaModal>
  );
};

export default FormulaModal;
