import React, { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { AbilityContext } from "../../config/acl";
import { HOME_ROUTE } from "../../constants/routes/app-routes";

export default function RenderRoutes({
  routePages,
  redirectRoute,
  exact = true,
}) {
  const ability = useContext(AbilityContext);
  const element = useRoutes(
    routePages.map((Page) => ({
      path: Array.isArray(Page.route) ? Page.route[0] : Page.route,
      element: ability.can("read", Page.type) ? (
        <Page.Component />
      ) : (
        <Navigate to={HOME_ROUTE} />
      ),
    }))
  );

  return element;
  // return (
  //   <Switch>
  //     {routePages.map((Page) => (
  //       <Route
  //         key={Array.isArray(Page.route) ? Page.route[0] : Page.route}
  //         path={Page.route}
  //         exact={Page.exact}
  //       >
  //         {ability.can("read", Page.type) ? (
  //           <Page.Component />
  //         ) : (
  //           <Redirect to={HOME_ROUTE} />
  //         )}
  //       </Route>
  //     ))}
  //     {redirectRoute && <Redirect to={redirectRoute} />}
  //   </Switch>
  // );
}
