import React from "react";
import StyledLoadingButton from "./StyledLoadingButton";
import Loader from "../Loader";

const LoadingButton = ({ title, isLoading, ...props }) => {
  return (
    <StyledLoadingButton disabled={isLoading} isLoading={isLoading} {...props}>
      {Boolean(title) && <span>{title}</span>}
      {props.children}
      {isLoading && <Loader />}
    </StyledLoadingButton>
  );
};

export default LoadingButton;
