import React, { useState } from "react";
import StyledProjectNameModal from "./StyledProjectNameModal";

const ProjectNameModal = ({ setModal, setProjectName }) => {
  const [name, setName] = useState("");
  const inputChangeHandler = (e) => {
    setName(e.target.value);
  };
  const closeHandler = () => {
    setProjectName(name);
  };
  return (
    <StyledProjectNameModal>
      <div className="userMainModal">
        <button onClick={() => setModal(false)} className="closeBtn">
          X
        </button>
        <h1>New Project :</h1>
        <p>Enter your desired name for your new Project.</p>
        <div className="userModalForm">
          <div className="userModalInputHolder">
            <span>Project Name:</span>
            <input
              value={name}
              onChange={inputChangeHandler}
              placeholder="Project Name..."
              type="text"
            />
          </div>
          <button onClick={closeHandler}>Submit</button>
        </div>
      </div>
    </StyledProjectNameModal>
  );
};
export default ProjectNameModal;
