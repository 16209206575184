import axios from "axios";
import Cookies from "js-cookie";

const createApi = (baseUrl, headers, extraFeatures) =>
  axios.create({
    baseURL: baseUrl,
    withCredentials: true, // This is crucial for sending cookies
    headers: {
      "Content-type": "application/json",
      "X-CSRFToken": Cookies.get("csrfToken"),
      Userip: sessionStorage.getItem("Userip"),
      ...headers,
    },
    ...extraFeatures,
  });

const ApiClient = createApi(process.env.REACT_APP_API_BASE_URL);

// Add a request interceptor to include the CSRF token in the Cookie header
ApiClient.interceptors.request.use(
  (config) => {
    config.headers["X-CSRFToken"] = Cookies.get("csrfToken");
    // config.headers["Cookie"] = `csrftoken=${Cookies.get("csrftoken")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default ApiClient;
