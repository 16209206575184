import styled from "styled-components";

const StyledInput = styled.div`
  position: relative;
  select,
  input[type="text"],
  input[type="number"] {
    font-size: 12px;
    margin: 3px 0;
    padding: 2px;
    background: #333;
    color: #ddd;
    width: fit-content;
    cursor: pointer;
    border-radius: 0 3px 0 3px;
    option {
      width: 100%;
    }
  }

  .inputHolder {
    display: flex;
    margin: 3px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input[type="text"] {
      min-width: 36px;
      width: fit-content;
      margin: 0;
    }
    select {
      margin: 0;
    }
    span {
      color: #ddd;
      font-size: 12px;
      white-space: nowrap;
      margin-right: 5px;
    }
  }
  .constant,
  .variable {
    padding: 2px 4px;
    color: #aaaaaa;
    border: 1px solid black;
    text-align: center;
    min-width: 30px;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-top: -2px;
    }
  }
  .constant {
    background: #994422;
  }
  .variable {
    background: #444499;
  }

  .valueFetchHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      background: transparent;
      text-align: center;
      margin-bottom: -7px;
    }
  }
`;

export default StyledInput;
