import styled from "styled-components";

const StyledUserModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
  }
  .userMainModal {
    position: relative;
    width: 600px;
    padding: 8px;
    border: 1px solid #d6d6d6;
    z-index: 9999;
    overflow: hidden;
    background: #666666;
    box-shadow: 0 0 10px #d6d6d6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .userModalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .userModalInputHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    width: 300px;
    span {
      color: #fff;
      margin-right: 10px;
    }

    input {
      padding: 10px;
      border: none;
      border-radius: 5px;
    }
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
  }

  button {
    margin: 0 !important;
    margin-top: 10px !important;
  }
  .infoHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    button {
      background: #666666;
      color: #fff;
      border: 1px solid #fff;
      margin-left: 10px;
    }
    p {
      color: #fff;
      margin-right: 10px;
    }
  }
  .loginSubmitBtn span {
    color: black !important;
  }
  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    cursor: pointer;
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 50%;
  }
  .captchaImage {
    border-radius: 8px;
    margin-right: 8px;
    width: 150px;
  }
  .captchaInput {
    width: 140px;
  }
`;

export default StyledUserModal;
