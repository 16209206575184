import React, { useCallback, useEffect, useState } from "react";
import StyledCustomNode from "./StyledCustomNode";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import PropertiesModal from "../ProppertiesModal";
import { v4 as uuidv4 } from "uuid";
import deleteNodeHandler from "../../utils/functions/deleteNodeHandler";
import ApiClient from "../../API";
import useFetch from "../../utils/hooks/useFetch";
import {
  CONDITION_INPUTS,
  MODAL_DETAIL,
} from "../../constants/routes/api-routes";
import blockName from "../../constants/blocks/blockNames";
import exceptions from "../../constants/exeptions";
import setNestedValue from "../../utils/functions/setNestedValue";
import useUpdateProject from "../../utils/hooks/useUpdateProject";
import { useFlowStore } from "../../store/flowStore";
import deleteImage from "../../assests/img/delete.png";
import copyImage from "../../assests/img/page_white_copy.png";
import editTitleImage from "../../assests/img/title.png";
import toggleImage from "../../assests/img/toggle.png";
import { ReactComponent as Check } from "../../assests/icons/check.svg";
import { useVariableStore } from "../../store/variableStore";
import { useModalStore } from "../../store/modalStore";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Cookies from "js-cookie";

const CustomNode = ({ data, ...props }) => {
  const flowStore = useFlowStore();
  const variableStore = useVariableStore();
  const { openModal, closeModal } = useModalStore();
  const id = data?.id;
  const [isLoading, setIsLoading] = useState(data?.loading);
  const [menu, setMenu] = useState(false);
  const { updateProject } = useUpdateProject();
  // const [propertiesModal, setPropertiesModal] = useState(false);
  const [editedName, setEditedName] = useState(data?.title);
  const [editMode, setEditMode] = useState(false);
  const { getNode, setNodes, getNodes, setEdges } = useReactFlow();
  const [isSelected, setIsSelected] = useState(false);
  const { data: fetchedData, isSuccess } = useFetch(
    "GET",
    [`${MODAL_DETAIL + data?.slug}/`, data?.loading],
    `${MODAL_DETAIL + data?.slug}/`,
    null,
    null,
    { enabled: !!data?.slug && !!data && !!data?.loading }
  );

  const fetchValueFetchData = async (endpoint) => {
    return await ApiClient({
      method: "GET",
      url: endpoint,
      headers: {
        Authorization: Cookies.get("token")
          ? `Bearer ${Cookies.get("token")}`
          : null,
      },
    });
  };

  const valueFetchHandler = async (property, valueFetchList) => {
    const _property = { ...property };
    for (let index = 0; index < valueFetchList.length; index++) {
      const element = valueFetchList[index];
      await fetchValueFetchData(element?.endpoint)
        ?.then((data) => {
          const _data = data?.data;
          const _registerId = (i) =>
            element?.register_id?.length > 0 && element?.register_id !== "NULL"
              ? `params.${element?.register_id}.row${i}`
              : `params.${element?.nameKey}.row${i}`;
          setNestedValue(_property, _registerId(1), element?.rows[0]);
          setNestedValue(_property, _registerId(2), element?.rows[1]);

          _data?.conditions_input_field?.forEach((input) => {
            setDefaultValues(
              valueFetchList,
              input,
              _property,
              "text",
              null,
              null,
              element?.register_id && element?.register_id !== "NULL"
                ? element?.register_id
                : element?.nameKey,
              true
            );
          });

          _data?.conditions_dropdown_menu?.forEach((input) => {
            if (
              input?.name_key?.includes(exceptions.adjust) ||
              input?.name_key?.includes(exceptions.moreSettings)
            ) {
              setDefaultValues(
                valueFetchList,
                input,
                _property,
                "select",
                "condition_child_extra_feature_name",
                true,
                element?.register_id && element?.register_id !== "NULL"
                  ? element?.register_id
                  : element?.nameKey,
                true
              );
            } else {
              setDefaultValues(
                valueFetchList,
                input,
                _property,
                "select",
                "condition_child_extra_feature_name",
                false,
                element?.register_id && element?.register_id !== "NULL"
                  ? element?.register_id
                  : element?.nameKey,
                true
              );
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const _properties = flowStore.nodes;
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === _property?.id) {
          node.data = {
            ...node.data,
            loading: false,
          };
          flowStore.updateNodesData(node.id, node);
        }
        return node;
      })
    );
    if (!_properties.find((item) => item?.id === data?.id)) {
      _properties.push(_property);
      flowStore.setNodes(_properties);
    }
    updateProject(null, false, true, `New block created (${data?.index})`);
  };

  const setDefaultValues = (
    valueFetchList,
    input,
    property,
    type,
    optionsKey,
    additionalInputs = false,
    nameKey,
    isCondition = false
  ) => {
    if (type === "text") {
      const registerId =
        input?.register_id?.length > 0 &&
        input?.register_id !== "NULL" &&
        isCondition
          ? `params.${input?.register_id}`
          : input?.register_id?.length > 0 && input?.register_id !== "NULL"
          ? input?.register_id
          : isCondition
          ? `params.${nameKey}.params.${input?.name_key}`
          : `params.${input?.name_key}`;
      if (input?.type === "valueFetch") {
        const _rows = input?.name_value.split(",");
        valueFetchList?.push({
          endpoint: CONDITION_INPUTS(_rows[0], _rows[1]),
          rows: _rows,
          nameKey: input?.name_key,
          register_id: input?.register_id,
        });
      } else {
        setNestedValue(property, registerId, input?.name_value);
      }
    } else if (type === "select") {
      if (additionalInputs) {
        input?.[optionsKey]?.forEach((item) => {
          setDefaultValues(
            valueFetchList,
            item,
            property,
            item?.type,
            "condition_grand_child_feature_name",
            false,
            nameKey,
            true
          );
        });
      } else {
        const registerId =
          input?.register_id?.length > 0 &&
          input?.register_id !== "NULL" &&
          isCondition
            ? `params.${input?.register_id}`
            : input?.register_id?.length > 0 && input?.register_id !== "NULL"
            ? input?.register_id
            : isCondition
            ? `params.${nameKey}.params.${input?.name_key}`
            : `params.${input?.[optionsKey][0]?.name_key}`;
        setNestedValue(property, registerId, input?.name_value);

        const _selectedOption = input?.[optionsKey].find(
          (option) => option?.name_value === input?.name_value
        );

        _selectedOption?.grand_child_dropdown_menu?.forEach((element) => {
          setDefaultValues(
            valueFetchList,
            element,
            property,
            "select",
            "the_child_details"
          );
        });

        _selectedOption?.grand_child_input_field?.forEach((element) => {
          setDefaultValues(valueFetchList, element, property, "text");
        });
      }
    }
  };

  useEffect(() => {
    if (fetchedData && isSuccess) {
      //here we will set the defaults
      const _data = fetchedData?.data;
      const _valueFetchList = [];
      const _property = {
        params: {},
        id: data?.id,
        id_by_user: data?.index,
        category: data?.category,
        block_name_mql: data?.slug?.replace(/-/g, "_"),
        blockName: data?.title,
      };
      _data?.menu_child_feature?.forEach((header) => {
        header?.input_fields?.forEach((input) => {
          setDefaultValues(_valueFetchList, input, _property, "text");
        });

        header?.dropdown_menu?.forEach((input) => {
          setDefaultValues(
            _valueFetchList,
            input,
            _property,
            "select",
            "child_extra_feature_name"
          );
        });
      });

      if (_valueFetchList?.length > 0) {
        //get the valueFetch inputs
        valueFetchHandler(_property, _valueFetchList);
      } else {
        //set Property in localStorage
        const _properties = flowStore.nodes;

        if (!_properties.find((item) => item?.id === data?.id)) {
          _properties.push(_property);
          if (_property?.block_name_mql === blockName.condition) {
            _property.params = exceptions.conditionParams;
          }
          if (_property?.block_name_mql === blockName.formula) {
            _property.params = exceptions.formulaParams;
          }
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === _property?.id) {
                node.data = {
                  ...node.data,
                  loading: false,
                };

                flowStore.updateNodesData(node.id, node);
              }
              return node;
            })
          );
          if (_properties.length > 0) {
            flowStore.setNodes(_properties);
            updateProject(
              flowStore.events,
              false,
              true,
              `New block created (${data?.index})`
            );
          } else {
            flowStore.setNodes(_property);
            updateProject(
              flowStore.events,
              false,
              true,
              `New block created (${data?.index})`
            );
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, fetchedData]);

  const duplicateNode = useCallback(() => {
    const node = { ...getNode(id) };
    const position = {
      x: node.position.x + 50,
      y: node.position.y + 50,
    };
    let _id = uuidv4();
    const _index = Number(localStorage.getItem("highestIndex"));
    const _nodes = [...flowStore.nodes];
    const copiedNodeParams = { ..._nodes.find((e) => e.id === id) };
    flowStore.addNodesData({
      ...node,
      data: {
        ...node.data,
        index: _index,
        id: _id,
      },
      id: _id,
      position,
      positionAbsolute: position,
      selected: false,
      dragging: false,
    });
    flowStore.addNode({
      ...copiedNodeParams,
      id: _id,
      id_by_user: _index,
    });
    variableStore.duplicateNode(id, _id);
    updateProject(flowStore.events, false, true, `copy single block`);
    localStorage.setItem("highestIndex", Number(_index) + 1);
    setMenu(false);
    // eslint-disable-next-line
  }, [id, getNode, setNodes, getNodes, data]);

  useEffect(() => {
    setIsLoading(data?.loading);
  }, [data]);

  useEffect(() => {
    setMenu(false);
    closeModal(data?.id);
    return () => {
      setMenu(false);
      closeModal(data?.id);
    };
  }, [flowStore.selectedEvent]);

  const deleteNode = useCallback(() => {
    const _events = deleteNodeHandler(setNodes, setEdges, setMenu, id);
    updateProject(_events, false, true, `Delete block (${data?.index})`);
    // eslint-disable-next-line
  }, [id, setNodes, setEdges]);

  const contextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMenu((e) => {
      if (e) {
        setIsSelected(false);
        return false;
      } else {
        setIsSelected(true);
        return true;
      }
    });
  };

  useEffect(() => {
    if (isSelected) {
      // flowStore.selectNode(id);
      setNodes((nds) =>
        nds.map((_node) => {
          if (_node.id === id) {
            return { ..._node, selected: true };
          }
          return _node;
        })
      );
    } else {
      setNodes((nds) =>
        nds.map((_node) => {
          if (_node.id === id) {
            return { ..._node, selected: false };
          }
          return _node;
        })
      );
      // flowStore.deselectNode(id);
    }
  }, [isSelected]);

  const propertiesModalOpenHandler = () => {
    flowStore.deselectNode(id);

    openModal({
      ...data,
      x: props?.positionAbsoluteX + props?.width,
      y: props?.positionAbsoluteY + props?.height / 2,
    });
  };

  // const propertiesModalCloseHandler = () => {
  //   flowStore.deselectNode(id);
  //   variableStore.cancelRemovingOldValue(id);
  //   setPropertiesModal(false);
  // };

  const editTitleHandler = () => {
    setEditMode(true);
    setMenu(false);
  };

  const confirmEditTitleHandler = (e) => {
    e.stopPropagation();
    flowStore.updateNode(id, { blockName: editedName });
    flowStore.updateNodesData(id, { data: { ...data, title: editedName } });
    setEditMode(false);
  };

  const toggleBlockHandler = () => {
    flowStore.updateNode(id, {
      enabled: data?.enabled === false ? true : false,
    });
    flowStore.updateNodesData(id, {
      data: { ...data, enabled: data?.enabled === false ? true : false },
    });
    setMenu(false);
  };

  return (
    <StyledCustomNode
      color={data?.color}
      bgcolor={data?.bgcolor}
      onDoubleClick={propertiesModalOpenHandler}
      className={isLoading ? "loading" : ""}
      style={{ zIndex: isSelected ? 999 : 0 }}
    >
      <Handle
        type="target"
        id="c"
        position={Position.Top}
        className={isLoading ? "loading" : ""}
        style={{
          background: "#fef",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          zIndex: -1,
          marginTop: "-4px",
          pointerEvents: isLoading ? "none" : "auto",
        }}
        isConnectable={isLoading ? false : true}
      />
      <div
        style={{
          border: data?.enabled === false ? "1px solid red" : "none",
        }}
        className="nodeContent"
      >
        <div className="index">{data?.index}</div>
        <div onContextMenu={contextMenuHandler} className="title">
          {editMode ? (
            <div className="editInputHolder">
              <input
                type="text"
                value={editedName}
                autoFocus
                onChange={(e) => setEditedName(e.target.value)}
              />
            </div>
          ) : (
            <span>{data?.title}</span>
          )}
        </div>
        {editMode && (
          <button className="checkHolder" onClick={confirmEditTitleHandler}>
            <Check />
          </button>
        )}
      </div>
      <div>
        {menu && (
          <div className="menu">
            <div
              className="closeButton"
              onClick={() => {
                setIsSelected(false);
                flowStore.deselectNode(id);
                setMenu(false);
              }}
            >
              x
            </div>
            <button onClick={editTitleHandler}>
              <img src={editTitleImage} alt="editTitleImage" />
              Edit Title
            </button>
            <button onClick={toggleBlockHandler}>
              <img src={toggleImage} alt="toggleImage" />
              On/Off
            </button>
            <button onClick={duplicateNode}>
              <img src={copyImage} alt="copyImage" />
              Duplicate
            </button>

            <button
              onClick={() => {
                localStorage.setItem(
                  "copiedItem",
                  JSON.stringify({
                    nodes: [
                      {
                        nodesData: flowStore.getNodesData(id),
                        node: flowStore.getNode(id),
                      },
                    ],
                  })
                );
                setMenu(false);
              }}
            >
              <img src={copyImage} alt="copyImage" />
              Copy
            </button>
            <button onClick={deleteNode}>
              <img src={deleteImage} alt="deleteImage" />
              Delete
            </button>
          </div>
        )}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id="blue"
        className={isLoading ? "loading" : ""}
        style={{
          background: "blue",
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          zIndex: -1,
          marginBottom: "-6px",
          pointerEvents: isLoading ? "none" : "auto",
        }}
        isConnectable={isLoading ? false : true}
      />
      {data?.is_triple && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="red"
          className={isLoading ? "loading" : ""}
          style={{
            background: "red",
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            zIndex: -1,
            marginBottom: "-6px",
            marginLeft: "25px",
            pointerEvents: isLoading ? "none" : "auto",
          }}
          isConnectable={isLoading ? false : true}
        />
      )}
    </StyledCustomNode>
  );
};

export default CustomNode;
