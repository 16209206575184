import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import decompressData from "../utils/functions/decompressData";
import { useHistoryStore } from "./historyStore";
import { useVariableStore } from "./variableStore";
// import { useHistoryStore } from "./historyStore";
const initialState = {
  nodes: [],
  edges: [],
  nodesData: [],
  selectedEvent: "on_tick",
  events: {},
};

export const useFlowStore = create(
  persist(
    (set, get) => ({
      nodes: [],
      edges: [],
      nodesData: [],
      selectedEvent: "on_tick",
      events: {},

      setNodes: (nodes) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodes,
            },
          };
          return { nodes, events: updatedEvents };
        });
      },

      setEdges: (edges) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              edges,
            },
          };
          return { edges, events: updatedEvents };
        });
      },

      selectNode: (nodeId) => {
        set((state) => {
          const _nodesData = state.nodesData;
          _nodesData.find((e) => e.id === nodeId).selected = true;

          return { nodesData: _nodesData };
        });
      },

      deselectNode: (nodeId) => {
        set((state) => {
          const _nodesData = state.nodesData;
          if (_nodesData.find((e) => e.id === nodeId)) {
            _nodesData.find((e) => e.id === nodeId).selected = false;
          }
          return { nodesData: _nodesData };
        });
      },

      setNodesData: (nodesData) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodesData: nodesData,
            },
          };
          return { nodesData: nodesData, events: updatedEvents };
        });
      },

      setSelectedEvent: (event) => {
        const events = get().events;
        if (events[event]) {
          set({
            selectedEvent: event,
            nodes: events[event].nodes,
            edges: events[event].edges,
            nodesData: events[event].nodesData,
          });
        } else {
          set({
            selectedEvent: event,
            nodes: [],
            edges: [],
            nodesData: [],
          });
        }
      },

      movingMultipleNodes: (nodesList) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodes = state.nodesData.map((node) =>
            nodesList.find((e) => e.id === node?.id)
              ? {
                  ...node,
                  ...nodesList.find((e) => e.id === node?.id),
                  selected: false,
                }
              : node
          );
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodesData: updatedNodes,
            },
          };
          return { nodesData: updatedNodes, events: updatedEvents };
        });
      },

      deleteMultipleNodes: (nodesList) => {
        set((state) => {
          const selectedEvent = get().selectedEvent;
          let _highestDeletedNodeIndex = -1;

          nodesList.forEach((node) => {
            useVariableStore.getState().removeNode(node?.id);
            if (node?.data?.index > _highestDeletedNodeIndex) {
              _highestDeletedNodeIndex = node?.data?.index;
            }
          });

          const _highestIndex =
            Number(localStorage.getItem("highestIndex")) - 1;

          if (_highestDeletedNodeIndex >= _highestIndex && _highestIndex > 1) {
            localStorage.setItem("highestIndex", Number(_highestIndex));
          }
          const _updatedData = {
            nodesData: state.nodesData.filter(
              (e) => !nodesList.find((n) => n.id === e.id)
            ),
            nodes: state.nodes.filter(
              (e) => !nodesList.find((n) => n.id === e.id)
            ),
            edges: state.edges.filter(
              (edge) =>
                !nodesList.find((n) => n.id === edge.source) &&
                !nodesList.find((n) => n.id === edge.target)
            ),
          };
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              ..._updatedData,
            },
          };

          return { events: updatedEvents, ..._updatedData };
        });
      },

      setEvents: (events) => set({ events }),

      addNode: (node) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodes = [...state.nodes, node];
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodes: updatedNodes,
            },
          };
          return { nodes: updatedNodes, events: updatedEvents };
        });
      },

      addNodesData: (node) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          if (state.nodesData.findIndex((e) => e.id === node.id) < 0) {
            const updatedNodes = [
              ...state.nodesData,
              { ...node, selected: false },
            ];
            const updatedEvents = {
              ...state.events,
              [selectedEvent]: {
                ...state.events[selectedEvent],
                nodesData: updatedNodes,
              },
            };
            return { nodesData: updatedNodes, events: updatedEvents };
          }
        });
      },

      deleteNode: (nodeId) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodes = state.nodes.filter((node) => node.id !== nodeId);
          const updatedEdges = state.edges.filter(
            (edge) => edge.source !== nodeId && edge.target !== nodeId
          );
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodes: updatedNodes,
              edges: updatedEdges,
            },
          };
          return {
            nodes: updatedNodes,
            edges: updatedEdges,
            events: updatedEvents,
          };
        });
      },

      getNode: (nodeId) => {
        return get().nodes.find((e) => e.id === nodeId);
      },

      getNodesData: (nodeId) => {
        return get().nodesData.find((e) => e.id === nodeId);
      },

      updateNode: (nodeId, newNodeData) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodes = state.nodes.map((node) =>
            node.id === nodeId ? { ...node, ...newNodeData } : node
          );
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodes: updatedNodes,
            },
          };
          return { nodes: updatedNodes, events: updatedEvents };
        });
      },
      updateNodesData: (nodeId, newNodeData) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodesData = state.nodesData.map((node) =>
            node.id === nodeId ? { ...node, ...newNodeData } : node
          );
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodesData: updatedNodesData,
            },
          };
          return { nodesData: updatedNodesData, events: updatedEvents };
        });
      },

      addEdge: (edge) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedEdges = [...state.edges, edge];
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              edges: updatedEdges,
            },
          };
          return { edges: updatedEdges, events: updatedEvents };
        });
      },

      deleteEdge: (edgeId) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedEdges = state.edges.filter((edge) => edge.id !== edgeId);
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              edges: updatedEdges,
            },
          };
          return { edges: updatedEdges, events: updatedEvents };
        });
      },

      moveNode: (nodeId, position, positionAbsolute) => {
        const selectedEvent = get().selectedEvent;
        set((state) => {
          const updatedNodes = state.nodesData.map((node) =>
            node.id === nodeId ? { ...node, position, positionAbsolute } : node
          );
          const updatedEvents = {
            ...state.events,
            [selectedEvent]: {
              ...state.events[selectedEvent],
              nodesData: updatedNodes,
            },
          };
          return { nodesData: updatedNodes, events: updatedEvents };
        });
      },

      saveEventState: () =>
        set((state) => {
          const { selectedEvent, nodes, edges, nodesData, events } = state;
          events[selectedEvent] = { nodes, edges, nodesData };
          return { events };
        }),

      restoreHistory: (history) => {
        useHistoryStore.getState().setHistoryLoading(true);
        let decompressedData = JSON.parse(
          decompressData(JSON.parse(history.json_file)?.compressedData)
        );
        localStorage.setItem(
          "highestIndex",
          Number(decompressedData?.highestIndex)
        );
        const events = decompressedData?.data?.events;
        if (events) {
          set({ events });
          if (events[get().selectedEvent]) {
            const { nodes, edges, nodesData } = events[get().selectedEvent];
            set({ nodes, edges, nodesData });
          } else {
            set({ nodes: [], edges: [], nodesData: [] });
          }
        }
      },
      resetStore: () => set(initialState),
    }),
    {
      name: "flow-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
