import styled from "styled-components";

const StyledLoadingButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  opacity: ${(props) => (props.isLoading ? 0.7 : 1)};
  pointer-events: ${(props) => (props.isLoading ? "none" : "auto")};
  span {
    margin-right: 4px;
  }
`;

export default StyledLoadingButton;
