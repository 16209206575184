import styled from "styled-components";


const StyledAccordion= styled.div`
color: ${(props) => props.color || "black"};
background: ${(props) => props.bgcolor || "white"};
width: 100%;
`



export default StyledAccordion;