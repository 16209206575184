import React from "react";
import RenderRoutes from "../../../Components/RenderRoutes";
import { unAuthRoutes } from "../../../constants/routes/unAuth-routes";
const UnAuthenticatedPages = () => {
  return (
    <section>
      <RenderRoutes routePages={unAuthRoutes} />
    </section>
  );
};

export default UnAuthenticatedPages;
