import pako from "pako";

const decompressData = (base64CompressedData) => {
  // Convert base64 string back to a byte array
  if (typeof base64CompressedData === "string") {
    let byteArray;
    try {
      byteArray = Uint8Array?.from(atob(base64CompressedData), (c) =>
        c.charCodeAt(0)
      );
    } catch (error) {
      const _data = JSON.parse(base64CompressedData).compressedData;
      if (typeof _data === "object") {
        return _data;
      }
      byteArray = Uint8Array?.from(atob(_data), (c) => c.charCodeAt(0));
      console.log(error);
    }
    // Decompress the data
    const decompressedData = pako?.ungzip(byteArray, { to: "string" });
    // console.log({ decompressedData });
    return decompressedData;
  } else {
    return base64CompressedData;
  }
};

export default decompressData;
