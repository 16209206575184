import React, { useEffect, useState } from "react";
import StyledEventsBar from "./StyledEventsBar";
import useFetch from "../../utils/hooks/useFetch";
import { EVENTS } from "../../constants/routes/api-routes";
import setNestedValue from "../../utils/functions/setNestedValue";
import getNestedValue from "../../utils/functions/getNestedValue";
import { useFlowStore } from "../../store/flowStore";
import Cookies from "js-cookie";

const EventsBar = ({ setRerender }) => {
  const flowStore = useFlowStore();
  const { data, isLoading, isSuccess } = useFetch(
    "GET",
    [EVENTS],
    EVENTS,
    null,
    null,
    {
      enabled: Boolean(Cookies.get("token")),
    }
  );
  const [events, setEvents] = useState([]);
  const [activeEvent, setActiveEvent] = useState("on_tick");

  useEffect(() => {
    const _event = flowStore.selectedEvent;
    if (_event) {
      setActiveEvent(_event);
    } else {
      flowStore.setSelectedEvent("on_tick");
    }
    // eslint-disable-next-line
  }, [flowStore.selectedEvent]);

  useEffect(() => {
    if (data && isSuccess) {
      setEvents(data?.data);
      let _events = flowStore.events ? flowStore.events : { events: {} };
      data?.data?.forEach((element) => {
        if (
          !getNestedValue(_events, `${element?.slug?.replace(/-/g, "_")}.nodes`)
        ) {
          setNestedValue(
            _events,
            `${element?.slug?.replace(/-/g, "_")}.nodes`,
            []
          );
        }
        if (
          !getNestedValue(
            _events,
            `${element?.slug?.replace(/-/g, "_")}.nodesData`
          )
        ) {
          setNestedValue(
            _events,
            `${element?.slug?.replace(/-/g, "_")}.nodesData`,
            []
          );
        }
        if (
          !getNestedValue(_events, `${element?.slug?.replace(/-/g, "_")}.edges`)
        ) {
          setNestedValue(
            _events,
            `${element?.slug?.replace(/-/g, "_")}.edges`,
            []
          );
        }
      });
      flowStore.setEvents(_events);
    } // eslint-disable-next-line
  }, [data, isSuccess]);

  const selectEventHandler = (item) => {
    flowStore.setSelectedEvent(item?.replace(/-/g, "_"));
    setActiveEvent(item?.replace(/-/g, "_"));
  };

  return (
    <StyledEventsBar>
      {isLoading ? (
        <span>loading...</span>
      ) : (
        <div>
          {events
            ?.sort((a, b) => (Number(a.id_sent) > Number(b.id_sent) ? 1 : -1))
            ?.map((event) => (
              <div
                key={event?.slug?.replace(/-/g, "_")}
                onClick={selectEventHandler.bind(this, event?.slug)}
                className={`eventBtn ${
                  event?.slug?.replace(/-/g, "_") === activeEvent
                    ? "activeEvent"
                    : ""
                }`}
              >
                {event?.name}
                <span>
                  {flowStore.events[event?.slug?.replace(/-/g, "_")]?.nodesData
                    .length > 0
                    ? flowStore.events[event?.slug?.replace(/-/g, "_")]
                        ?.nodesData.length
                    : ""}
                </span>
              </div>
            ))}
        </div>
      )}
    </StyledEventsBar>
  );
};

export default EventsBar;
