import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../API";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
const useFetch = (
  method,
  queryKey,
  endpoint,
  params,
  data,
  reactQueryOptions,
  axiosOptions,
  extraAxiosFeatures
) => {
  const fetchFunction = async () => {
    try {
      return await ApiClient({
        method: method,
        url: endpoint,
        data: JSON.stringify(data),
        params: { ...params },
        headers: {
          "X-CSRFToken": Cookies.get("csrfToken"),
          Authorization: Cookies.get("token")
            ? `Bearer ${Cookies.get("token")}`
            : null,
        },
        ...axiosOptions,
        ...extraAxiosFeatures,
      });
    } catch (error) {
      toast.error("Something Went Wrong!");
      if (error.response.status === 401 && Cookies.get("token")) {
        // localStorage.removeItem("token");
        //delete all of the data ???
        localStorage.clear();
        Cookies.remove("token", {
          domain: process.env.REACT_APP_MAIN_DOMAIN,
        });
        Cookies.remove("role", {
          domain: process.env.REACT_APP_MAIN_DOMAIN,
        });
        Cookies.remove("refreshToken", {
          domain: process.env.REACT_APP_MAIN_DOMAIN,
        });
        Cookies.remove("tokenExpireTime", {
          domain: process.env.REACT_APP_MAIN_DOMAIN,
        });
        Cookies.remove("token");
        Cookies.remove("role");
        Cookies.remove("refreshToken");
        Cookies.remove("tokenExpireTime");
        window.location.reload();
        throw error;
      }
    }
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: fetchFunction,
    ...reactQueryOptions,
  });
};

export default useFetch;
//
