import styled from "styled-components";

export const StyledProjectSettings = styled.div`
  width: 700px;
  min-height: 300px;

  background: #666666;
  box-shadow: 0 0 10px #d6d6d6;
  position: absolute;
  left: calc(50% - 262.5px);
  top: 20%;
  z-index: 999999;
  padding: 5px;
  color: #fff;
  button {
    background: linear-gradient(to bottom, #aaa, #777);
    border-radius: 3px;
    padding: 4px 8px;
    text-shadow: 1px 1px 1px #000;
    cursor: pointer;
    color: #d6d6d6;
    border: 1px solid #111;
    margin: 8px;
  }
  .settingsForm {
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .header {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px;
    cursor: move;
    span {
      font-size: 14px;
      color: #aaaaaa;
    }
    button {
      margin: 0;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }
  }
`;
