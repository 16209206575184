import React from "react";
import StyledLogin from "./StyledLogin";
import UserModal from "../../../Components/UserModal";

const LoginPage = () => {
  return (
    <StyledLogin>
      <UserModal />
    </StyledLogin>
  );
};

export default LoginPage;
