import { useEffect } from "react";
import RenderRoutes from "../../../Components/RenderRoutes";
import { authRoutes } from "../../../constants/routes/auth-routes";
import useFetch from "../../../utils/hooks/useFetch";
import { REFRESH_TOKEN } from "../../../constants/routes/api-routes";
import Cookies from "js-cookie";
import { redirect, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../constants/routes/app-routes";
const AuthenticatedPages = () => {
  const navigate = useNavigate();
  const { data, refetch } = useFetch(
    "POST",
    [Cookies.get("token"), REFRESH_TOKEN],
    REFRESH_TOKEN,

    null,
    { refresh: Cookies.get("refreshToken") },
    { enabled: false }
  );
  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate(LOGIN_ROUTE);
    }
    const checkTokenExpiration = () => {
      const tokenExpiration = Cookies.get("tokenExpireTime");
      const currentTime = Date.now();
      if (tokenExpiration && currentTime >= tokenExpiration) {
        // Token has expired, refresh it
        refetch();
      }
    };

    // Check token expiration every minute
    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      const _expireTime =
        Number(Date.now()) + Number(process.env.REACT_APP_TOKEN_EXPIRE_TIME);
      Cookies.set("token", data.data?.access);
      Cookies.set("tokenExpireTime", _expireTime);
    }
  }, [data]);
  // if (!Cookies.get("token")) {
  //   return
  // }
  return (
    <section>
      <RenderRoutes routePages={authRoutes} />
    </section>
  );
};

export default AuthenticatedPages;
