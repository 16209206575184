import styled from "styled-components";

const StyledNewProjectModal = styled.div`
  overflow: hidden;
  background: #666666;
  box-shadow: 0 0 10px #d6d6d6;
  position: absolute;
  left: calc(50% - 262.5px);
  top: 20%;
  z-index: 999999;
  padding: 5px;

  .header {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px;
    cursor: move;
    span {
      font-size: 14px;
      color: #aaaaaa;
    }
    button {
      margin: 0;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      cursor: pointer;
      svg {
        cursor: pointer;
        width: 25px;
        height: 25px;
      }
    }
  }

  .contentNewProject {
    table {
      background-color: #555555;
      border: 0.1rem solid #000;

      td {
        background-color: #555;
        color: #fff;
        padding: 0.4rem;
      }

      fieldset {
        background-color: #666;
        border: 0.1rem solid #333;
        padding: 0.8rem;
      }

      legend {
        color: #ddd;
      }
      label {
        vertical-align: text-bottom;
        font-size: 1.8rem;
        color: #888;
        cursor: pointer;
      }

      input[type="radio"] {
        vertical-align: middle;
        margin: 0.5rem;
        cursor: pointer;

        &input[type="radio"]:checked + label {
          color: #ddd;
        }
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    input {
      min-width: 180px;
      margin-right: 5px;
      font-size: 20px;
      margin: 0;
      font-size: 12px;
      background-color: #333;
      color: #ddd;
      border: 1px solid #717171;
      font-size: 18px;
      padding: 8px;
    }
    button {
      background: linear-gradient(to bottom, #aaa, #777);
      border-radius: 3px;
      padding: 4px 8px;
      text-shadow: 1px 1px 1px #000;
      cursor: pointer;
      color: #d6d6d6;
      border: 1px solid #111;
    }
  }
`;

export default StyledNewProjectModal;
