import styled, { keyframes } from "styled-components";



const openAnimation = keyframes`
0%{max-height:0;}
100%{max-width:100%;}
`

const StyledAccordionItem = styled.div`
overflow: hidden;
color: ${(props) => props.color || "black"};
background: ${(props) => props.bgcolor || "white"};
width: 100%;
user-select: none;
font-size: 14px;
font-weight: 400;
cursor: pointer;
span{
padding: 5px;
display: block;
}
.open{
    
    animation: ${openAnimation} 0.3 ease-in-out f;
}
.itemListContainer{
    display: flex;
    align-items: center;

    :hover{
       color: #fff;
    background-color: #666;
    }
}
.listBrick{
    width: 8px;
    height: 28px;
}
.accordionContentItem{
    overflow: hidden;
    width: 100%;
    padding: 5px;
    background: #333;
    color: #CCC;
}
`

export default StyledAccordionItem